<template>
   <div class="container-y">
       <div class="w-full" v-for="item in items" :key="item">
           <div class="text-2xl md:text-3xl uppercase font-black text-primary dark:text-gray-200">
               {{item.title}}
           </div>
           <div class="text-gray-500 mb-5 text-base md:text-xl italic font-medium dark:text-gray-200">
               {{item.city}}
           </div>
           <div class="asset grid  gap-1 md:gap-2 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
               <div class="w-full overflow-hidden" v-for="image in item.imagespath" :key="image">
                   <img :src="image" class="img" alt="">
               </div>
           </div>
            <div class="w-full py-10">
                <table class="table-auto w-full">
                    <tbody>
                        <tr class="bg-white dark:bg-gray-800 dark:text-gray-200">
                            <td class="font-bold py-3">Alamat</td>
                            <td>:</td>
                            <td>{{item.address}}</td>
                        </tr>
                        <tr class="bg-gray-100 dark:bg-gray-800 dark:text-gray-200">
                            <td class="font-bold py-3">Luas</td>
                            <td>:</td>
                            <td>{{item.wide}}</td>
                        </tr>
                        <tr class="bg-white dark:bg-gray-800 dark:text-gray-200">
                            <td class="font-bold py-3">Harga</td>
                            <td>:</td>
                            <td>{{item.price}}</td>
                        </tr>
                        <tr class="bg-gray-100 dark:bg-gray-800 dark:text-gray-200">
                            <td class="font-bold py-3">PIC</td>
                            <td>:</td>
                            <td>{{item.pic}}</td>
                        </tr>
                        <tr class="bg-white dark:bg-gray-800 dark:text-gray-200">
                            <td class="font-bold py-3">Kontak</td>
                            <td>:</td>
                            <td>{{item.phone}}</td>
                        </tr>
                        <tr class="bg-gray-100 dark:bg-gray-800 dark:text-gray-200" v-if="item.legal_no">
                            <td class="font-bold py-3">Nomor Dokumen</td>
                            <td>:</td>
                            <td>{{item.legal_no}}</td>
                        </tr>
                        <tr class="bg-white dark:bg-gray-800 dark:text-gray-200" v-if="item.facility">
                            <td colspan="3">
                                <div class="font-bold py-3">Fasilitas</div>
                                <div v-html="item.facility" class="content"></div>
                            </td>
                        </tr>                        
                    </tbody>
                </table>
            </div>
       </div>
   </div>
</template>

<script>
import store from '@/store';
export default {
    name: 'ClientAsset',

    data() {
        return {
            items: []
        };
    },

    mounted() {
        this.getData()
    },

    methods: {
        getData(){
            store.commit('ChangeLoader',true)
        this.axios.get('api/getasset').then((response) => {
                let obj = {}
                for(let i = 0 ; i < response.data.length ; i++){
                     obj = {
                        id : response.data[i].id,
                        title : response.data[i].title,
                        address : response.data[i].address,
                        city : response.data[i].city,
                        phone : response.data[i].phone,
                        price : response.data[i].price,
                        pic : response.data[i].pic,
                        wide : response.data[i].wide,
                        facility : response.data[i].facility,
                        legal_no : response.data[i].legal_no,
                        images : JSON.parse(response.data[i].images),
                        imagespath : JSON.parse(response.data[i].imagespath)

                    }

                    this.items.push(obj)
                }
            }).catch(error => {
                
            console.log(error)

            }).finally(() => {
                store.commit('ChangeLoader',false)
            })
        }
    },
};
</script>

<style lang="scss" scoped>
@import '../../assets/main.scss';
.img{
    width: 100%;
    object-fit: cover;
    object-position: center;
}

</style>