<template>
    <div class="bg-white dark:bg-gray-800">
        <Hero :hero="params"></Hero>
        <Breadcrumbs :breadcrumbs="crumbs" :page="pagename"></Breadcrumbs>
        <div class="container-y grid gap-4 grid-cols-1 md:grid-cols-[3fr_5fr] lg:grid-cols-[2fr_5fr]">
            <div class="menu order-2 md:order-1">
                <ProfileMenu></ProfileMenu>
            </div>
            <div class="content order-1 md:order-2">
                 <Content></Content>
            </div>
        </div>       
    </div>
</template>

<script>
import Breadcrumbs from '../layouts/breadcrumb.vue'
import Hero from '../layouts/Hero.vue'
import Content from '../components/Laporan/ContentLaporan.vue'
import ProfileMenu from '../layouts/profileMenu.vue'
export default {
    name: 'ClientLaporankeuanganview',

    data() {
        return {
            params:{},
            pagename: '',
            crumbs: [],
            pageparams:'Laporan Keuangan'
        };
    },

    mounted() {
        this.getparams()
    },

    methods: {
           getparams(){

             this.axios.get('api/getbanner/'+this.pageparams).then((response) => {
               this.params = {
                    id: response.data.id,
                    title: response.data.type,
                    img: response.data.imagepath,
                    description : response.data.description,
                    showtitle : response.data.showtitle,
                    showdesc : response.data.showdesc,
                }

                document.title = response.data.type + ' | Bank Padma'
                document.querySelector('head meta[name="description"]').setAttribute('content', response.data.description);
                document.querySelector('head meta[name="og:description"]').setAttribute('content', response.data.description);
                document.querySelector('head meta[name="og:title"]').setAttribute('content', response.data.type + ' | Bank Padma');
                document.querySelector('head meta[name="keywords"]').setAttribute('content', response.data.keyword);
                document.querySelector('head meta[name="og:url"]').setAttribute('content', window.location.href);
                document.querySelector('head meta[name="og:image"]').setAttribute('content', response.data.imagepath);
                this.pagename = response.data.type
                this.crumbs = [{key:response.data.type, link:"/laporan-keuangan"}]

            }).catch(error => {
                
            console.log(error)

            }).finally(() => {
                
            })
            
            
        }
    },
    components:{
        Hero,
        Breadcrumbs,
        Content,
        ProfileMenu
    }
};
</script>

<style lang="scss" scoped>

</style>