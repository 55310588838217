<template>
    <div class="p-4">
        <img :src="watermark" class="w-full" alt="">
        <div class="text-center text-xl font-bold mb-4">Formulir Pembukaan Deposito</div>
        <table class="border-collapse border table-fixed w-full border-gray-500 text-xs font-medium" >
            <tr class="w-full leading-normal">
                <td  class="border pb-4 px-2 border-gray-500 w-full text-center font-bold text-base" colspan="2">Data Pemohon</td>
            </tr>
            <tr class="w-full leading-normal">
                <td  class="border pb-3 px-2 border-gray-500 w-2/5">Nama : </td>
                <td  class="border pb-3 px-2 border-gray-500 w-full">{{data.nama}}</td>
            </tr>
            <tr class="w-full leading-normal">
                <td  class="border pb-3 px-2 border-gray-500 w-2/5">Nomor KTP : </td>
                <td  class="border pb-3 px-2 border-gray-500 w-full">{{data.ktp}}</td>
            </tr>
            <tr class="w-full leading-normal">
                <td  class="border pb-3 px-2 border-gray-500 w-2/5">Alamat : </td>
                <td  class="border pb-3 px-2 border-gray-500 w-full">{{data.alamat}}</td>
            </tr>
            <tr class="w-full leading-normal">
                <td  class="border pb-3 px-2 border-gray-500 w-2/5">Kota : </td>
                <td  class="border pb-3 px-2 border-gray-500 w-full">{{data.kota}}</td>
            </tr>
            <tr class="w-full leading-normal">
                <td  class="border pb-3 px-2 border-gray-500 w-2/5">Status Kawin : </td>
                <td  class="border pb-3 px-2 border-gray-500 w-full">{{data.statuskawin}}</td>
            </tr>
            <tr class="w-full leading-normal">
                <td  class="border pb-3 px-2 border-gray-500 w-2/5">Nomor Handphone : </td>
                <td  class="border pb-3 px-2 border-gray-500 w-full">{{data.handphone}}</td>
            </tr>
             <tr class="w-full leading-normal">
                <td  class="border pb-3 px-2 border-gray-500 w-2/5">Email : </td>
                <td  class="border pb-3 px-2 border-gray-500 w-full">{{data.email}}</td>
            </tr>
             <tr class="w-full leading-normal">
                <td  class="border pb-3 px-2 border-gray-500 w-2/5">Nama Ibu Kandung : </td>
                <td  class="border pb-3 px-2 border-gray-500 w-full">{{data.namaibu}}</td>
            </tr>
            
            <tr class="w-full leading-normal">
                <td  class="border pb-4 px-2 border-gray-500 w-full text-center font-bold text-base" colspan="2">Data Pekerjaan dan Penghasilan</td>
            </tr>   
            <tr class="w-full leading-normal">
                <td  class="border pb-3 px-2 border-gray-500 w-2/5">Pekerjaan : </td>
                <td  class="border pb-3 px-2 border-gray-500 w-full">{{data.pekerjaan}}</td>
            </tr>
            <tr class="w-full leading-normal">
                <td  class="border pb-3 px-2 border-gray-500 w-2/5">Tempat Bekerja : </td>
                <td  class="border pb-3 px-2 border-gray-500 w-full">{{data.tempatkerja}}</td>
            </tr>
              <tr class="w-full leading-normal">
                <td  class="border pb-3 px-2 border-gray-500 w-2/5">Penghasilan Pertahun : </td>
                <td  class="border pb-3 px-2 border-gray-500 w-full">{{data.penghasilan}}</td>
            </tr>
              <tr class="w-full leading-normal">
                <td  class="border pb-3 px-2 border-gray-500 w-2/5">Sumber Penghasilan : </td>
                <td  class="border pb-3 px-2 border-gray-500 w-full">{{data.sumberpenghasilan}}</td>
            </tr>
            <tr class="w-full leading-normal">
                <td  class="border pb-4 px-2 border-gray-500 w-full text-center font-bold text-base" colspan="2">Data Deposito</td>
            </tr>   
            <tr class="w-full leading-normal">
                <td  class="border pb-3 px-2 border-gray-500 w-2/5">Jenis Deposito : </td>
                <td  class="border pb-3 px-2 border-gray-500 w-full">{{data.jenisdeposito}}</td>
            </tr>
             <tr class="w-full leading-normal">
                <td  class="border pb-3 px-2 border-gray-500 w-2/5">Nominal Deposito : </td>
                <td  class="border pb-3 px-2 border-gray-500 w-full">{{data.nominal}}</td>
            </tr>
             <tr class="w-full leading-normal">
                <td  class="border pb-3 px-2 border-gray-500 w-2/5">Jangka Waktu : </td>
                <td  class="border pb-3 px-2 border-gray-500 w-full">{{data.jangkawaktu}}</td>
            </tr>
             <tr class="w-full leading-normal">
                <td  class="border pb-3 px-2 border-gray-500 w-2/5">Jenis Perpanjangan : </td>
                <td  class="border pb-3 px-2 border-gray-500 w-full">{{data.perpanjangan}}</td>
            </tr>
             <tr class="w-full leading-normal">
                <td  class="border pb-3 px-2 border-gray-500 w-2/5">Tujuan Pembukaan : </td>
                <td  class="border pb-3 px-2 border-gray-500 w-full">{{data.tujuan}}</td>
            </tr>
             <tr class="w-full leading-normal">
                <td  class="border pb-3 px-2 border-gray-500 w-2/5">nama Ahli Watis : </td>
                <td  class="border pb-3 px-2 border-gray-500 w-full">{{data.ahliwaris}}</td>
            </tr>
        </table>

        <div class="">
            <div v-html="data.formterm" class="term text-xs font-normal text-gray-900 leading-relaxed mb-5">

            </div>

             <div v-html="data.subterm" class="term text-xs font-normal text-gray-900 leading-relaxed">

            </div>
        </div>      

        <div class="text-xs font-medium text-900 mt-4">
            {{data.kota}} , {{date}}
        </div>
        <div class="grid grid-cols-3 gap-10 text-xs font-medium text-900">
            <div class="group h-40 flex flex-col justify-between items-start">                
                <div>Pemohon,</div>
                <div>
                    <img :src="data.sign" width="200" alt="">
                </div>
                <div class="w-full">
                     <div class="border-b border-gray-900 w-full block leading-normal h-8">{{data.nama}}</div>
                     <div class="text-xs  font-normal">Tanda tangan/nama terang</div>
                </div>
                           
            </div>
            <div class="group h-40 flex flex-col justify-between items-start">                
                <div>Petugas</div>
                 <div class="w-full">
                     <div class="border-b border-gray-900 w-full block leading-normal h-8"></div>
                     <div class="text-xs font-normal">Tanda tangan/nama terang</div>
                </div>              
            </div>
             <div class="group h-40 flex flex-col justify-between items-start">                
                <div>Supervisor</div>
                 <div class="w-full">
                     <div class="border-b border-gray-900 w-full block leading-normal h-8"></div>
                     <div class="text-xs font-normal">Tanda tangan/nama terang</div>
                </div>              
            </div>
        </div>
        <br>
    </div>
</template>

<script>
import moment from "moment";
import watermarks from '../../../../../public/watermark.png'
export default {
    name: 'PadmawebDownloadDepositSubmission',
    props:['data'],
    data() {
        return {
            moment : moment,
            watermark:watermarks
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
    computed: {
        date: {
            get: function(){
                return moment(this.data.created_at).locale('id').format("DD MMMM YYYY");
            },
            set: function(){

            }
          },  
    }
};
</script>

<style lang="scss" scoped>

</style>