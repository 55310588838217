<template>
   <div class="py-3 md:py-10 lg:h-screen overflow-hidden w-full relative bg-white dark:bg-gray-900" v-if="company.length">
       <img v-if="general !== null" :src="general.companypath" class="h-full hidden md:block absolute bottom-0 left-32 md:left-10" alt="Bank Padma">
       <div class="relative md:absolute h-full w-full inset-0 z-10">
           <div class="container-y relative">
               <div class="md:w-3/4 lg:w-2/4 mx-auto md:mx-0 md:float-right mt-0 md:mt-10 lg:mt-52 flex flex-col justify-center items-start">
                   <div class="text-2xl md:text-5xl md:font-semibold font-bold dark:text-gray-200 text-primary uppercase mb-3 md:mb-5 lg:mb-8">
                       PT. BPR Padma
                   </div>
                   <p class="text-justify text-sm md:text-base mb-5 lg:mb-8 leading-7 lg:leading-8 text-gray-700 dark:text-gray-200">
                       {{company[0].description}}
                   </p>
                   <button class="px-3 md:px-5 py-2 md:py-4 text-white bg-primary text-xs md:text-sm rounded"><router-link to="/sejarah">Selengkapnya</router-link></button>
               </div>
           </div>
       </div>
   </div>
</template>

<script>
import store from '@/store';
export default {
    name: 'ClientAbout',

    data() {
        return {
            background: null,
        };
    },

    mounted() {
    },

    methods: {
    },

    computed:{
         company(){
            return store.state.company
        },
        general(){
            return store.state.general
        }
    }
};
</script>

<style lang="scss" scoped>

</style>