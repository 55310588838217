<template>
    <div v-if="items.length">
        <div class="text-2xl font-semibold text-gray-700 dark:text-gray-200 pb-4 mb-4 border-b-2 border-secondary">
            Artikel Terkait
        </div>
        <div class="w-full">
            <div class="grid gap-2 md:gap-4 grid-cols-2 mb-2" v-for="item in items" :key="item">
                <div class="i-img">
                    <img :src="item.imagepath" :alt="item.title">
                </div>
                <div class="i-desc">
                    <div class="date text-xs font-normal dark:text-gray-200 text-gray-500 mb-1">
                        {{moment(item.published_date).locale('id').format("DD MMMM YYYY")}}
                    </div>
                    <div class="headline text-xs md:text-sm font-medium dark:text-gray-200 text-primary duration-300 hover:text-secondary dark:hover:text-secondary">
                        <router-link class="link" :to="{name : 'DBlog', params: { blog: item.permalink } }">
                            {{item.title}}
                        </router-link>
                    </div>
                </div>
            </div>
        </div>        
    </div>
</template>

<script>
import moment from "moment"
export default {
    name: 'ClientLBlog',

    data() {
        return {
             items:[],
             moment:moment
        };
    },

    mounted() {
        this.getData()
    },

    methods: {
         getData(){
           this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
            this.axios.get('api/getblogterkait').then((response) => {
                this.items = response.data
            }).catch(error => {
               
              console.log(error)

            }).finally(() => {
                
            })
        },
    },
};
</script>

<style lang="scss" scoped>

</style>