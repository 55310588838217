<template>
    <div class="bg-white dark:bg-gray-800">
        <Hero :hero="params"></Hero>
         <Breadcrumbs :breadcrumbs="crumbs" :page="pagename"></Breadcrumbs>
        <content :data="params"></content>
    </div>
</template>

<script>
import store from '@/store';
import Breadcrumbs from '../layouts/breadcrumb.vue'
import Hero from '../layouts/Hero-Detail.vue'
import Content from '../components/Layanan/ContentDetailLayanan.vue'
export default {
    name: 'ClientDlayanan',
    props:['name'],
    data() {
        return {
            params:{},
            pagename: null,
            crumbs: []
        };
    },

    mounted() {
        this.getparams();
    },

    methods: {
        getparams(){
            store.commit('ChangeLoader',true)
            this.axios.get('api/getservice/'+this.name).then((response) => {
                
                let slink = false;
                let ilink = false;

                if(response.data.showlink == 'true'){
                    slink = true
                }

                if(response.data.internallink == 'true'){
                    ilink = true
                }
                this.params = {
                    id: response.data.id,
                    title: response.data.title,
                    img: response.data.imagepath,
                    detail: response.data.detail,
                    description : response.data.description,
                    permalink:response.data.permalink,
                    link: response.data.link,
                    buttontext: response.data.buttontext,
                    showlink: slink,
                    internallink: ilink
                }
                this.pagename = response.data.title

                this.crumbs = [
                    {key:"Layanan", link:"/layanan"},
                    {key:response.data.title, link: "/layanan/"+response.data.permalink}
                ]

                document.title = response.data.title + ' | Bank Padma'
                document.querySelector('head meta[name="description"]').setAttribute('content', response.data.description);
                document.querySelector('head meta[name="og:description"]').setAttribute('content', response.data.description);
                document.querySelector('head meta[name="og:title"]').setAttribute('content', response.data.title + ' | Bank Padma');
                document.querySelector('head meta[name="keywords"]').setAttribute('content', response.data.keyword);
                document.querySelector('head meta[name="og:url"]').setAttribute('content', window.location.href);
                document.querySelector('head meta[name="og:image"]').setAttribute('content', response.data.imagepath);
                
               
            }).catch(error => {
                
            console.log(error)

            }).finally(() => {
                 store.commit('ChangeLoader',false)
            })        

            
        }
    },
    components:{
        Breadcrumbs,
        Hero ,
        Content   
    }
};
</script>

<style lang="scss" scoped>

</style>