<template>
    <div>
       
    </div>
</template>

<script>

export default {
    name: 'ClientDashboard',

    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
    components:{
 
    }
};
</script>

<style lang="scss" scoped>

</style>