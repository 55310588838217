<template>
    <div class="fixed bg-b-overlay z-50 inset-0 w-full h-screen">       
        <div class="bg-white dark:bg-gray-900 w-5/6 md:w-2/6 shadow-md rounded-md  absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
            <div class="w-full flex justify-between flex-col relative">
                <div class="close absolute right-3 top-3">
                    <mdicon name="close" size="20" class="mr-1 text-gray-500 dark:text-gray-200" @click="this.$emit('closeconfirm')"/>
                </div>
                <div class="text-2xl font-bold px-5 pt-5 pb-2">
                    <slot name="header" class="h-full"></slot>
                </div>
                <div class="h-full text-base border-b px-5 pb-4 border-gray-300 dark:border-gray-800">
                    <slot name="main"></slot>
                </div>
                <div class="px-5 pb-5 pt-4">
                    <slot name="footer"></slot>
                </div>       
            </div>                   
        </div>         
    </div>
</template>

<script>
export default {
    name: 'PadmawebConfirm',

    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>

<style lang="scss" scoped>

</style>