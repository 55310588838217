<template>
    <div class="container-y pb-8">
        <div class="grid gap-4 grid-cols-1 md:grid-cols-[2fr_1fr]">
            <div class="left">
                <div class="text-2xl md:text-3xl lg:text-4xl lg:leading-snug font-bold leading-snug text-primary dark:text-gray-200" v-if="!loading">
                    {{data.title}}
                </div>
                <div class="flex items-center justify-start py-3" v-if="!loading">
                    <div class="flex items-center justify-start mr-5 text-gray-500 dark:text-gray-200">                        
                        <mdicon name="calendar" size="23" class="mr-1" />
                        <div class="text-sm">
                            {{moment(data.published_date).locale('id').format("DD MMMM YYYY")}}
                        </div>
                    </div>
                    <div class="flex items-center justify-start mr-5 text-gray-500 dark:text-gray-200">                        
                        <mdicon name="account" size="23" class="mr-1" />
                        <div class="text-sm">
                            {{data.name}}
                        </div>
                    </div>                    
                </div>
                <div class="w-full overflow-hidden" v-if="!loading">
                    <img :src="data.imagepath" :alt="data.title">
                </div>
                <div class="text-sm mt-5 md:text-base leading-loose md:leading-loose w-full dark:text-gray-200 font-normal dark:font-normal content mb-5 text-gray-700" v-html="data.detail"  v-if="!loading"></div>
                    <div class="mt-4"  v-if="!loading">
                        <div class="text-sm font-semibold text-gray-700 dark:text-gray-100">Tags :</div>
                        <div class="mt-2 flex flex-wrap">
                            <div v-for="tag in tags" :key="tag" class="text-gray-700 text-xs font-semibold bg-gray-300 px-2 py-1 block mr-2 mb-2 rounded-sm">
                                <router-link :to="'/blog/tags/'+tag">{{tag}}</router-link>
                            </div>
                        </div>
                    </div>
                <div class="my-4 md:my-8" v-if="!loading">
                    <Share class="block"></Share>
                </div>
           </div>
            <div class="right">
               <Other></Other>
            </div>
        </div>
       
    </div>
</template>

<script>
import store from "@/store";
import moment from "moment";
import Share from '../../layouts/Share.vue'
import Other from './LBlog.vue'
export default {
    name: 'ClientDBlogC',
    props:['data'],
    data() {
        return {
            moment:moment,
           
        };
    },

    mounted() {

    },

    methods: {
        
    },
    components:{
        Other,
        Share
    },
    computed:{
        tags(){
            const str = this.data.keyword
            if(str){
                const arr = str.split(',');
                return arr
            }         
        },
        loading(){
            return store.state.preloader
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../../assets/main.scss";

</style>