<template>
    <div>
       <DataTable :datatable="items" :header="headers" @page-changed="pageChange($event)">
           <template v-slot:table-slot>
               <div class="w-full grid gap-5 grid-cols-[1fr_auto]">
                   <form @submit.prevent action="" class="w-full">
                       <div class="flex border-b-2 bg-gray-100 border-gray-300 dark:bg-gray-900 dark:text-gray-100 dark:border-gray-800 focus:outline-none py-1 mb-4 w-full text-gray-700 font-medium">
                           <input type="text" class="focus:outline-none bg-transparent w-full" placeholder="Search..." v-model="search">
                           <mdicon name="magnify" size="28" class="mr-1 text-gray-700 dark:text-gray-100"/>
                       </div>
                    </form>

                    <button class="text-sm font-medium focus:outline-none active:translate-y-[2px] duration-200 bg-primary inline-block relative h-8 rounded shadow w-16 text-center text-gray-100" @click="addModal" v-if="isCreatePermitted">Add</button>
               </div>
           </template>

           <template v-slot:action="item">
              <div class="flex">
                   <button class="flex justify-center items-center bg-primary mr-2 px-2 py-1 text-gray-100 rounded text-xs font-medium focus:outline-none" @click="editModal(item)" v-if="isUpdatePermitted"><mdicon name="pencil" class="text-gray-100 mr-[2px]" size="15"/><span>Edit</span></button>
                   <button class="flex justify-center items-center bg-secondary px-2 py-1 text-gray-100 rounded text-xs font-medium focus:outline-none" @click="deleteModal(item)" v-if="isDeletePermitted"><mdicon name="trash-can-outline" class="text-gray-100 mr-[2px]" size="15"/><span>Delete</span></button>
               </div>
           </template>
       </DataTable>
       <FormBase v-if="modal" @closeModal="modal = !modal">
            <template v-slot:formName>
               {{title}}
            </template>
            <template v-slot:form>
                <div class="grid gap-5 grid-cols-1 mb-10">
                    <div class="form-group">
                        <input type="number" placeholder="Number" class="w-full border-b border-gray-400 dark:border-gray-800 dark:text-gray-100 bg-transparent focus:outline-none py-2 px-2 text-sm font-normal text-gray-700" v-model="form.number">
                    </div>               
                </div> 
                 <div class="grid gap-5 grid-cols-1 mb-10">
                    <div class="form-group">
                        <input type="text" placeholder="Title" class="w-full border-b border-gray-400 dark:border-gray-800 dark:text-gray-100 bg-transparent focus:outline-none py-2 px-2 text-sm font-normal text-gray-700" v-model="form.title">
                    </div>               
                </div> 
                 <div class="grid gap-5 grid-cols-1 mb-10">
                    <div class="form-group">
                        <input type="text" placeholder="Icon" class="w-full border-b border-gray-400 dark:border-gray-800 dark:text-gray-100 bg-transparent focus:outline-none py-2 px-2 text-sm font-normal text-gray-700" v-model="form.icon">
                    </div>               
                </div> 
                <div class="grid gap-5 grid-cols-1 mb-10">
                    <div class="form-group">
                        <input type="text" placeholder="Route" class="w-full border-b border-gray-400 dark:border-gray-800 dark:text-gray-100 bg-transparent focus:outline-none py-2 px-2 text-sm font-normal text-gray-700" v-model="form.route">
                    </div>               
                </div>                
            </template>
             <template v-slot:formFooter>
                  <div class="text-primary dark:text-gray-100 font-medium text-lg cursor-pointer hover:bg-gray-300 dark:hover:bg-gray-800 dark:hover:text-gray-100 py-2 px-4 rounded" @click="modal = !modal">
                      Cancel
                  </div> 
                   <div class="text-primary dark:text-gray-100 font-medium text-lg cursor-pointer hover:bg-gray-300 dark:hover:bg-gray-800 dark:hover:text-gray-100 py-2 px-4 rounded" @click="save">
                      Save
                  </div>                        
            </template>
       </FormBase>
        <Transition name="fade">
            <Confirm v-if="deletemodal" @closeconfirm="deletemodal = false">
                <template v-slot:header>
                    Confirm
                </template>
                <template v-slot:main>
                    Are You Sure You Want To Delete This Data ?
                </template>
                <template v-slot:footer>
                    <div class="flex justify-end items-center">
                        <LButton class="bg-gray-300 text-gray-800 mr-2" @click="deletemodal = false">
                            Cancel
                        </LButton>
                        <LButton class="text-white bg-secondary" :class=" isloading ? 'pointer-events-none' : 'cursor-pointer'" :disabled="isloading" @click="deleteData">
                            {{ isloading ? 'Loading...' : 'Confirm' }}
                        </LButton>                            
                    </div>
                </template>
            </Confirm>  
        </Transition>
    </div>
</template>

<script>
import LButton from '@/layouts/button.vue'
import Confirm from '@/layouts/confirm.vue'
import FormBase from '@/layouts/FormBase.vue'
import DataTable from '@/layouts/DataTable.vue'
export default {
    name: 'PadmawebModuleTable',

    data() {
        return {
            isLoading:false,
            deletemodal: false,
            editIndex : -1,
            deleteID: null,
            form : {
                id: '',
                number:'',
                title: '',
                icon:'',
                route:'',
            },
           defaultForm : {
                id: '',
                number:'',
                title: '',
                icon:'',
                route:'',
            },
            headers:[],
            items:[],
            currentPage : 1,
            modal:false,
            roles: [],
            search: ''
        };
    },

    watch:{
        search:function(){
            if(this.search.length > 0){
                this.searchData(this.search)
            } else {
                this.getData()
            }
            
        },
        modal(val) {
			val || this.clearform();
		},
    },

    mounted() {
        this.getData()
        this.getheader()
    },

    methods: {        
         getheader(){
            if(this.isDeletePermitted == true || this.isUpdatePermitted == true){
                this.headers = [
                   {
                    Text : 'ID',
                    value : 'id',
                    type: 'string'
                },
                {
                    Text: 'Icon',
                    value: 'icon',
                    type: 'icon'
                },
                {
                    Text: 'Title',
                    value: 'title',
                    type: 'string'
                }
                ,
                {
                    Text: 'Route',
                    value: 'route',
                    type: 'string'
                },
                {
                    Text: 'Actions',
                    action: true,
                }
                ]
            }else{
                this.headers = [
                     {
                        Text : 'ID',
                        value : 'id',
                        type: 'string'
                        },
                        {
                            Text: 'Icon',
                            value: 'icon',
                            type: 'icon'
                        },
                        {
                            Text: 'Title',
                            value: 'title',
                            type: 'string'
                        }
                        ,
                        {
                            Text: 'Route',
                            value: 'route',
                            type: 'string'
                        }
                ]
            }
        },
        addModal(){
            this.editIndex = -1
            this.modal = !this.modal
        },
        deleteModal(val){
            this.deleteID = JSON.parse(JSON.stringify(val)).item.id
            this.deletemodal = true
        },
        editModal(val){
            this.editIndex = 1
            let obj = JSON.parse(JSON.stringify(val)).item

            this.form.id = obj.id
            this.form.number = obj.number
            this.form.title = obj.title
            this.form.icon = obj.icon
            this.form.route = obj.route

            this.modal = true
        },
        selectImage(event){
           this.form.img = event.target.files[0];
        },
        changePage(e){
            this.currentPage = e;
            this.getData()
        },

        getData(){
            this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
            this.axios.get(`api/modules?page=${this.currentPage}`).then((response) => {
                this.items = response.data
                console.log(this.items)
            }).catch(error => {
               
              console.log(error)

            }).finally(() => {
                
            })
        },
        searchData(val){
            this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
            this.axios.get('api/modules/search/'+val).then((response) => {
                this.items = response.data
            }).catch(error => {
               
              console.log(error)

            }).finally(() => {
                
            })
        },
        pageChange: function(e){
           this.currentPage = e;
           this.getData()
        },

        clearform: function() {
            this.modal = false
            this.$nextTick(() => {
                this.form = Object.assign({}, this.defaultForm);
                this.editIndex = -1;
            });
        }
           
        ,
        save(){
            if(this.editIndex > -1){
                let formData = new FormData();

                formData.append('id', this.form.id);
				formData.append('number', this.form.number);
				formData.append('title', this.form.title);
                formData.append('icon', this.form.icon);
                formData.append('route', this.form.route);


                    this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
                    this.axios.post('api/modules/update',formData).then(() => {
                    this.getData();
                    this.modal = false
                    this.clearform();
                }).catch(error => {                
                    console.log(error)
                }).finally(() => {
                })
            }else{
                let formData = new FormData();
                 formData.append('number', this.form.number);
				formData.append('title', this.form.title);
                formData.append('icon', this.form.icon);
                formData.append('route', this.form.route);
               

                this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
                this.axios.post('api/modules',formData).then(() => {
                this.getData();
                this.clearform()
                }).catch(error => {                
                    console.log(error)
                }).finally(() => {
                })
            }
        },
        deleteData(){
            this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
            this.axios.delete('api/modules/'+this.deleteID).then(() => {
                this.getData()
                this.deleteID = null
                this.deletemodal = false
            }).catch(error => {
               
              console.log(error)

            }).finally(() => {
                
            })
        }
    },
    components:{
        DataTable,
        FormBase,
        Confirm,
        LButton
    },
    computed:{
        title(){
            if(this.editIndex > -1){
                return 'Edit Item'
            }else{
                return 'New Item'
            }
        }
    }
};
</script>

<style lang="scss" scoped>

</style>