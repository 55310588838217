<template>
    <div class="container-x">
        <div class="w-full h-64 md:h-96 lg:h-screen" v-if="h == null">
           
        </div>
        <div class="w-full relative overflow-hidden"  v-if="h !== null">
            <img :src="hero.img" :alt="hero.title" class="w-full object-cover object-center">
            <div class="w-full absolute z-10 left-5 md:left-16 bottom-10 md:bottom-16 lg:left-28 lg:bottom-32">
                <div v-if="hero.showlink" class="title hidden md:block md:text-3xl lg:text-5xl w-2/6 leading-loose font-black text-gray-900 mb-8">{{hero.title}}</div>
                <p v-if="hero.showlink" class="text-sm hidden md:block md:w-2/4 lg:w-1/3 font-medium text-gray-800 leading-relaxed">{{hero.description}}</p>
                <button @click="href(hero.link,hero.internallink)" v-if="hero.showlink" class="bg-secondary text-white rounded p-3 text-xs md:text-sm font-semibold drop-shadow-sm mt-8 active:translate-y-0.5 duration-200">{{hero.buttontext}}</button>
            </div>            
        </div>
    </div>
</template>

<script>
export default {
    name: 'ClientHero',
    props:['hero'],
    data() {
        return {
            h:null
        };
    },

    mounted() {
       
    },

    methods: {
        href(link,i){
            if(i == true){
                this.$router.push(link)
            }else{
                window.location.href= link;
            }			
   		},
    },
    watch:{
        hero:function(){

            if(this.hero.id == undefined){
                this.h = null
            }else{
                this.h = this.hero
            }
           
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../assets/main.scss"
</style>