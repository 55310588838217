<template>
    <div>
        <tabNavVue :tabs="tabs" :selected="selected" @selected="setSelected">
            <tabVue :isSelected="selected === '1'">
               <SavingTableVue></SavingTableVue>
            </tabVue>
            <tabVue :isSelected="selected === '2'">
               <DepositableVue></DepositableVue>
            </tabVue>
            <tabVue :isSelected="selected === '3'">
               <LoantableVue></LoantableVue>
            </tabVue>
            <tabVue :isSelected="selected === '4'">
                <ServicetableVue></ServicetableVue>
            </tabVue>
             <tabVue :isSelected="selected === '5'">
                <BrochureTableVue></BrochureTableVue>
            </tabVue>
        </tabNavVue>
    </div>
</template>

<script>
import tabNavVue from './tabNav.vue';
import tabVue from './tab.vue';
import SavingTableVue from './SavingTable.vue';
import LoantableVue from './Loantable.vue';
import DepositableVue from './Depositable.vue';
import ServicetableVue from './Servicetable.vue';
import BrochureTableVue from './BrochureTable.vue';
export default {
    name: 'PadmawebProductComponent',

    data() {
        return {
            selected:'1',

            tabs:[
                {
                    name: 'Savings',
                    initial: '1',
                    icon: 'piggy-bank-outline'
                },
                 {
                    name: 'Deposits',
                    initial: '2',
                    icon: 'chart-line'
                },
                {
                    name: 'Loans',
                    initial: '3',
                    icon: 'handshake-outline'
                },
                {
                    name: 'Services',
                    initial: '4',
                    icon: 'charity'
                },
                {
                    name: 'Brochures',
                    initial: '5',
                    icon: 'file'
                },


            ],
        };
    },

    mounted() {
        
    },

    methods: {
        setSelected(tab){
            this.selected = tab;
        },
    },
    components:{
        tabNavVue,
        tabVue,
        SavingTableVue,
        DepositableVue,
        LoantableVue,
        ServicetableVue,
        BrochureTableVue
    }
};
</script>

<style lang="scss" scoped>

</style>