<template>
    <div class="p-4">
        <img :src="watermark" class="w-full" alt="">
        <div class="text-center text-xl font-bold mb-4">Formulir Permohonan Kredit</div>
        <table class="border-collapse border table-fixed w-full border-gray-500 text-xs font-medium" >
            <tr class="w-full leading-normal">
                <td  class="border pb-4 px-2 border-gray-500 w-full text-center font-bold text-sm" colspan="2">Data Pemohon</td>
            </tr>
            <tr class="w-full leading-normal">
                <td  class="border pb-2 px-2 border-gray-500 w-full">Nama : {{data.nama}}</td>
                <td  class="border pb-2 px-2 border-gray-500 w-full">Nama Avalis :</td>
            </tr>
            <tr class="w-full leading-normal">
                <td  class="border pb-2 px-2 border-gray-500 w-full">Nomor KTP : {{data.ktp}}</td>
                <td  class="border pb-2 px-2 border-gray-500 w-full">Nama KTP :</td>
            </tr>
            <tr class="w-full leading-normal">
                <td  class="border pb-2 px-2 border-gray-500 w-full">Alamat : {{data.alamat}}</td>
                <td  class="border pb-2 px-2 border-gray-500 w-full">Alamat :</td>
            </tr>
             <tr class="w-full leading-normal">
                <td  class="border pb-2 px-2 border-gray-500 w-full">Kota : {{data.kota}}</td>
                <td  class="border pb-2 px-2 border-gray-500 w-full">Kota :</td>
            </tr>
            <tr class="w-full leading-normal">
                <td  class="border pb-2 px-2 border-gray-500 w-full">Status Perkawinan : {{data.statuskawin}}</td>
                <td  class="border pb-2 px-2 border-gray-500 w-full">Status Perkawinan :</td>
            </tr>
            <tr class="w-full leading-normal">
                <td  class="border pb-2 px-2 border-gray-500 w-full">Nomor Telp/Hp : {{data.handphone}}</td>
                <td  class="border pb-2 px-2 border-gray-500 w-full">Nomor Telp/Hp :</td>
            </tr>
            <tr class="w-full leading-normal">
                <td  class="border pb-2 px-2 border-gray-500 w-full">Alamat Email : {{data.email}}</td>
                <td  class="border pb-2 px-2 border-gray-500 w-full">Alamat Email :</td>
            </tr>
            <tr class="w-full leading-normal">
                <td  class="border pb-2 px-2 border-gray-500 w-full">Jumlah Tanggungan : {{data.tanggungan}}</td>
                <td  class="border pb-2 px-2 border-gray-500 w-full">Jumlah Tanggungan :</td>
            </tr>
            <tr class="w-full leading-normal">
                <td  class="border pb-2 px-2 border-gray-500 w-full">Nama Ibu Kandung : {{data.ibukandung}}</td>
                <td  class="border pb-2 px-2 border-gray-500 w-full">Nama Ibu Kandung :</td>
            </tr>  
            <tr class="w-full leading-normal">
                <td  class="border pb-4 px-2 border-gray-500 w-full text-center font-bold text-sm" colspan="2">Data Pekerjaan dan Penghasilan</td>
            </tr>   
            <tr class="w-full leading-normal">
                <td  class="border pb-2 px-2 border-gray-500 w-full">Pekerjaan : {{data.pekerjaan}}</td>
                <td  class="border pb-2 px-2 border-gray-500 w-full">Pekerjaan :</td>
            </tr>     
            <tr class="w-full leading-normal">
                <td  class="border pb-2 px-2 border-gray-500 w-full">Tempat Bekerja : {{data.tempatKerja}}</td>
                <td  class="border pb-2 px-2 border-gray-500 w-full">Tempat Bekerja :</td>
            </tr> 
            <tr class="w-full leading-normal">
                <td  class="border pb-2 px-2 border-gray-500 w-full">Penghasilan Per Bulan : {{data.penghasilan}}</td>
                <td  class="border pb-2 px-2 border-gray-500 w-full">Penghasilan Per Bulan :</td>
            </tr>      
            <tr class="w-full leading-normal">
                <td  class="border pb-2 px-2 border-gray-500 w-full">Pengeluaran Per Bulan : {{data.pengeluaran}}</td>
                <td  class="border pb-2 px-2 border-gray-500 w-full">Pengeluaran Per Bulan :</td>
            </tr>      
            <tr class="w-full leading-normal">
                <td  class="border pb-2 px-2 border-gray-500 w-full">Angsuran Bank Lain : {{data.angsuranBankLain}}</td>
                <td  class="border pb-2 px-2 border-gray-500 w-full">Angsuran Bank Lain :</td>
            </tr>      
            <tr class="w-full leading-normal">
                <td  class="border pb-2 px-2 border-gray-500 w-full">Penghasilan Bersih : {{data.pb}}</td>
                <td  class="border pb-2 px-2 border-gray-500 w-full">Penghasilan Bersih :</td>
            </tr>          
            <tr class="w-full leading-normal">
                <td  class="border pb-4 px-2 border-gray-500 w-full text-center font-bold text-sm" colspan="2">Data Permohonan Kredit</td>
            </tr>   
            <tr class="w-full leading-normal">
                <td  class="border pb-2 px-2 border-gray-500 w-full">Jenis Kredit : {{data.jenisPinjaman}}</td>
                <td  class="border pb-2 px-2 border-gray-500 w-full">Jenis Kredit :</td>
            </tr> 
            <tr class="w-full leading-normal">
                <td  class="border pb-2 px-2 border-gray-500 w-full">Jumlah Pinjaman : {{data.plafon}}</td>
                <td  class="border pb-2 px-2 border-gray-500 w-full">Jumlah Pinjaman :</td>
            </tr> 
            <tr class="w-full leading-normal">
                <td  class="border pb-2 px-2 border-gray-500 w-full">Jangka Waktu : {{data.jangkawaktu}}</td>
                <td  class="border pb-2 px-2 border-gray-500 w-full">Jangka Waktu :</td>
            </tr> 
            <tr class="w-full leading-normal">
                <td  class="border pb-2 px-2 border-gray-500 w-full">Tujuan Pengunaan : {{data.tujuan}}</td>
                <td  class="border pb-2 px-2 border-gray-500 w-full">Tujuan Pengunaan :</td>
            </tr> 
            <tr class="w-full leading-normal">
                <td  class="border pb-2 px-2 border-gray-500 w-full">Dokumen Jaminan : {{data.jaminan}}</td>
                <td  class="border pb-2 px-2 border-gray-500 w-full">Dokumen Jaminan :</td>
            </tr> 
            <tr class="w-full leading-normal">
                <td  class="border pb-2 px-2 border-gray-500 w-full">Atas Nama : {{data.atasnama}}</td>
                <td  class="border pb-2 px-2 border-gray-500 w-full">Atas Nama : </td>
            </tr> 
        </table>

        <div class="">
            <div v-html="data.formterm" class="term text-xs font-normal leading-relaxed mb-5">

            </div>

             <div v-html="data.subterm" class="term text-xs font-normal leading-relaxed">

            </div>
        </div>       

        <div class="text-xs font-medium text-900 mt-4">
            {{data.kota}} , {{date}}
        </div>
        <div class="grid grid-cols-3 gap-10 text-xs font-medium text-900">
            <div class="group h-40 flex flex-col justify-between items-start">                
                <div>Pemohon,</div>
                <div>
                    <img :src="data.sign" width="200" alt="">
                </div>
                <div class="w-full">
                     <div class="border-b border-gray-900 w-full block leading-normal h-8">{{data.nama}}</div>
                     <div class="text-xs  font-normal">Tanda tangan/nama terang</div>
                </div>
                           
            </div>
             <div class="group h-40 flex flex-col justify-between items-start">                
                <div>Avalis</div>
                 <div class="w-full">
                     <div class="border-b border-gray-900 w-full block leading-normal h-8"></div>
                     <div class="text-xs font-normal">Tanda tangan/nama terang</div>
                </div>               
            </div>
             <div class="group h-40 flex flex-col justify-between items-start">                
                <div>PT.BPR Padma</div>
                 <div class="w-full">
                     <div class="border-b border-gray-900 w-full block leading-normal h-8"></div>
                     <div class="text-xs font-normal">Tanda tangan/nama terang</div>
                </div>              
            </div>
        </div>
        <br>
    </div>
</template>

<script>
import moment from "moment";
import watermarks from '../../../../../public/watermark.png'
export default {
    name: 'PadmawebDownloadLoanSubmission',
    props:['data'],
    data() {
        return {
            moment : moment,
            watermark:watermarks
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
    computed: {
        date: {
            get: function(){
                return moment(this.data.created_at).locale('id').format("DD MMMM YYYY");
            },
            set: function(){

            }
          },  
    }
};
</script>

<style lang="scss" scoped>
 .term::v-deep ol{
        margin-left: 15px !important;
        li{
            list-style-type: decimal !important;
            list-style-position: inside;
        }
    }

    .term::v-deep ul{
        margin-left: 15px !important;
        li{
            list-style-type: decimal !important;
            list-style-position: inside;
        }
    }

</style>