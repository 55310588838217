<template>
	<div v-if="isSelected" class="box">
    <slot></slot>
	</div>
</template>

<script>
export default {

  name: 'Tab',
  props:{
  	isSelected:{
  		type: Boolean
  	}
  },

  data () {
    return {

    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/main.scss";
</style>

