<template>
    <div class="bg-white dark:bg-gray-800">
        <Hero :hero="params"></Hero>
        <Breadcrumbs :breadcrumbs="crumbs" :page="pagename"></Breadcrumbs>
        <div class="container-y grid gap-4 grid-cols-1 md:grid-cols-[3fr_5fr] lg:grid-cols-[2fr_5fr]">
            <div class="menu order-2 md:order-1">
                <ProfileMenu></ProfileMenu>
            </div>
            <div class="content order-1 md:order-2">
                 <Content :item="data"></Content>
            </div>
        </div>       
    </div>
</template>

<script>
import store from '@/store'
import Breadcrumbs from '../layouts/breadcrumb.vue'
import Hero from '../layouts/Hero.vue'
import Content from '../components/Other/ContentDetailManajemen.vue'
import ProfileMenu from '../layouts/profileMenu.vue'
export default {
    name: 'ClientDetailmanajemenview',
    props: ['permalink'],
    data() {
        return {
            params:{},
            pagename: '',
            crumbs: [],
            data:{},
            pageparams: 'General',
        };
    },

    created() {
        this.getparams();
        this.getdata();
        
        
    },

    methods: {
        getparams(){
            this.axios.get('api/getbanner/'+this.pageparams).then((response) => {
               this.params = {
                    id: response.data.id,
                    title: 'Manajemen',
                    img: response.data.imagepath,
                    showtitle : response.data.showtitle,
                    showdesc : response.data.showdesc,
                }

                document.title ='Manajemen | Bank Padma'
                document.querySelector('head meta[name="description"]').setAttribute('content', response.data.description);
                document.querySelector('head meta[name="og:description"]').setAttribute('content', response.data.description);
                document.querySelector('head meta[name="og:title"]').setAttribute('content', 'Manajemen | Bank Padma');
                document.querySelector('head meta[name="keywords"]').setAttribute('content', response.data.keyword);
                document.querySelector('head meta[name="og:url"]').setAttribute('content', window.location.href);
                document.querySelector('head meta[name="og:image"]').setAttribute('content', response.data.imagepath);
            }).catch(error => {
                
            console.log(error)

            }).finally(() => {
                
            })
            
        },
        getdata(){
            store.commit('ChangeLoader',true)
            this.axios.get('api/getmanajemen/'+this.permalink).then((response) => {

                this.data = response.data
                
                this.pagename = response.data.title

                this.crumbs = [
                    {key:"Manajemen", link:"/manajemen"},
                    {key:response.data.position, link: "/deposito/"+response.data.permalink}
                ]

                this.pagename = this.data.position               
            }).catch(error => {
                
            console.log(error)

            }).finally(() => {
                store.commit('ChangeLoader',false)
                
            })

           
        }
    },
    components:{
        Hero,
        Breadcrumbs,
        Content,
        ProfileMenu
    }
};
</script>

<style lang="scss" scoped>

</style>