<template>
    <div>
        <ul class="mb-8 md:mb-0">
            <li v-for="m in menu" :key="m" class="text-base md:text-lg mb-3 md:mb-4 font-semibold">
                <router-link class="text-primary dark:text-gray-200 hover:text-secondary duration-200" :to="m.link">{{m.text}}</router-link>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'ClientProfileMenu',

    data() {
        return {
            menu: [
                {
                    text:'Sejarah Bank Padma',
                    link:'/sejarah'
                },
                 {
                    text:'Visi dan Misi Perusahaan',
                    link:'/visi-dan-misi'
                },
                 {
                    text:'Manajemen',
                    link:'/manajemen'
                },
                {
                    text:'Struktur Organisasi',
                    link:'/struktur-organisasi'
                },
                 {
                    text:'Penghargaan',
                    link:'/penghargaan'
                },
                 {
                    text:'Laporan Keuangan',
                    link:'/laporan-keuangan'
                },
            ]
        };
    },

    mounted() {
        
    },

    methods: {
        
    }
};
</script>

<style lang="scss" scoped>
@import "../assets/main.scss";
ul{
    li{
        .router-link-active.router-link-exact-active{
            color: $secondary !important;
        }
    }
}
</style>