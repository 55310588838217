<template>
    <div class="container-y pb-5">
        <div v-if="items.length">
            <div class="grid grid-cols-1 md:grid-cols-[2fr_1fr] gap-5 mb-5" v-for="item in items" :key="item">
                <div class="left order-2 md:order-1">
                    <div class="text-xl font-bold text-primary uppercase dark:text-gray-200 mb-3">
                        {{item.title}}
                    </div>
                    <div class="text-xs md:text-sm leading-7 md:leading-loose text-gray-500 w-full dark:text-gray-200 font-normal dark:font-normal content mb-5" v-html="item.detail">

                    </div>              
                </div>
                <div class="right order-1 md:order-2">
                    <div class="image">
                        <img :src="item.imagepath" :alt="item.title">
                    </div>
                </div>
            </div>
            <div class="md:hidden">
                <Share></Share>
            </div>
        </div>
    </div>
</template>

<script>
import store from '@/store';
import Share from '@/layouts/Share.vue';
export default {
    name: 'ClientContentSetoran',

    data() {
        return {
            items: []
        };
    },

    mounted() {
        this.getdata();
    },

    methods: {
        getdata(){
             store.commit('ChangeLoader',true)
            this.axios.get('api/getsetoran').then((response) => {
                this.items = response.data
            }).catch(error => {
                
            console.log(error)

            }).finally(() => {
                 store.commit('ChangeLoader',false)
            })
        },
    },
    components:{
        Share
    }
};
</script>

<style lang="scss" scoped>
@import "../../assets/main.scss"
</style>