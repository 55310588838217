<template>
    <div class="fixed z-50 top-0 left-0 w-full h-screen">
        <div class="relative bg-gray-200 dark:bg-gray-900 w-full h-screen flex justify-center items-center">
            <div class="logo">
                <img :src="logo" alt="Bank Padma">
            </div>
        </div>        
    </div>
</template>

<script>
import newLogo from '@/assets/logo.png'
export default {
    name: 'PadmawebPreloader',

    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
    computed:{
        logo(){
            return newLogo
        }
    }
};
</script>

<style lang="scss" scoped>
.logo{
    width: 65px;
    height: 65px;
     position: relative;
    animation: popup 1s infinite;
}

@media screen and (min-width:768px){
    .logo{
        width: 80px;
        height: 80px;
    }
}

@keyframes popup{
    from {transform: scale(1);}
    50%  {transform: scale(1.2);} /* ignored */
    to   {transform: scale(1);}
}
</style>