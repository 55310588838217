<template>
    <div class="container-y py-4 dark:bg-gray-900" :class="totalData == 0 ? 'h-96':''">

        <div class="text-2xl text-primary font-medium mb-5 dark:text-gray-100">
            Hasil Pencarian Untuk "<span class="text-secondary">{{search}}</span>"
        </div>

        <div class="text-base font-normal text-gray-700 dark:text-gray-100 mb-4" v-if="totalData > 0">
            Menampilkan {{totalData > 0 ? fromPage + 1 : 0}} - {{toPage + 1 > totalData ? totalData : toPage + 1}} dari {{totalData}}
        </div>

        <div class="text-lg font-normal dark:text-gray-100 text-gray-700 mb-4" v-if="totalData == 0">
            Tidak Ada Data Ditemukan
        </div>


        <div>
            <div class="grid md:grid-cols-[8fr_4fr] lg:grid-cols-[10fr_2fr] grid-cols-1 gap-5 mb-5 w-full lg:w-3/4" v-for="(item,index) in pagidatedItem" :key="index">
                <div class="w-full order-2 md:order-1">
                    <div class="text-lg font-bold text-primary dark:text-gray-100 hover:text-secondary duration-300 mb-1">
                        <router-link :to="item.permalink">
                            {{item.title}}
                        </router-link>
                    </div>
                    <div class="text-sm font-normal text-gray-500  dark:text-gray-200 leading-relaxed text-justify">{{item.description}}</div>
                </div>
                <div class="overflow-hidden  order-1 md:order-2">
                    <img :src="item.imagepath" :alt="item.title" class="object-cover object-center">
                </div>
            </div>
        </div>

        <div class="px-5 flex justify-between items-center h-10 mt-6" v-if="totalPages > 1">
            <ul class="flex items-center h-full mr-5">
                <li v-if="currentPage > 1 " @click="prev" class="h-full flex justify-center items-center px-2 text-xs font-medium border cursor-pointer text-primary bg-gray-100 dark:bg-gray-900 border-gray-300 dark:border-gray-800 dark:text-gray-100">Prev</li>
                <li v-for="p in totalPages" :key="p" class="h-full flex justify-center items-center px-3 text-sm font-medium border cursor-pointer" :class="currentPage === p ? 'bg-primary text-gray-100 border-primary' : 'text-primary bg-gray-100 dark:bg-gray-900 dark:text-gray-100 border-gray-300 dark:border-gray-800'" @click="change(p)">{{p}}</li>
                <li v-if="currentPage < totalPages"  @click="next" class="h-full px-2 text-xs flex justify-center items-center font-medium border cursor-pointer text-primary bg-gray-100 dark:bg-gray-900 border-gray-300 dark:text-gray-100 dark:border-gray-800">Next</li>
            </ul>
        </div>

    </div>
</template>

<script>
export default {
    name: 'PadmawebSearchView',
    props:['search'],
    data() {
        return {
            items:[],
            currentPage:1,
            perPage: 6
        };
    },

    mounted() {
        this.searchSaving();
    },

    methods: {
        change(e){
           this.currentPage = e
       },
        prev(){
           this.currentPage = this.currentPage - 1
       },
       next(){
           this.currentPage = this.currentPage + 1
       },
        async searchSaving(){
            this.items = []
            this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
            await this.axios.get('api/search/saving/'+this.search).then((response) => {
               for(let i = 0 ; i < response.data.length ; i++){
                    let obj = {
                        id: response.data[i].id,
                        title: response.data[i].title,
                        description: response.data[i].description,
                        imagepath: response.data[i].imagepath,
                        permalink: '/tabungan/'+response.data[i].permalink
                    }

                    this.items.push(obj)
               }
            }).catch(error => {
               
              console.log(error)

            }).finally(() => {
                
            })

            await this.axios.get('api/search/loan/'+this.search).then((response) => {
               for(let i = 0 ; i < response.data.length ; i++){
                    let obj = {
                        id: response.data[i].id,
                        title: response.data[i].title,
                        description: response.data[i].description,
                        imagepath: response.data[i].imagepath,
                        permalink: '/kredit/'+response.data[i].permalink
                    }

                    this.items.push(obj)
               }
            }).catch(error => {
               
              console.log(error)

            }).finally(() => {
                
            })

            await this.axios.get('api/search/deposit/'+this.search).then((response) => {
               for(let i = 0 ; i < response.data.length ; i++){
                    let obj = {
                        id: response.data[i].id,
                        title: response.data[i].title,
                        description: response.data[i].description,
                        imagepath: response.data[i].imagepath,
                        permalink: '/deposito/'+response.data[i].permalink
                    }

                    this.items.push(obj)
               }
            }).catch(error => {
               
              console.log(error)

            }).finally(() => {
                
            })

             await this.axios.get('api/search/service/'+this.search).then((response) => {
               for(let i = 0 ; i < response.data.length ; i++){
                    let obj = {
                        id: response.data[i].id,
                        title: response.data[i].title,
                        description: response.data[i].description,
                        imagepath: response.data[i].imagepath,
                        permalink: '/deposito/'+response.data[i].permalink
                    }

                    this.items.push(obj)
               }
            }).catch(error => {
               
              console.log(error)

            }).finally(() => {
                
            })

        }
    },

    watch:{
        search:function(){
            this.searchSaving();
        },
        currentPage(){
            this.pagidatedItem
        }
    },

    computed:{
        totalData:function(){
            return this.items.length
        },
        totalPages: function () {
            return Math.ceil(this.items.length / this.perPage);
        },
        fromPage(){
            return this.perPage * (this.currentPage - 1);
        },
        toPage(){
            return this.fromPage + this.perPage - 1
        },
        pagidatedItem: function () {
            return this.items.slice( this.fromPage , this.toPage + 1);
        }
    }
};
</script>

<style lang="scss" scoped>

</style>