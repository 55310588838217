<template>
    <div>
        <heroComponentVue></heroComponentVue>
    </div>
</template>

<script>
import heroComponentVue from '@/components/Admin/Component/heroComponent/heroComponent.vue';
export default {
    name: 'PadmawebHeroView',

    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
    components:{
        heroComponentVue
    }
};
</script>

<style lang="scss" scoped>

</style>