<template>
    <div>        
        <div class="fixed bottom-10 left-5 md:left-10 z-50">
            <div>
                <div @click="toTop" class="bg-primary hover:bg-secondary inline-block rounded-sm shadow-sm p-1 cursor-pointer hover:-translate-y-2 duration-300">
                    <mdicon name="arrow-up" size="30" class="text-white" />
                </div>
            </div>
        </div>
        <div class="fixed bottom-10 right-5 md:right-10 z-50">
            <div class="flex flex-col md:flex-row justify-center items-center">
                <div v-for="item in items" :key="item.id" class="hover:-translate-y-2 duration-300 py-1 md:py-0 mx-1">
                    <div class="">
                        <a :href="item.chaturl" target="_blank">
                            <mdicon :name="item.icon" size="45" :style="{'color' : item.color}" class="dark:text-white" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PadmawebChat',

    data() {
        return {
            items:[]
        };
    },

    mounted() {
        this.getData()
    },

    methods: {
        toTop(){
             document.body.scrollTop = 0;
             document.documentElement.scrollTop = 0;
        }
        ,
        getData(){
            this.axios.get('api/chatsosmed').then((response) => {
                this.items = response.data
            }).catch(error => {
               
              console.log(error)

            }).finally(() => {
                
            })
        },
    },
};
</script>

<style lang="scss" scoped>

</style>