<template>
   <div class="container-y pb-4 md:pb-8 ">
       <div class="w-full md:w-4/5">
            <ol class="list-decimal text-base md:text-xl font-medium text-secondary dark:text-gray-200 ml-5 md:mx-5">
                <li v-for="(item,i) in items" :key="i" class="mb-8 ">
                    <div class="text-base md:text-xl mb-1 font-bold text-primary dark:text-gray-200 md:ml-2">
                        {{item.question}}
                    </div>
                    <div class="text-sm md:text-base leading-loose md:leading-loose w-full dark:text-gray-200 font-normal dark:font-normal content mb-5 text-gray-700">
                        {{item.answer}}
                    </div>
                </li>
            </ol>
       </div>
   </div>
</template>

<script>
import store from '@/store';
export default {
    name: 'ClientFaq',

    data() {
        return {
            items:[]
        };
    },

    mounted() {
        this.getData() 
    },

    methods: {
        getData(){
            store.commit('ChangeLoader',true)
            this.axios.get('api/getfaq').then((response) => {
                this.items = response.data
            }).catch(error => {
                
            console.log(error)

            }).finally(() => {
                store.commit('ChangeLoader',false)
                
            })
        }
    },
};
</script>

<style lang="scss" scoped>

</style>