import SecureLS from "secure-ls" 
const  ls = new SecureLS({isCompression: false})

export default{
	methods:{
		checkUserPermission(key){
			const  ls = new SecureLS({isCompression: false})  
			const permissions = JSON.parse(ls.get('session').data.permissions)    
			let isPermitted = false;
			for(let d of permissions){
				if(this.$route.path==d.route){
					if(d[key]){
						isPermitted = true
						break;
					}else{
						break;
					}
				}
			}
			return isPermitted;
		},
		// checktoken(){
		// 	if( localStorage.length > 0 ){
		// 		return ls.get('session').data.token
		// 	}else{				
		// 		return false
		// 	}
		// },
		checktoken() {
			const sessionData = ls.get('session');
			if (sessionData && sessionData.data && sessionData.data.token) {
				return sessionData.data.token;
			} else {
				return false;
			}
		},
		checkuser(){
			const  ls = new SecureLS({isCompression: false})  
			const user = JSON.parse(ls.get('session').data.user) 
			return user
		}
	},

	computed:{
		token(){
			return this.checktoken()
		},
		user(){
			return this.checkuser()
		},
		isReadPermitted(){
			return this.checkUserPermission('read')
		},
		isCreatePermitted(){ 
			return this.checkUserPermission('create')
		},
		isUpdatePermitted(){
			return this.checkUserPermission('update')
		},
		isDeletePermitted(){
			return this.checkUserPermission('delete')
		},
		
	}
}