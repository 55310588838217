<template>
    <div>
        <tabNavVue :tabs="tabs" :selected="selected" @selected="setSelected">
            <tabVue :isSelected="selected === '1'">
               <CompanyTableVue></CompanyTableVue>
            </tabVue>
            <tabVue :isSelected="selected === '2'">
              <OfficeTableVue></OfficeTableVue>
            </tabVue>
            <tabVue :isSelected="selected === '3'">
              <ManajemenTableVue></ManajemenTableVue>
            </tabVue>
            <tabVue :isSelected="selected === '4'">
              <AwardTableVue></AwardTableVue>
            </tabVue>
        </tabNavVue>
    </div>
</template>

<script>
import tabNavVue from './tabNav.vue';
import tabVue from './tab.vue';
import CompanyTableVue from './CompanyTable.vue';
import OfficeTableVue from './OfficeTable.vue';
import ManajemenTableVue from './ManajemenTable.vue';
import AwardTableVue from './AwardTable.vue';
export default {
    name: 'PadmawebProductComponent',

    data() {
        return {
            selected:'1',

            tabs:[
                {
                    name: 'Company',
                    initial: '1',
                    icon: 'domain'
                },
                 {
                    name: 'Office',
                    initial: '2',
                    icon: 'office-building-marker-outline'
                },
                {
                    name: 'Management',
                    initial: '3',
                    icon: 'sitemap'
                },
                {
                    name: 'Awards',
                    initial: '4',
                    icon: 'seal'
                },

            ],
        };
    },

    mounted() {
        
    },

    methods: {
        setSelected(tab){
            this.selected = tab;
        },
    },
    components:{
        tabNavVue,
        tabVue,
        CompanyTableVue,
        OfficeTableVue,
        ManajemenTableVue,
        AwardTableVue
    }
};
</script>

<style lang="scss" scoped>

</style>