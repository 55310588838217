<template>
<div class="container-y pb-8">
    <v-loading v-if="isloading"></v-loading>
    <div class="border border-gray-200 dark:border-0 dark:bg-gray-700 rounded-md w-full px-5 md:px-14 py-5 md:py-8 shadow-md mb-4 md:mb-0">
        <form @submit.prevent="submitForm">
            <div class="text-lg md:text-3xl font-bold text-primary dark:text-gray-200 uppercase text-center mb-8">Formulir Pengajuan Kredit</div>
            <div class="bg-primary dark:bg-gray-800 text-white py-3 px-3 text-sm font-medium rounded-sm mb-5">Data Pinjaman</div>
            <div class="form-group mb-5">
                <div class="text-sm font-semibold text-gray-700 relative inline-block after:content-['*'] after:text-[#ff0000] after:absolute after:right-[-10px] mb-1 dark:text-gray-200">Jenis Pinjaman</div>
                <div v-if="error.jenisPinjaman" class="text-xs text-danger font-medium">{{error.jenisPinjaman[0]}}</div>
                <select name="" id="" class="w-full border-b font-medium text-sm text-gray-500 border-gray-300 dark:border-0 dark:bg-gray-300 dark:text-gray-800 py-2 px-1 focus:outline-none placeholder:text-gray-500" v-model="form.jenisPinjaman">
                    <option value="">Pilih</option>
                    <option v-for="item in items" :key="item" :value="item.title">{{item.title}}</option>
                </select>
            </div>
            <div class="form-group mb-5">
                <div class="text-sm font-semibold text-gray-700 relative inline-block after:content-['*'] after:text-[#ff0000] after:absolute after:right-[-10px] mb-1 dark:text-gray-200">Jumlah Pinjaman</div>
                <div v-if="error.plafon" class="text-xs text-danger font-medium">{{error.plafon[0]}}</div>
                <VueNumberFormat class="w-full border-b font-medium text-sm text-gray-500 border-gray-300 dark:border-0 dark:bg-gray-300 dark:text-gray-800 py-2 px-2 focus:outline-none placeholder:text-gray-500" v-model:value="form.plafon" :options="{ precision: 0, prefix: 'Rp. ', decimal: '', thousand: ',', acceptNegative: false, isInteger: false  }"></VueNumberFormat>
            </div>
            <div class="form-group mb-5">
                <div class="text-sm font-semibold text-gray-700 relative inline-block after:content-['*'] after:text-[#ff0000] after:absolute after:right-[-10px] mb-1 dark:text-gray-200">Jangka Waktu</div>
                <div v-if="error.jangkawaktu" class="text-xs text-danger font-medium">{{error.jangkawaktu[0]}}</div>
                <select name="" id="" class="w-full border-b font-medium text-sm text-gray-500 border-gray-300 dark:border-0 dark:bg-gray-300 dark:text-gray-800 py-2 px-1 focus:outline-none placeholder:text-gray-500" v-model="form.jangkawaktu">
                    <option value="">Pilih Jangka Waktu</option>
                    <option :value="L.duration" v-for="L in LD" :key="L.id">{{L.duration}}</option>
                </select>
            </div>
            <div class="form-group mb-5">
                <div class="text-sm font-semibold text-gray-700 relative inline-block after:content-['*'] after:text-[#ff0000] after:absolute after:right-[-10px] mb-1 dark:text-gray-200">Tujuan Penggunaan</div>
                <div v-if="error.tujuan" class="text-xs text-danger font-medium">{{error.tujuan[0]}}</div>
                <input type="text" class="w-full border-b font-medium text-sm text-gray-500 border-gray-300 dark:border-0 dark:bg-gray-300 dark:text-gray-800 py-2 px-2 focus:outline-none placeholder:text-gray-500" v-model="form.tujuan">
            </div>

            <div class="bg-primary dark:bg-gray-800 text-white py-3 px-3 text-sm font-medium rounded-sm mb-5">Data Pribadi Pemohon</div>
            <div class="form-group mb-5">
                <div class="text-sm font-semibold text-gray-700 relative inline-block after:content-['*'] after:text-[#ff0000] after:absolute after:right-[-10px] mb-1 dark:text-gray-200">Nama Lengkap</div>
                <div v-if="error.nama" class="text-xs text-danger font-medium">{{error.nama[0]}}</div>
                <input type="text" class="w-full border-b font-medium text-sm text-gray-500 border-gray-300 dark:border-0 dark:bg-gray-300 dark:text-gray-800 py-2 px-2 focus:outline-none placeholder:text-gray-500" v-model="form.nama">
            </div>

            <div class="form-group mb-5">
                <div class="text-sm font-semibold text-gray-700 relative inline-block after:content-['*'] after:text-[#ff0000] after:absolute after:right-[-10px] mb-1 dark:text-gray-200">Nomor KTP</div>
                <div v-if="error.ktp" class="text-xs text-danger font-medium">{{error.ktp[0]}}</div>
                <input type="text" class="w-full border-b font-medium text-sm text-gray-600 border-gray-300 dark:border-0 dark:bg-gray-300 dark:text-gray-800 py-2 px-2 focus:outline-none placeholder:text-gray-500" v-model="form.ktp">
            </div>

            <div class="form-group mb-5">
                <div class="text-sm font-semibold text-gray-700 relative inline-block after:content-['*'] after:text-[#ff0000] after:absolute after:right-[-10px] mb-1 dark:text-gray-200">Alamat Lengkap</div>
                <div v-if="error.alamat" class="text-xs text-danger font-medium">{{error.alamat[0]}}</div>
                <input type="text" class="w-full border-b font-medium text-sm text-gray-500 border-gray-300 dark:border-0 dark:bg-gray-300 dark:text-gray-800 py-2 px-2 focus:outline-none placeholder:text-gray-500" v-model="form.alamat">
            </div>

            <div class="grid gap-4 grid-cols-1 md:grid-cols-2">
                <div class="form-group mb-5">
                    <div class="text-sm font-semibold text-gray-700 relative inline-block after:content-['*'] after:text-[#ff0000] after:absolute after:right-[-10px] mb-1 dark:text-gray-200">Kota</div>
                    <div v-if="error.kota" class="text-xs text-danger font-medium">{{error.kota[0]}}</div>
                    <select name="" id="" class="w-full border-b font-medium text-sm text-gray-500 border-gray-300 dark:border-0 dark:bg-gray-300 dark:text-gray-800 py-2 px-1 focus:outline-none placeholder:text-gray-500" v-model="form.kota">
                        <option value="">Pilih</option>
                        <option v-for="c in city" :key="c" :value="c.city">{{c.city}}</option>
                    </select>
                </div>

                <div class="form-group mb-5">
                    <div class="text-sm font-semibold text-gray-700 relative inline-block after:content-['*'] after:text-[#ff0000] after:absolute after:right-[-10px] mb-1 dark:text-gray-200">Status Perkawinan</div>
                    <div v-if="error.statuskawin" class="text-xs text-danger font-medium">{{error.statuskawin[0]}}</div>
                    <select name="" id="" class="w-full border-b font-medium text-sm text-gray-500 border-gray-300 dark:border-0 dark:bg-gray-300 dark:text-gray-800 py-2 px-1 focus:outline-none placeholder:text-gray-500" v-model="form.statuskawin">
                        <option value="">Pilih</option>
                        <option value="Menikah">Menikah</option>
                        <option value="Lajang">Lajang</option>
                        <option value="Cerai">Cerai</option>
                    </select>
                </div>
            </div>

            <div class="grid gap-4 grid-cols-1 md:grid-cols-2">
                <div class="form-group mb-5">
                    <div class="text-sm font-semibold text-gray-700 relative inline-block after:content-['*'] after:text-[#ff0000] after:absolute after:right-[-10px] mb-1 dark:text-gray-200">No.Handphone</div>
                    <div v-if="error.handphone" class="text-xs text-danger font-medium">{{error.handphone[0]}}</div>
                    <input type="text" class="w-full border-b font-medium text-sm text-gray-500 border-gray-300 dark:border-0 dark:bg-gray-300 dark:text-gray-800 py-2 px-2 focus:outline-none placeholder:text-gray-500" v-model="form.handphone">
                </div>

                <div class="form-group mb-5">
                    <div class="text-sm font-semibold text-gray-700 relative inline-block after:content-['*'] after:text-[#ff0000] after:absolute after:right-[-10px] mb-1 dark:text-gray-200">Email</div>
                    <div v-if="error.email" class="text-xs text-danger font-medium">{{error.email[0]}}</div>
                    <input type="email" class="w-full border-b font-medium text-sm text-gray-500 border-gray-300 dark:border-0 dark:bg-gray-300 dark:text-gray-800 py-2 px-2 focus:outline-none placeholder:text-gray-500" v-model="form.email">
                </div>
            </div>

            <div class="form-group mb-5">
                <div class="text-sm font-semibold text-gray-700 relative inline-block after:content-['*'] after:text-[#ff0000] after:absolute after:right-[-10px] mb-1 dark:text-gray-200">Jumlah Tanggungan</div>
                <div v-if="error.tanggungan" class="text-xs text-danger font-medium">{{error.tanggungan[0]}}</div>
                <input type="text" placeholder="0" class="w-full border-b font-medium text-sm text-gray-500 border-gray-300 dark:border-0 dark:bg-gray-300 dark:text-gray-800 py-2 px-2 focus:outline-none placeholder:text-gray-500" v-model="form.tanggungan">
            </div>

            <div class="form-group mb-5">
                <div class="text-sm font-semibold text-gray-700 relative inline-block after:content-['*'] after:text-[#ff0000] after:absolute after:right-[-10px] mb-1 dark:text-gray-200">Nama Gadis Ibu Kandung</div>
                <div v-if="error.ibukandung" class="text-xs text-danger font-medium">{{error.ibukandung[0]}}</div>
                <input type="text" class="w-full border-b font-medium text-sm text-gray-500 border-gray-300 dark:border-0 dark:bg-gray-300 dark:text-gray-800 py-2 px-2 focus:outline-none placeholder:text-gray-500" v-model="form.ibukandung">
            </div>

            <div class="bg-primary dark:bg-gray-800 text-white py-3 px-3 text-sm font-medium rounded-sm mb-5">Data Pekerjaan & Penghasilan</div>

            <div class="grid gap-4 grid-cols-1 md:grid-cols-2">
                <div class="form-group mb-5">
                    <div class="text-sm font-semibold text-gray-700 relative inline-block after:content-['*'] after:text-[#ff0000] after:absolute after:right-[-10px] mb-1 dark:text-gray-200">Pekerjaan</div>
                    <div v-if="error.pekerjaan" class="text-xs text-danger font-medium">{{error.pekerjaan[0]}}</div>
                    <select name="" id="" class="w-full border-b font-medium text-sm text-gray-500 border-gray-300 dark:border-0 dark:bg-gray-300 dark:text-gray-800 py-2 px-1 focus:outline-none placeholder:text-gray-500" v-model="form.pekerjaan">
                        <option value="">Pilih</option>
                        <option v-for="j in jobs" :key="j" :value="j.job">{{j.job}}</option>
                    </select>
                </div>

                <div class="form-group mb-5">
                    <div class="text-sm font-semibold text-gray-700 relative inline-block after:content-['*'] after:text-[#ff0000] after:absolute after:right-[-10px] mb-1 dark:text-gray-200">Tempat Bekerja</div>
                    <div v-if="error.tempatKerja" class="text-xs text-danger font-medium">{{error.tempatKerja[0]}}</div>
                    <input type="text" class="w-full border-b font-medium text-sm text-gray-500 border-gray-300 dark:border-0 dark:bg-gray-300 dark:text-gray-800 py-2 px-2 focus:outline-none placeholder:text-gray-500" v-model="form.tempatKerja">
                </div>
            </div>

            <div class="form-group mb-5">
                <div class="text-sm font-semibold text-gray-700 relative inline-block after:content-['*'] after:text-[#ff0000] after:absolute after:right-[-10px] mb-1 dark:text-gray-200">Penghasilan Perbulan</div>
                <div v-if="error.penghasilan" class="text-xs text-danger font-medium">{{error.penghasilan[0]}}</div>
                <VueNumberFormat class="w-full border-b font-medium text-sm text-gray-500 border-gray-300 dark:border-0 dark:bg-gray-300 dark:text-gray-800 py-2 px-2 focus:outline-none placeholder:text-gray-500" v-model:value="form.penghasilan" :options="{ precision: 0, prefix: 'Rp. ', decimal: '', thousand: ',', acceptNegative: false, isInteger: false  }"></VueNumberFormat>
            </div>

            <div class="form-group mb-5">
                <div class="text-sm font-semibold text-gray-700 relative inline-block after:content-['*'] after:text-[#ff0000] after:absolute after:right-[-10px] mb-1 dark:text-gray-200">Pengeluaran Perbulan</div>
                <div v-if="error.pengeluaran" class="text-xs text-danger font-medium">{{error.pengeluaran[0]}}</div>
                <VueNumberFormat class="w-full border-b font-medium text-sm text-gray-500 border-gray-300 dark:border-0 dark:bg-gray-300 dark:text-gray-800 py-2 px-2 focus:outline-none placeholder:text-gray-500" v-model:value="form.pengeluaran" :options="{ precision: 0, prefix: 'Rp. ', decimal: '', thousand: ',', acceptNegative: false, isInteger: false  }"></VueNumberFormat>
            </div>

            <div class="form-group mb-5">
                <div class="text-sm font-semibold text-gray-700 relative inline-block after:content-['*'] after:text-[#ff0000] after:absolute after:right-[-10px] mb-1 dark:text-gray-200">Angsuran Bank Lain</div>
                <div v-if="error.angsuranBankLain" class="text-xs text-danger font-medium">{{error.angsuranBankLain[0]}}</div>
                <VueNumberFormat class="w-full border-b font-medium text-sm text-gray-500 border-gray-300 dark:border-0 dark:bg-gray-300 dark:text-gray-800 py-2 px-2 focus:outline-none placeholder:text-gray-500" v-model:value="form.angsuranBankLain" :options="{ precision: 0, prefix: 'Rp. ', decimal: '', thousand: ',', acceptNegative: false, isInteger: false  }"></VueNumberFormat>
            </div>

            <div class="form-group mb-5">
                <div class="text-sm font-semibold text-gray-700 relative inline-block after:content-['*'] after:text-[#ff0000] after:absolute after:right-[-10px] mb-1 dark:text-gray-200">Pendapatan Bersih</div>
                <VueNumberFormat class="w-full border-b font-medium text-sm text-gray-500 border-gray-300 dark:border-0 dark:bg-gray-300 dark:text-gray-800 py-2 px-2 focus:outline-none placeholder:text-gray-500" v-model:value="penghasilanbersih" :options="{ precision: 0, prefix: 'Rp. ', decimal: '', thousand: ',', acceptNegative: true, isInteger: false  }"></VueNumberFormat>
            </div>

            <div class="bg-primary dark:bg-gray-800 text-white py-3 px-3 text-sm font-medium rounded-sm mb-5">Data Jaminan</div>

            <div class="form-group mb-5">
                <div class="text-sm font-semibold text-gray-700 relative inline-block after:content-['*'] after:text-[#ff0000] after:absolute after:right-[-10px] mb-1 dark:text-gray-200">Jaminan</div>
                <div v-if="error.jaminan" class="text-xs text-danger font-medium">{{error.jaminan[0]}}</div>
                <select name="" id="" class="w-full border-b font-medium text-sm text-gray-500 border-gray-300 dark:border-0 dark:bg-gray-300 dark:text-gray-800 py-2 px-1 focus:outline-none placeholder:text-gray-500" v-model="form.jaminan">
                    <option value="">Pilih</option>
                    <option value="BPKB">BPKB</option>
                    <option value="Sertifikat">Sertifikat</option>
                </select>
            </div>
            <div class="form-group mb-5">
                <div class="text-sm font-semibold text-gray-700 relative inline-block after:content-['*'] after:text-[#ff0000] after:absolute after:right-[-10px] mb-1 dark:text-gray-200">Atas Nama Jaminan</div>
                <div v-if="error.atasnama" class="text-xs text-danger font-medium">{{error.atasnama[0]}}</div>
                <select name="" id="" class="w-full border-b font-medium text-sm text-gray-500 border-gray-300 dark:border-0 dark:bg-gray-300 dark:text-gray-800 py-2 px-1 focus:outline-none placeholder:text-gray-500" v-model="form.atasnama">
                    <option value="">Pilih</option>
                    <option value="Pemohon/Pasangan">Pemohon/Pasangan</option>
                    <option value="Keluarga">Keluarga</option>
                    <option value="Orang Lain">Orang Lain</option>
                </select>
            </div>

            <div class="bg-primary dark:bg-gray-800 text-white py-3 px-3 text-sm font-medium rounded-sm mb-5">Kelengkapan Data</div>

            <div class="form-group mb-5">
                <div class="text-sm font-semibold inline-block text-gray-700 relative after:content-['*'] after:text-[#ff0000] after:absolute after:right-[-10px] mb-1 dark:text-gray-200">Foto KTP (Max 2MB)</div>
                <input type="File" class="block" @change="selectktp($event)" required>
            </div>
            <div class="form-group mb-5" v-if="form.statuskawin == 'Menikah'">
                <div class="text-sm font-semibold text-gray-700 relative inline-block after:content-['*'] after:text-[#ff0000] after:absolute after:right-[-10px] mb-1 dark:text-gray-200">Foto KTP Pasangan (Max 2MB)</div>
                <input type="File" class="block" :required="form.statuskawin == 'Menikah'" @change="selectktppasangan($event)">
            </div>
            <div class="form-group mb-5">
                <div v-html="termform.baseterm" class="term text-sm font-normal text-gray-500 leading-relaxed mb-2">

                </div>
                <div class="form-check flex items-start justify-start">
                    <input type="checkbox" class="form-check-input relative mr-1" name="setuju" v-model="form.term" required>
                    <label class="form-check-label text-sm font-normal text-gray-500 dark:text-gray-200 flex items-start justify-start" for="setuju">
                        <span class="text-[#ff0000] ml-1 mr-1">*</span>
                        <div v-html="termform.subterm"></div>
                    </label>
                </div>
            </div>

            <div class="form-group mb-5">
                <div class="text-sm font-semibold text-gray-700 relative inline-block after:content-['*'] after:text-[#ff0000] after:absolute after:right-[-10px] mb-2 dark:text-gray-200">Tanda Tangan</div>
                <div class="border-gray-300 rounded-sm overflow-hidden border w-full md:w-3/4 lg:w-2/4 h-60 md:h-72 relative group dark:bg-gray-200 dark:border-0">
                    <VueSignaturePad ref="signaturePad" />
                    <div class="absolute top-0 right-0 bg-gray-400 text-white flex text-center justify-center items-center p-2 cursor-pointer lg:translate-y-[-50px] lg:group-hover:translate-y-0 duration-300" @click="clear">
                        <mdicon name="trash-can-outline" size="20" class="mr-1 cursor-pointer" />
                    </div>
                </div>
            </div>
            
            <div class="mb-4  w-full md:w-3/4 lg:w-2/4 flex justify-center items-center gap-2 border rounded-sm p-1 border-gray-300">
                <div class="h-full w-[200px]">
                    <img :src="captcha" alt="Captcha" class="h-full w-full object-cover object-center">
                </div>
                <div class="w-full">
                    <input class="focus:outline-none text-gray-900 text-[16px] md:text-[20px] placeholder:font-normal font-bold" type="number" name="" v-model="answer" placeholder="Answer" id="">
                </div>
            </div>  

            <div class="form-group mb-5 flex flex-col md:flex-row">
                <button type="submit" class="bg-primary text-white mb-2 text-xs font-semibold rounded-sm py-3 px-4 duration-300 mr-2 flex justify-center items-center">
                    <mdicon name="send" size="20" class="mr-1 cursor-pointer" @click="ajukankredit" :disabled="isloading" />{{ isloading ? 'Process....' : 'Ajukan Pinjaman' }}</button>
                <button type="Reset" class="bg-secondary text-white mb-2 text-xs font-semibold rounded-sm py-3 px-4 duration-300 mr-2 flex justify-center items-center" @click="clear">
                    <mdicon name="restore" size="20" class="mr-1 cursor-pointer" /> Reset Form</button>
            </div>
        </form>
    </div>
    <div class="md:hidden">
        <Share></Share>
    </div>
</div>
</template>

<script>
import Share from '../../layouts/Share.vue';
export default {

    name: 'ClientFormKredit',

    data() {
        return {
            isloading: false,
            modal: false,
            items: [],
            form: {
                jenisPinjaman: '',
                plafon: '',
                jangkawaktu: '',
                tujuan: '',
                nama: '',
                ktp: '',
                alamat: '',
                kota: '',
                statuskawin: '',
                handphone: '',
                email: '',
                pekerjaan: '',
                tempatKerja: '',
                penghasilan: 0,
                pengeluaran: 0,
                angsuranBankLain: 0,
                pb: 0,
                tanggungan: '',
                ibukandung: '',
                jaminan: '',
                atasnama: '',
                term: false,
                ktpdiri: null,
                ktppasangan: null
            },
            defaultform: {
                jenisPinjaman: '',
                plafon: '',
                jangkawaktu: '',
                tujuan: '',
                nama: '',
                ktp: '',
                alamat: '',
                kota: '',
                statuskawin: '',
                handphone: '',
                email: '',
                pekerjaan: '',
                tempatKerja: '',
                penghasilan: 0,
                pengeluaran: 0,
                angsuranBankLain: 0,
                pb: 0,
                tanggungan: '',
                ibukandung: '',
                jaminan: '',
                atasnama: '',
                term: false,
                ktpdiri: null,
                ktppasangan: null
            },
            city: [],
            jobs: [],
            LD: [],
            termform: {

            },
            error: [],
            captcha: '',
            sessionId: '',
            answer: '',

        };
    },

    mounted() {
        this.loadCaptcha();
        this.getLoan();
        this.getJob();
        this.getCity();
        this.getLD();
        this.getterm();
    },

    methods: {
        refreshCaptcha() {
            this.loadCaptcha()
            this.answer = ''
        },
        loadCaptcha() {
            const headers = {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'Accept': 'application/json'

            }
            this.axios.post('/api/captcha', headers).then((response) => {
                let resData = response.data
                this.captcha = resData.captcha
                this.sessionId = resData.sessionId
            })
        },
        verifyCaptcha() {
            const formData = new FormData();
            formData.append('answer', this.answer);
            formData.append('sessionId', this.sessionId);

            return this.axios.post('api/verify-captcha', formData)
                .then(() => true)
                .catch((error) => {
                    this.$notify({
                        type: "error",
                        title: 'Error',
                        text: error.response.data.message,
                    });
                    return false;
                });
        },
        async submitForm() {
            this.isloading = true;

            if (await this.verifyCaptcha()) {
                const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
                let formData = new FormData();

                if (this.form.ktppasangan !== null) {
                    formData.append('ktppasangan', this.form.ktppasangan);
                }

                if (isEmpty) {
                    alert('Sign Harus Isi');
                } else {
                    formData.append('sign', data);
                }
                formData.append('jenisPinjaman', this.form.jenisPinjaman);
                formData.append('plafon', this.form.plafon);
                formData.append('jangkawaktu', this.form.jangkawaktu);
                formData.append('tujuan', this.form.tujuan);
                formData.append('nama', this.form.nama);
                formData.append('ktp', this.form.ktp);
                formData.append('alamat', this.form.alamat);
                formData.append('kota', this.form.kota);
                formData.append('statuskawin', this.form.statuskawin);
                formData.append('handphone', this.form.handphone);
                formData.append('email', this.form.email);
                formData.append('pekerjaan', this.form.pekerjaan);
                formData.append('tempatKerja', this.form.tempatKerja);
                formData.append('penghasilan', this.form.penghasilan);
                formData.append('pengeluaran', this.form.pengeluaran);
                formData.append('angsuranBankLain', this.form.angsuranBankLain);
                formData.append('pb', this.form.penghasilan - this.form.pengeluaran - this.form.angsuranBankLain);
                formData.append('tanggungan', this.form.tanggungan);
                formData.append('ibukandung', this.form.ibukandung);
                formData.append('jaminan', this.form.jaminan);
                formData.append('atasnama', this.form.atasnama);
                formData.append('term', this.form.term);
                formData.append('ktpdiri', this.form.ktpdiri);
                formData.append('formterm', JSON.stringify(this.termform));

                this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` };
                this.axios.post('api/storefkredits', formData).then(() => {
                    this.clear();
                    this.clearform();
                    this.$notify({
                        title: "Pengajuan Kredit Sukses",
                        type: "success",
                        text: "Petugas Kami Akan Segera Menghubungi Anda"
                    });
                }).catch(error => {
                    this.$notify({
                        title: "Gagal Proses Permohonan",
                        type: "error",
                        text: "Ada Data Yang Kurang Lengkap"
                    });
                    this.error = error.response.data;
                    this.refreshCaptcha();
                })
            } else {
                this.refreshCaptcha();
            }
            this.isloading = false;
        },
        getterm() {
            this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
            this.axios.get(`api/getterm/Loan`).then((response) => {
                this.termform = JSON.parse(response.data.term)
            }).catch(error => {

                console.log(error)

            }).finally(() => {

            })
        },
        clearform: function () {
            this.form = Object.assign({}, this.defaultForm);
            this.editIndex = -1;
        },
        selectktp(event) {
            this.form.ktpdiri = event.target.files[0];
        },
        selectktppasangan(event) {
            this.form.ktppasangan = event.target.files[0];
        },
        closemodal(e) {
            this.modal = e
        },
        clear() {
            this.$refs.signaturePad.clearSignature();
        },
        save() {
            const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
        },
        getLoan() {

            this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
            this.axios.get(`api/getloan`).then((response) => {
                this.items = response.data
            }).catch(error => {

                console.log(error)

            }).finally(() => {

            })
        },
        getJob() {

            this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
            this.axios.get(`api/getjob`).then((response) => {
                this.jobs = response.data
            }).catch(error => {

                console.log(error)

            }).finally(() => {

            })
        },
        getCity() {

            this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
            this.axios.get(`api/getcity`).then((response) => {
                this.city = response.data
            }).catch(error => {

                console.log(error)

            }).finally(() => {

            })
        },
        getLD() {

            this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
            this.axios.get(`api/getloanduration`).then((response) => {
                this.LD = response.data
            }).catch(error => {

                console.log(error)

            }).finally(() => {

            })
        }
    },
    computed: {
        penghasilanbersih: {
            get: function () {
                let val = 0
                val = this.form.penghasilan - this.form.pengeluaran - this.form.angsuranBankLain;
                return val
            },
            set: function () {

            }
        },
    },
    components: {
        Share
    }
};
</script>

<style lang="scss" scoped>
.term::v-deep ol {
    li {
        list-style-type: decimal !important;
        list-style-position: inside;
    }
}

.term::v-deep ul {
    li {
        list-style-type: decimal !important;
        list-style-position: inside;
    }
}
</style>
