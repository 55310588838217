<template>
    <div class="dark:text-gray-200 bg-gray-100 dark:bg-gray-900 z-20 rounded-md shadow-md w-80 flex-col flex h-96 border border-gray-300 dark:border-gray-800">
        <div class="user flex flex-col justify-center p-5 w-full h-full border-b border-gray-200 dark:border-gray-800">
            <div class="image w-full flex justify-center">
                <div class="h-20 w-20 rounded-full overflow-hidden mb-4">
                    <img :src="this.user.imgpath" alt="Bill Gates" class="object-center h-full w-full object-cover">
                </div>                
            </div>
            <div class="text-xl font-semibold capitalize text-center w-full">
               {{this.user.name}}
            </div> 
            <div class="text-sm font-light text-center w-full text-gray-500 dark:text-gray-200">
               {{this.user.role.role}}
            </div>
            <div class="text-sm font-light text-center w-full text-gray-500 dark:text-gray-200">
                {{this.user.email}}
            </div>            
        </div>
        <div class="menu h-full flex flex-col justify-evenly items-center">
            <div class="flex border justify-center items-center font-semibold dark:text-gray-900 dark:hover:text-gray-200 text-xs border-gray-300 dark:border-none rounded-full cursor-pointer hover:bg-primary dark:hover:bg-primary dark:bg-gray-200 hover:text-gray-200 px-4 py-3 duration-300">
                <div @click="reset">
                    Change Password
                </div>
            </div>            
        </div>
        <div class="footer py-5 border-t border-gray-200 dark:border-gray-800 flex justify-center items-center">
            <div class="flex border justify-center items-center text-xs border-gray-300 dark:border-none rounded-md cursor-pointer hover:bg-primary dark:bg-gray-200 dark:text-gray-900 hover:text-gray-200 px-4 py-2 duration-300 dark:hover:bg-primary dark:hover:text-gray-200" @click="openlogout">
                <mdicon name="logout" size="20"/>
                <div>Sign Out</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ClientSetting',

    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        openlogout(){
            this.$emit('logouts', true)
        },

        reset(){
            this.$emit('resetpassword')
        },
    },
};
</script>

<style lang="scss" scoped>

</style>