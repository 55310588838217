<template>
    <div class="w-full h-80 md:h-96 lg:h-screen bg-primary dark:bg-gray-900 overflow-hidden relative">        
        <div class="image w-full h-full hidden md:block">
            <img v-if="general !== null" :src="general.loanpath" alt="Pengajuan Kredit Bank Padma" class="h-full bottom-0 right-5 md:right-20 lg:right-32 absolute">
        </div>
        <div class="absolute inset-0 w-4/5 mx-auto md:mx-0 md:ml-20 md:mt-16 lg:ml-44 lg:mt-64 h-full z-10 flex flex-col justify-center md:justify-start items-center md:items-start">
            <div class="text-lg font-medium md:text-2xl text-white w-full text-center md:text-left">
                Pengajuan Kredit Lebih Mudah
            </div>
            <div class="text-3xl md:w-4/6 lg:w-3/5 md:text-4xl lg:text-6xl md:leading-tight lg:leading-snug text-white font-black text-center md:text-left my-5 lg:my-8">
                Ajukan Kreditmu Secara Online
            </div>
            <div class="px-5 md:px-6 py-3 md:py-4 rounded bg-secondary text-white font-bold">
                <router-link to="/kredit/formulir-kredit">Ajukan Sekarang</router-link>
            </div>
        </div>
    </div>
</template>

<script>
import store from '@/store';
export default {
    name: 'ClientKredit',

    data() {
        return {
        };
    },

    mounted() {
    },

    methods: {
    },
    computed:{
        general(){
            return store.state.general
        }
    }
};
</script>

<style lang="scss" scoped>
</style>