<template>
    <div class="container-y pb-8">
        <div class="border border-gray-200 dark:bg-gray-700 dark:border-none rounded-md w-full px-5 md:px-14 py-5 md:py-8 shadow-md mb-4 md:mb-0">
            <form @submit.prevent="saveForm">
                <div class="text-lg md:text-3xl dark:text-gray-200 font-bold text-primary uppercase text-center mb-8">Formulir Lamaran Kerja</div>

                <div class="form-group mb-5">
                    <div class="text-sm font-semibold text-gray-700 relative inline-block after:content-['*'] after:text-[#ff0000] after:absolute after:right-[-10px] mb-1 dark:text-gray-200">Posisi Yang di Lamar</div>
                    <div v-if="error.job" class="text-xs text-danger font-medium">{{error.job[0]}}</div>
                    <input type="text" class="w-full border-b font-medium text-sm text-gray-500 border-gray-300 py-2 px-2 focus:outline-none placeholder:text-gray-500" :value="form.job" @change="form.job = $event.target.value">
                </div>                   

                <div class="bg-primary dark:bg-gray-800 text-white py-3 px-3 text-sm font-medium rounded-sm mb-5">Data Pribadi</div>
                <div class="form-group mb-5">
                    <div class="text-sm font-semibold text-gray-700 relative inline-block after:content-['*'] after:text-[#ff0000] after:absolute after:right-[-10px] mb-1 dark:text-gray-200">Nama Lengkap</div>
                     <div v-if="error.name" class="text-xs text-danger font-medium">{{error.name[0]}}</div>
                    <input type="text" class="w-full border-b font-medium text-sm text-gray-500 border-gray-300 py-2 px-2 focus:outline-none placeholder:text-gray-500" :value="form.name" @change="form.name = $event.target.value">
                </div>  
                <div class="form-group mb-5">
                    <div class="text-sm font-semibold text-gray-700 relative inline-block after:content-['*'] after:text-[#ff0000] after:absolute after:right-[-10px] mb-1 dark:text-gray-200">Tempat/Tanggal Lahir</div>
                     <div v-if="error.birth" class="text-xs text-danger font-medium">{{error.birth[0]}}</div>
                    <input type="text" class="w-full border-b font-medium text-sm text-gray-500 border-gray-300 py-2 px-2 focus:outline-none placeholder:text-gray-500" :value="form.birth" @change="form.birth = $event.target.value">
                </div>   
                <div class="form-group mb-5">
                    <div class="text-sm font-semibold text-gray-700 relative inline-block after:content-['*'] after:text-[#ff0000] after:absolute after:right-[-10px] mb-1 dark:text-gray-200">Jenis Kelamin</div>
                     <div v-if="error.gender" class="text-xs text-danger font-medium">{{error.gender[0]}}</div>
                    <div class="form-group mt-3 flex justify-start items-center">
                        <div class="group flex justify-center items-center mr-5">
                            <input type="radio" name="Laki-Laki" class="mr-2" value="Laki-Laki" v-model="form.gender">
                            <label for="Laki-Laki" class="text-sm font-medium text-gray-700 relative inline-block after:absolute after:right-[-10px] mb-1 dark:text-gray-200">Laki-Laki</label>
                        </div>
                        <div class="group flex justify-center items-center mr-5">
                            <input type="radio" name="Perempuan" class="mr-2" value="Perempuan" v-model="form.gender">
                            <label for="Perempuan" class="text-sm font-medium text-gray-700 relative inline-block after:absolute after:right-[-10px] mb-1 dark:text-gray-200">Perempuan</label>
                        </div>
                        
                    </div>
                </div>             
                <div class="form-group mb-5">
                    <div class="text-sm font-semibold text-gray-700 relative inline-block after:content-['*'] after:text-[#ff0000] after:absolute after:right-[-10px] mb-1 dark:text-gray-200">Agama</div>
                    <div v-if="error.religion" class="text-xs text-danger font-medium">{{error.religion[0]}}</div>
                    <div class="form-group mt-3 flex justify-start items-center">
                        <div class="group flex justify-center items-center mr-5">
                            <input type="radio" name="Islam" class="mr-2" value="Islam" v-model="form.religion">
                            <label for="Islam" class="text-sm font-medium text-gray-700 relative inline-block after:absolute after:right-[-10px] mb-1 dark:text-gray-200">Islam</label>
                        </div>
                        <div class="group flex justify-center items-center mr-5">
                            <input type="radio" name="Hindu" class="mr-2" value="Hindu" v-model="form.religion">
                            <label for="Hindu" class="text-sm font-medium text-gray-700 relative inline-block after:absolute after:right-[-10px] mb-1 dark:text-gray-200">Hindu</label>
                        </div>
                                <div class="group flex justify-center items-center mr-5">
                            <input type="radio" name="Budha" class="mr-2" value="Budha" v-model="form.religion">
                            <label for="Budha" class="text-sm font-medium text-gray-700 relative inline-block after:absolute after:right-[-10px] mb-1 dark:text-gray-200">Budha</label>
                        </div>
                        <div class="group flex justify-center items-center mr-5">
                            <input type="radio" name="Kristen" class="mr-2" value="Kristen" v-model="form.religion">
                            <label for="Kristen" class="text-sm font-medium text-gray-700 relative inline-block after:absolute after:right-[-10px] mb-1 dark:text-gray-200">Kristen</label>
                        </div>     
                        <div class="group flex justify-center items-center mr-5">
                            <input type="radio" name="Katolik" class="mr-2" value="Katolik" v-model="form.religion">
                            <label for="Katolik" class="text-sm font-medium text-gray-700 relative inline-block after:absolute after:right-[-10px] mb-1 dark:text-gray-200">Katolik</label>
                        </div>                    
                    </div>
                </div>    

                <div class="form-group mb-5">
                    <div class="text-sm font-semibold text-gray-700 relative inline-block after:content-['*'] after:text-[#ff0000] after:absolute after:right-[-10px] mb-1 dark:text-gray-200">Status</div>
                    <div v-if="error.marriedstatus" class="text-xs text-danger font-medium">{{error.marriedstatus[0]}}</div>
                    <div class="form-group mt-3 flex justify-start items-center">
                        <div class="group flex justify-center items-center mr-5">
                            <input type="radio" name="Belum Menikah" class="mr-2" value="Belum Menikah" v-model="form.marriedstatus">
                            <label for="Belum Menikah" class="text-sm font-medium text-gray-700 relative inline-block after:absolute after:right-[-10px] mb-1 dark:text-gray-200">Belum Menikah</label>
                        </div>
                        <div class="group flex justify-center items-center mr-5">
                            <input type="radio" name="Menikah" class="mr-2" value="Menikah" v-model="form.marriedstatus">
                            <label for="Menikah" class="text-sm font-medium text-gray-700 relative inline-block after:absolute after:right-[-10px] mb-1 dark:text-gray-200">Menikah</label>
                        </div>
                                <div class="group flex justify-center items-center mr-5">
                            <input type="radio" name="Cerai" class="mr-2" value="Cerai" v-model="form.marriedstatus">
                            <label for="Cerai" class="text-sm font-medium text-gray-700 relative inline-block after:absolute after:right-[-10px] mb-1 dark:text-gray-200">Cerai</label>
                        </div>                    
                    </div>
                </div>  

                 <div class="form-group mb-5">
                    <div class="text-sm font-semibold text-gray-700 relative inline-block after:content-['*'] after:text-[#ff0000] after:absolute after:right-[-10px] mb-1 dark:text-gray-200">Jenis Identitas</div>
                    <div v-if="error.idtype" class="text-xs text-danger font-medium">{{error.idtype[0]}}</div>
                    <div class="form-group mt-3 flex justify-start items-center">
                        <div class="group flex justify-center items-center mr-5">
                            <input type="radio" name="KTP" class="mr-2" value="KTP" v-model="form.idtype">
                            <label for="KTP" class="text-sm font-medium text-gray-700 relative inline-block after:absolute after:right-[-10px] mb-1 dark:text-gray-200">KTP</label>
                        </div>
                        <div class="group flex justify-center items-center mr-5">
                            <input type="radio" name="SIM" class="mr-2" value="SIM" v-model="form.idtype">
                            <label for="SIM" class="text-sm font-medium text-gray-700 relative inline-block after:absolute after:right-[-10px] mb-1 dark:text-gray-200">SIM</label>
                        </div>
                        <div class="group flex justify-center items-center mr-5">
                            <input type="radio" name="Kipem" class="mr-2" value="Kipem" v-model="form.idtype">
                            <label for="Kipem" class="text-sm font-medium text-gray-700 relative inline-block after:absolute after:right-[-10px] mb-1 dark:text-gray-200">Kipem</label>
                        </div>  
                        <div class="group flex justify-center items-center mr-5">
                            <input type="radio" name="Paspor" class="mr-2" value="Paspor" v-model="form.idtype">
                            <label for="Paspor" class="text-sm font-medium text-gray-700 relative inline-block after:absolute after:right-[-10px] mb-1 dark:text-gray-200">Paspor</label>
                        </div>                   
                    </div>
                </div>  
                
                <div class="form-group mb-5">
                    <div class="text-sm font-semibold text-gray-700 relative inline-block after:content-['*'] after:text-[#ff0000] after:absolute after:right-[-10px] mb-1 dark:text-gray-200">Nomor identitas</div>
                     <div v-if="error.idnumber" class="text-xs text-danger font-medium">{{error.idnumber[0]}}</div>
                    <input type="text" class="w-full border-b font-medium text-sm text-gray-500 border-gray-300 py-2 px-2 focus:outline-none placeholder:text-gray-500" :value="form.idnumber" @change="form.idnumber = $event.target.value">
                </div>

                <div class="form-group mb-5">
                    <div class="text-sm font-semibold text-gray-700 relative inline-block after:content-['*'] after:text-[#ff0000] after:absolute after:right-[-10px] mb-1 dark:text-gray-200">Alamat</div>
                     <div v-if="error.address" class="text-xs text-danger font-medium">{{error.address[0]}}</div>
                    <input type="text" class="w-full border-b font-medium text-sm text-gray-500 border-gray-300 py-2 px-2 focus:outline-none placeholder:text-gray-500" :value="form.address" @change="form.address = $event.target.value">
                </div>

                <div class="form-group mb-5">
                    <div class="text-sm font-semibold text-gray-700 relative inline-block after:content-['*'] after:text-[#ff0000] after:absolute after:right-[-10px] mb-1 dark:text-gray-200">Kota</div>
                     <div v-if="error.city" class="text-xs text-danger font-medium">{{error.city[0]}}</div>
                    <input type="text" class="w-full border-b font-medium text-sm text-gray-500 border-gray-300 py-2 px-2 focus:outline-none placeholder:text-gray-500" :value="form.city" @change="form.city = $event.target.value">
                </div>
                
                <div class="grid gap-4 grid-cols-1 md:grid-cols-2">
                    <div class="form-group mb-5">
                        <div class="text-sm font-semibold text-gray-700 relative inline-block after:content-['*'] after:text-[#ff0000] after:absolute after:right-[-10px] mb-1 dark:text-gray-200">No.Telp / HP</div>
                         <div v-if="error.phone" class="text-xs text-danger font-medium">{{error.phone[0]}}</div>
                        <input type="text" class="w-full border-b font-medium text-sm text-gray-500 border-gray-300 py-2 px-2 focus:outline-none placeholder:text-gray-500" :value="form.phone" @change="form.phone = $event.target.value">
                    </div>

                    <div class="form-group mb-5">
                        <div class="text-sm font-semibold text-gray-700 relative inline-block after:content-['*'] after:text-[#ff0000] after:absolute after:right-[-10px] mb-1 dark:text-gray-200">Email</div>
                         <div v-if="error.email" class="text-xs text-danger font-medium">{{error.email[0]}}</div>
                        <input type="email" class="w-full border-b font-medium text-sm text-gray-500 border-gray-300 py-2 px-2 focus:outline-none placeholder:text-gray-500" :value="form.email" @change="form.email = $event.target.value">
                    </div>
                </div>          

                <div class="bg-primary dark:bg-gray-800 text-white py-3 px-3 text-sm font-medium rounded-sm mb-5">Dokumen Pendukung </div>
                 <div class="form-group mb-5">
                    <div class="text-sm font-semibold inline-block text-gray-700 relative after:content-['*'] after:text-[#ff0000] after:absolute after:right-[-10px] mb-1 dark:text-gray-200">Pas Foto</div>
                    <input type="File" class="block" @change="selectfoto($event)">
                </div>
                <div class="form-group mb-5">
                    <div class="text-sm font-semibold inline-block text-gray-700 relative after:content-['*'] after:text-[#ff0000] after:absolute after:right-[-10px] mb-1 dark:text-gray-200">(CV , Surat Lamaran, Kartu Identitas, dll)</div>
                    <input type="File" multiple accept="application/pdf" class="block" @change="selectdocument($event)">
                </div>

                
               <div class="form-group mb-5">
                    <div v-html="termform.baseterm" class="term text-sm font-normal text-gray-500 leading-relaxed mb-2">

                    </div>
                    <div class="form-check flex justify-start items-start">
                    <input type="checkbox" class="form-check-input relative mr-1" name="setuju" v-model="form.term" required>
                        <label class="form-check-label text-sm font-normal text-gray-500 dark:text-gray-200 flex" for="setuju">
                            <span class="text-[#ff0000] ml-1 mr-1">*</span> <div v-html="termform.subterm"></div>
                        </label>
                    </div>
                </div>

                <div class="form-group mb-5">
                    <div class="text-sm font-semibold text-gray-700 relative inline-block after:content-['*'] after:text-[#ff0000] after:absolute after:right-[-10px] mb-2 dark:text-gray-200">Tanda Tangan</div>               
                    <div class="border-gray-300 rounded-sm dark:bg-gray-200 dark:border-0 overflow-hidden border w-full md:w-3/4 lg:w-2/4 h-60 md:h-72 relative group">
                        <VueSignaturePad ref="signaturePad" />
                        <div class="absolute top-0 right-0 bg-gray-400 text-white flex text-center justify-center items-center p-2 cursor-pointer lg:translate-y-[-50px] lg:group-hover:translate-y-0 duration-300" @click="clear"><mdicon name="trash-can-outline" size="20" class="mr-1 cursor-pointer" /></div>
                    </div>                        
                </div>
               
                 <div class="form-group mb-5 flex flex-col md:flex-row">
                     <button type="submit" class="bg-primary text-white mb-2 text-xs font-semibold rounded-sm py-3 px-4 duration-300 mr-2 flex justify-center items-center" @click="save"><mdicon name="send" size="20" class="mr-1 cursor-pointer" :disabled="isloading"/>{{isloading ? 'Proses....' : 'Kirim Lowongan' }}</button>
                     <button type="Reset" class="bg-secondary text-white mb-2 text-xs font-semibold rounded-sm py-3 px-4 duration-300 mr-2 flex justify-center items-center" @click="clear"><mdicon name="restore" size="20" class="mr-1 cursor-pointer" /> Reset Form</button>  
                 </div>
            </form>
        </div>
         <div class="md:hidden">
            <Share></Share>
        </div>        
    </div>
</template>

<script>
import Share from '@/layouts/Share.vue';
export default {
    
    name: 'ClientFormLowongan',

    data() {
        return {
            isloading:false,
             modal: false,
            form: {
               photo:null,
               document:[],
               job:'',
               name:'',
               birth:'',
               gender:'',
               religion:'',
               marriedstatus:'',
               idtype:'',
               idnumber:'',
               address:'',
               city:'',
               phone:'',
               email:'',
                term:false,
                sign:null,
            } ,   
            defaultform: {
               photo:null,
               document:[],
               job:'',
               name:'',
               birth:'',
               gender:'',
               religion:'',
               marriedstatus:'',
               idtype:'',
               idnumber:'',
               address:'',
               city:'',
               phone:'',
               email:'',
                term:false,
                sign:null,
            }  ,   
            
            error:[],
            termform:{

            }
        };
    },

    mounted() {
        this.getterm();
    },

    methods: {     
         getterm(){
            this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
            this.axios.get(`api/getterm/Career`).then((response) => {
                this.termform = JSON.parse(response.data.term)
            }).catch(error => {
                
                console.log(error)

            }).finally(() => {
                
            })
        },   
        clearform: function() {
            this.form = Object.assign({}, this.defaultform);
        },
        clear() {
            this.$refs.signaturePad.clearSignature();
        },
        selectfoto(event){
           this.form.photo = event.target.files[0];
        },
        selectdocument(event){
            this.form.document = [];
            let selectedFiles=event.target.files;
            if(!selectedFiles.length){
                 return false;
            }
            for(let i=0;i<selectedFiles.length;i++){
                this.form.document.push(selectedFiles[i]);
                
            }
        },
       save() {
            this.isloading = true;
            const { isEmpty, data } = this.$refs.signaturePad.saveSignature();

            let formData = new FormData();
                formData.append('photo', this.form.photo);

                for(let i=0 ; i<this.form.document.length; i++){
                    formData.append('document[]', this.form.document[i]);
                }
                
                formData.append('job', this.form.job);
                formData.append('name', this.form.name);
                formData.append('birth', this.form.birth);
                formData.append('gender', this.form.gender);
                formData.append('religion', this.form.religion);
                formData.append('marriedstatus', this.form.marriedstatus);
                formData.append('idtype', this.form.idtype);
                formData.append('idnumber', this.form.idnumber);
                formData.append('address', this.form.address);
                formData.append('phone', this.form.phone);
                formData.append('email', this.form.email);
                formData.append('city', this.form.city);
                formData.append('term', this.form.term);
                formData.append('formterm', JSON.stringify(this.termform)); 
                if(isEmpty){
                     this.$notify({
                        title: "Gagal Proses Permohonan",
                        type: "error",
                        text: "Tanda Tangan Harus Diisi"
                    });
                }else{
                    formData.append('sign',data );
                }             
                    this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
                    this.axios.post('api/storevacancies',formData).then(() => {
                    this.clear();
                    this.clearform();
                    this.$notify({
                        title: "Lowongan Kerja Terkirim",
                        type: "success",
                        text: "Petugas Kami Akan Segera Menghubungi Anda"
                    });
                }).catch(error => {                
                    this.$notify({
                        title: "Gagal Proses Permohonan",
                        type: "error",
                        text: "Ada Data Yang Kurang Lengkap"
                    });
                    this.error = error.response.data
                }).finally(() => {
                     this.isloading = false;
                })
        }
    },
    computed:{ 
    },
    components:{
        Share
    }
};
</script>

<style lang="scss" scoped>

</style>