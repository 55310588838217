<template>
    <div :class=" isDark ? 'dark' : '' ">
        <div class="font-Poppins bg-gray-200  dark:bg-gray-800 text-gray-700 dark:text-gray-200">
            <div class="flex h-screen">
                <Sidebar :menu="menuOpen" :menuItems="permissions" @closemenu="menuOpen =!menuOpen"></Sidebar>
                <main class="w-full">
                    <nav class="w-full px-8 flex py-5 md:py-4 bg-gray-100 dark:bg-gray-900 border-b border-gray-300 dark:border-gray-800">
                        <div class="w-full flex items-center justify-start">
                            <div class="menu">
                                <mdicon name="menu" size="30" class="cursor-pointer" @click="menuOpen = !menuOpen"/>
                            </div>
                            <div class="hidden md:block md:text-lg ml-2 md:ml-3 font-bold">Bank Padma</div>
                        </div>
                        <div class="w-full flex items-center justify-end">
                            <div class="switch h-full flex justify-center items-center cursor-pointer ml-3" @click="changedark">
                                <div class="h-full flex justify-center items-center">
                                    <mdicon v-if="!isDark" name="toggle-switch-off" size="28" class="mr-1"/>
                                    <mdicon v-if="isDark" name="toggle-switch" size="28" class="mr-1 cursor-pointer text-primary"/>
                                </div>                        
                                <div  class="text-xs font-medium dark:text-primary">
                                    Dark Mode
                                </div>   
                            </div> 
                            <div class="cursor-pointer ml-2 md:ml-4">
                                <mdicon name="account-cog" size="28" @click="setting = !setting"/>
                            </div>                                                  
                        </div>                        
                    </nav>
                    <div class="m-5">
                        <div class="text-xs font-thin uppercase text-gray-700 dark:text-gray-400">Overview</div>
                        <div class="text-base font-bold capitalize">
                            {{ this.$route.meta.pagename }}
                        </div>
                        <div class="mt-3">
                            <slot></slot>
                        </div>                    
                    </div>                
                </main>
            </div>
            <Transition name="slide-fade">
                <div class="absolute top-16 mx-auto right-1/2 translate-x-1/2 md:right-10 md:translate-x-0" v-if="setting">                
                        <Setting @logouts="logoutmodal" @resetpassword="resetpass = !resetpass"></Setting>               
                </div>
             </Transition>
             
             <Transition name="fade">
                <Confirm v-if="logoutconfirm" @closeconfirm="logoutconfirm = false">
                    <template v-slot:header>
                        Confirm
                    </template>
                    <template v-slot:main>
                        Are You Sure You Want To Logout ?
                    </template>
                    <template v-slot:footer>
                        <div class="flex justify-end items-center">
                            <LButton class="bg-gray-300 text-gray-800 mr-2" @click="logoutconfirm = false">
                                Cancel
                            </LButton>
                            <LButton class="text-white bg-secondary" :class=" isloading ? 'pointer-events-none' : 'cursor-pointer'" :disabled="isloading" @click="logout()">
                                {{ isloading ? 'Loading...' : 'Confirm' }}
                            </LButton>                            
                        </div>
                    </template>
                </Confirm>  
             </Transition>

             <FormBaseVue v-if="resetpass" @closeModal="resetpass = !resetpass">
                    <template v-slot:formName>
                    {{title}}
                    </template>
                    <template v-slot:form>
                        <div class="mb-10 grid gap-3 grid-cols-1">
                            <div class="form-group mb-5">
                                <input type="password" placeholder="New Password" class="w-full border-b border-gray-400 dark:border-gray-800 dark:text-gray-100 bg-transparent focus:outline-none py-2 px-2 text-sm font-normal text-gray-700" v-model="form.newpass">
                            </div>   
                            <div class="form-group">
                                <input type="password" placeholder="Confirm Password" class="w-full border-b border-gray-400 dark:border-gray-800 dark:text-gray-100 bg-transparent focus:outline-none py-2 px-2 text-sm font-normal text-gray-700" v-model="form.confirmpass">
                            </div>            
                        </div>
                    </template>
                    <template v-slot:formFooter>
                        <div class="text-primary dark:text-gray-100 font-medium text-lg cursor-pointer hover:bg-gray-300 dark:hover:bg-gray-800 dark:hover:text-gray-100 py-2 px-4 rounded" @click="resetpass = !resetpass">
                            Cancel
                        </div> 
                        <div class="text-primary dark:text-gray-100 font-medium text-lg cursor-pointer hover:bg-gray-300 dark:hover:bg-gray-800 dark:hover:text-gray-100 py-2 px-4 rounded" @click="resetpassword">
                            Save
                        </div>                        
                    </template>
            </FormBaseVue>
            
        </div>
    </div>
</template>

<script>
import Sidebar from './sidebar.vue'
import Setting from '../layouts/setting.vue'
import SecureLS from "secure-ls" 
const  ls = new SecureLS({isCompression: false})
import Confirm from '../layouts/confirm.vue'
import LButton from './button.vue'
import FormBaseVue from './FormBase.vue'
export default {
    name: 'ClientAdminLayout',

    data() {
        return {
            resetpass:false,
            isloading:false,
            logoutconfirm:false,
            isDark : false,
            menuOpen : false,
            setting: false,
            permissions : [],
            users: {},
            form:{
                newpass:'',
                confirmpass:''
            }
        };
    },

    mounted() {
        this.darkmode()
        this.getpermissions()
    },

    methods: {
        changedark(){
            this.isDark = !this.isDark
        },
        darkmode(){
            if (window.matchMedia && 
                    window.matchMedia('(prefers-color-scheme: dark)').matches) {
                    this.isDark = true
                } else {
                    this.isDark = false
                }
        },

        getpermissions(){
          this.permissions = JSON.parse(this.user.role.permissions)
        },

        logout(){
            this.isloading = true
             this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
             this.axios.post('api/logout').then(() => {
             ls.removeAll()
             this.$router.push({name: 'Login'})
            }).catch(error => {
               
               console.log(error)

            }).finally(() => {
               this.isloading = false,
               this.logoutconfirm = false
            })
        },
        logoutmodal(){
            this.setting = false;
            this.logoutconfirm = true;
            
        },
        resetpassword(){
            if(this.form.newpass == this.form.confirmpass){               
                    this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
                    this.axios.post(`api/resetpassword/${this.user.id}`,this.form).then(() => {
                    this.resetpass = false
                     this.$notify({
                        title: "Notification",
                        type: "success",
                        text: "Password Reset Succesfully"
                    });

                }).catch(error => {                
                    console.log(error)
                }).finally(() => {
                })

            }else{
                 this.$notify({
                    title: "Error",
                    type: "error",
                    text: "Password Not Match"
                });
            }
            
        }
    },
    components:{
        Sidebar,
        Setting,
        Confirm,
        LButton,
        FormBaseVue
    }
};
</script>

<style lang="scss" scoped>
    .slide-fade-enter-active {
        transition: all 0.3s ease-out;
    }

    .slide-fade-leave-active {
        transition: all 0.3s ease-in-out;
    }

    .slide-fade-enter-from,
    .slide-fade-leave-to {
        transform: translateY(-20px);
        opacity: 0;
    }

    .fade-enter-active,
    .fade-leave-active {
    transition: opacity 0.3s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
    opacity: 0;
    }
</style>