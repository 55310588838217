<template>
    <div>
        <CareerTableVue></CareerTableVue>
    </div>
</template>

<script>
import CareerTableVue from '@/components/Admin/Component/CareerTable.vue';
export default {
    name: 'PadmawebCareerView',

    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
    components:{
        CareerTableVue
    }
};
</script>

<style lang="scss" scoped>

</style>