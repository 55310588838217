<template>
    <div class="bg-white dark:bg-gray-800">      
        <Hero :hero="params"></Hero>
        <Breadcrumbs :breadcrumbs="crumbs" :page="pagename"></Breadcrumbs>
        <Content :data="blogcategory" @gantititle="ganti"></Content>
    </div>
</template>

<script>
import Breadcrumbs from '../layouts/breadcrumb.vue'
import Hero from '../layouts/Hero.vue'
import Content from '@/components/Blog/ContentSearch.vue';
export default {
    name: 'ClientSearchView',

    data() {
        return {
            params:{},
            pagename: 'Pencarian',
            crumbs: [],
            pageparams: 'Pencarian',
            blogcategory:this.category
        };
    },
    props:['category'],

    mounted() {
      
       
    },

    methods: {
        getparams(e){
             this.axios.get('api/getbanner/'+this.pageparams).then((response) => {
               this.params = {
                    id: response.data.id,
                    title: e,
                    img: response.data.imagepath,
                    description : response.data.description,
                    showtitle : response.data.showtitle,
                    showdesc : 'false',
                }

                document.title = 'Blog Kategori : '+ e
                document.querySelector('head meta[name="description"]').setAttribute('content', response.data.description);
                document.querySelector('head meta[name="og:description"]').setAttribute('content', response.data.description);
                document.querySelector('head meta[name="og:title"]').setAttribute('content', 'Blog Kategori : '+ e);
                document.querySelector('head meta[name="keywords"]').setAttribute('content', response.data.keyword);
                document.querySelector('head meta[name="og:url"]').setAttribute('content', window.location.href);
                document.querySelector('head meta[name="og:image"]').setAttribute('content', response.data.imagepath);
            }).catch(error => {
                
            console.log(error)

            }).finally(() => {
                
            })
            
            this.crumbs = [{key:"Blog", link:"/blog"},{key:this.pname, link:"/blog/kategori/"+this.category}]
        },
        ganti(e){
            this.getparams(e)
        }
    },
    components:{
        Hero,
        Breadcrumbs,
        Content
    },
    watch:{
        
    },
    computed:{
        pname(){
            const result1 = this.category.replaceAll('-', ' ');
            return result1
        }
    }
};
</script>

<style lang="scss" scoped>

</style>