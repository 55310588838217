<template>
   <div class="container-y grid grid-cols-1 gap-8 py-6 md:py-8 bg-white dark:bg-gray-800">
        <div class="w-full h-64 md:h-96 lg:h-screen" v-if="loading">

        </div>
       <div class="top" v-if="items.length">
           <div class="text-primary text-xl md:text-2xl font-extrabold uppercase mb-4 md:mb-8 dark:text-gray-200">
               Lokasi Kantor
           </div>
           <div class="container-x">
               <div class="grid grid-cols-1 md:grid-cols-3 gap-5 lg:gap-14">
                   <div class="item flex flex-col" v-for="(item,i) in items" :key="i">
                       <div class="text-primary dark:text-gray-200 font-bold text-base md:text-lg mb-1 uppercase cursor-pointer" @click="changeMap(item.map)">
                           {{item.office}}
                       </div>
                       <div class="text-gray-700 dark:text-gray-200 font-normal text-sm md:text-base mb-1">
                           {{item.address}}
                       </div>
                       <div class="text-gray-700 dark:text-gray-200 font-normal text-sm md:text-base">
                           {{item.phone}}
                       </div>
                   </div>
               </div>
           </div>
       </div>
       <div class="bottom" v-if="items.length">
            <div class="text-primary dark:text-gray-200 text-xl md:text-2xl font-extrabold uppercase mb-8">
              Site Maps
           </div>
           <div class="container-x">
               <iframe :src="map" class="frame" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
           </div>
       </div>
   </div>
</template>

<script>
import store from '@/store';
export default {
    name: 'ClientLokasiview',

    data() {
        return {
            map: '',
            items:[
                                  
            ]
        };
    },

    mounted() {
        this.getData()
    },

    methods: {
        getData(){
             store.commit('ChangeLoader',true)
            this.axios.get('api/getoffice').then((response) => {               
                this.items = response.data
                this.map = response.data[0].map
            }).catch(error => {
                
            console.log(error)

            }).finally(() => {
                store.commit('ChangeLoader',false)
                
            })
        },
        changeMap(e){
            this.map = e;
        }
    },
    beforeRouteEnter (to, from , next){
        next( async vm => {
            document.title = 'Lokasi Kantor | Bank Padma'
        })
    },
    computed:{
        loading(){
            return store.state.preloader
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../assets/main.scss';

.frame{
    width: 100%;
    height: 300px;
}

@media screen and (min-width:768px) {
    .frame{
        width: 100%;
        height: 400px;
    }
}
@media screen and (min-width:1024px) {
    .frame{
        width: 100%;
        height: 500px;
    }
}
</style>