<template>
    <div class="overflow-hidden bg-white p-5 rounded shadow mt-3">
        <div class="flex mb-2">
            <button class="text-sm font-medium focus:outline-none active:translate-y-[2px] duration-200 bg-primary inline-block relative h-8 rounded shadow w-16 text-center text-gray-100" @click="addModal" v-if="isCreatePermitted">Add</button>
            <button class="text-sm font-medium focus:outline-none active:translate-y-[2px] duration-200 bg-danger inline-block relative h-8 rounded shadow w-16 text-center text-gray-100 mx-2" @click="deleteModal(items.id)" v-if="isCreatePermitted">Delete</button>
            <button class="text-sm font-medium focus:outline-none active:translate-y-[2px] duration-200 bg-secondary inline-block relative h-8 rounded shadow w-16 text-center text-gray-100" @click="editModal(items.id)" v-if="isCreatePermitted">Update</button>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-5 w-full overflow-hidden" v-if="Object.keys(items).length > 0">
            <div class="overflow-hidden h-96 border border-gray-300 shadow px-5 pt-2">
                <div class="text-xl font-bold">About Image</div>
                <img :src="items.companypath" class="h-full object-cover object-center" alt="">
            </div>
            <div class="overflow-hidden h-96 border border-gray-300 shadow px-5 pt-2">
                 <div class="text-xl font-bold">Loan Image</div>
                <img :src="items.loanpath" class="h-full" alt="">
            </div>
            <div class="overflow-hidden h-96 border border-gray-300 shadow px-5 pt-2">
                 <div class="text-xl font-bold">Suggestion Image</div>
                <img :src="items.suggestionpath" class="h-full" alt="">
            </div>
        </div>
       <FormBase v-if="modal" @closeModal="modal = !modal">
            <template v-slot:formName>
               {{title}}
            </template>
            <template v-slot:form>
                <div class="mb-10">
                    <div class="form-group flex flex-col">
                        <label for="" class="text-sm font-medium text-gray-500 dark:text-gray-100">Company Image</label>
                        <input type="file" name="" id="" @change="selectCompany($event)">
                    </div>
                </div>
                <div class="mb-10">
                    <div class="form-group flex flex-col">
                        <label for="" class="text-sm font-medium text-gray-500 dark:text-gray-100">Loan Image</label>
                        <input type="file" name="" id="" @change="selectLoan($event)">
                    </div>
                </div>
                <div class="mb-10">
                    <div class="form-group flex flex-col">
                        <label for="" class="text-sm font-medium text-gray-500 dark:text-gray-100">Suggestion Image</label>
                        <input type="file" name="" id="" @change="selectSuggestion($event)">
                    </div>
                </div>

            </template>
             <template v-slot:formFooter>
                  <div class="text-primary dark:text-gray-100 font-medium text-lg cursor-pointer hover:bg-gray-300 dark:hover:bg-gray-800 dark:hover:text-gray-100 py-2 px-4 rounded" @click="modal = !modal">
                      Cancel
                  </div> 
                   <div class="text-primary dark:text-gray-100 font-medium text-lg cursor-pointer hover:bg-gray-300 dark:hover:bg-gray-800 dark:hover:text-gray-100 py-2 px-4 rounded" @click="save">
                      Save
                  </div>                        
            </template>
       </FormBase>
        <Transition name="fade">
            <Confirm v-if="deletemodal" @closeconfirm="deletemodal = false">
                <template v-slot:header>
                    Confirm
                </template>
                <template v-slot:main>
                    Are You Sure You Want To Delete This Data ?
                </template>
                <template v-slot:footer>
                    <div class="flex justify-end items-center">
                        <LButton class="bg-gray-300 text-gray-800 mr-2" @click="deletemodal = false">
                            Cancel
                        </LButton>
                        <LButton class="text-white bg-secondary" :class=" isloading ? 'pointer-events-none' : 'cursor-pointer'" :disabled="isloading" @click="deleteData">
                            {{ isloading ? 'Loading...' : 'Confirm' }}
                        </LButton>                            
                    </div>
                </template>
            </Confirm>  
        </Transition>
    </div>
</template>

<script>
import LButton from '@/layouts/button.vue'
import Confirm from '@/layouts/confirm.vue'
import FormBase from '@/layouts/FormBase.vue'
import DataTable from '@/layouts/DataTable.vue'
export default {
    name: 'PadmawebGeneral',

    data() {
        return {
            isLoading:false,
            deletemodal: false,
            editIndex : -1,
            deleteID: null,
            form : {
               loan:null,
               company:null,
               suggestion:null
            },
           defaultForm : {
               loan:null,
               company:null,
               suggestion:null
            },
            headers:[],
            items:{},
            currentPage : 1,
            modal:false,
            roles: [],
            search: ''
        };
    },

    watch:{
        search:function(){
            if(this.search.length > 0){
                this.searchData(this.search)
            } else {
                this.getData()
            }
            
        },
        modal(val) {
			val || this.clearform();
		}
    },

    mounted() {
        this.getData()
    },

    methods: {
        addModal(){
            this.editIndex = -1
            this.modal = !this.modal
        },
        deleteModal(val){
            this.deleteID = val
            this.deletemodal = true
        },
        editModal(val){
            this.editIndex = 1
            this.form.id = val

            this.modal = true
        },
        selectCompany(event){
           this.form.company = event.target.files[0];
        },
         selectLoan(event){
           this.form.loan = event.target.files[0];
        },
         selectSuggestion(event){
           this.form.suggestion = event.target.files[0];
        },
        changePage(e){
            this.currentPage = e;
            this.getData()
        },

        getData(){
            this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
            this.axios.get('api/generals').then((response) => {
                this.items = response.data
            }).catch(error => {
               
              console.log(error)

            }).finally(() => {
                
            })
        },
        searchData(val){
            this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
            this.axios.get('api/testimonial/search/'+val).then((response) => {
                this.items = response.data
            }).catch(error => {
               
              console.log(error)

            }).finally(() => {
                
            })
        },
        pageChange: function(e){
           this.currentPage = e;
           this.getData()
        },

        clearform: function() {
            this.modal = false
            this.$nextTick(() => {
                this.form = Object.assign({}, this.defaultForm);
                this.editIndex = -1;
            });
        }
           
        ,
        save(){
            if(this.editIndex > -1){
                let formData = new FormData();

                if( this.form.company !== null){
					formData.append('company', this.form.company);
				}
                if( this.form.loan !== null){
					formData.append('loan', this.form.loan);
				}
                if( this.form.suggestion !== null){
					formData.append('suggestion', this.form.suggestion);
				}
                formData.append('id', this.form.id);
                    this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
                    this.axios.post('api/generals/update',formData).then(() => {
                    this.getData();
                    this.modal = false
                    this.clearform();
                    this.$notify({
                        title: "Notification",
                        type: "success",
                        text: "Data Updated Successfully"
                    });
                }).catch(error => {                
                    console.log(error)
                }).finally(() => {
                })
            }else{
                console.log(this.form)
                let formData = new FormData();	
                formData.append('company', this.form.company);
                formData.append('loan', this.form.loan);
				formData.append('suggestion', this.form.suggestion);
                    this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
                    this.axios.post('api/generals',formData).then(() => {
                    this.getData();
                    this.clearform()
                     if(response.status == 200){
                        this.$notify({
                            title: "Notification",
                            type: "success",
                            text: "Data Created Successfully"
                        });
                    } else if(response.status == 201){
                        this.$notify({
                            title: "Notification",
                            type: "error",
                            text: response.data.msg
                        });

                        console.log(response)
                    }
                }).catch(error => {                
                    console.log(error)
                }).finally(() => {
                })
            }
        },
        deleteData(){
            this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
            this.axios.delete('api/generals/'+this.deleteID).then((response) => {
                this.getData()
                this.deleteID = null
                this.deletemodal = false
                this.clearform()
                    this.$notify({
                    title: "Notification",
                    type: "success",
                    text: "Data Deleted Successfully"
                });
            }).catch(error => {
               
              console.log(error)

            }).finally(() => {
                
            })
        }
    },
    components:{
        DataTable,
        FormBase,
        Confirm,
        LButton
    },
    computed:{
        title(){
            if(this.editIndex > -1){
                return 'Edit Item'
            }else{
                return 'New Item'
            }
        }
    }
};
</script>

<style lang="scss" scoped>

</style>