<template>
    <div class="container-y py-5 dark:bg-gray-700">
        <div class="text-2xl text-primary font-medium mb-5 dark:text-gray-100">
            Tags " <span class="text-secondary">{{tag}}</span> "
        </div>

        <div class="text-gray-500 dark:text-gray-100 font-normal text-base mb-3"  v-if="page > 1">
            Menampilkan {{items.from}}-{{items.to}} dari {{items.total}}
        </div>
        <div>
            <div class=" grid md:grid-cols-[8fr_4fr] lg:grid-cols-[10fr_2fr] grid-cols-1 gap-5 mb-5 w-full lg:w-3/4" v-for="item in items.data" :key="item.id">
                <div class="w-full order-2 md:order-1">
                    <div class="text-lg font-bold text-primary hover:text-secondary duration-300 mb-1 dark:text-gray-100">
                        <router-link :to="{name : 'DBlog', params: { blog: item.permalink } }">
                            {{item.title}}
                        </router-link>
                    </div>
                    <div class="text-sm font-normal text-gray-500 leading-relaxed text-justify dark:text-gray-100">{{item.description}}</div>
                </div>
                <div class="overflow-hidden  order-1 md:order-2">
                    <img :src="item.imagepath" :alt="item.title" class="object-cover object-center">
                </div>
            </div>
        </div>

        <div class="px-5 flex justify-between items-center h-10 mt-6" v-if="page > 1">
            <ul class="flex items-center h-full mr-5">
                <li v-if="currentPage > 1 " @click="prev" class="h-full flex justify-center items-center px-2 text-xs font-medium border cursor-pointer text-primary bg-gray-100 dark:bg-gray-900 border-gray-300 dark:border-gray-800 dark:text-gray-100">Prev</li>
                <li v-for="p in pages" :key="p" class="h-full flex justify-center items-center px-3 text-sm font-medium border cursor-pointer" :class="currentPage === p ? 'bg-primary text-gray-100 border-primary' : 'text-primary bg-gray-100 dark:bg-gray-900 dark:text-gray-100 border-gray-300 dark:border-gray-800'" @click="change(p)">{{p}}</li>
                <li v-if="currentPage < page"  @click="next" class="h-full px-2 text-xs flex justify-center items-center font-medium border cursor-pointer text-primary bg-gray-100 dark:bg-gray-900 border-gray-300 dark:text-gray-100 dark:border-gray-800">Next</li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PadmawebBlogTagView',
    props:['tag'],
    data() {
        return {
            items:[],
            currentPage:1
        };
    },

    mounted() {
        this.getData();
    },

    methods: {
         change(e){
           this.currentPage = e
       },
        prev(){
           this.currentPage = this.currentPage - 1
       },
       next(){
           this.currentPage = this.currentPage + 1
       },
        async getData(){
            this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
            await this.axios.get('api/getblogbytag/'+this.tag+'?page='+this.currentPage).then((response) => {
                this.items = response.data
            }).catch(error => {
               
              console.log(error)

            }).finally(() => {
                
            })
        }
    },
     computed:{
        page(){
            return Math.ceil(this.items.total / this.items.per_page)
        },
        pages(){
            const onSides = 1
            let pages = [];
            // Loop through
            for (let i = 1; i <= this.page; i++) {
                // Define offset
                let offset = (i == 1 || this.page) ? onSides + 1 : onSides;
                // If added
                if (i == 1 || (this.currentPage - offset <= i && this.currentPage + offset >= i) ||
                    i == this.currentPage || i == this.page) {
                    pages.push(i);
                } else if (i == this.currentPage - (offset + 1) || i == this.page + (offset + 1)) {
                    pages.push('...');
                }
            }
            return pages;            
        }
    },
    watch:{
        currentPage:function(){
            this.getData();
        }
    }
};
</script>

<style lang="scss" scoped>

</style>