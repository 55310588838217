<template>
   <div class="container-y mt-8 b-4">
       <div class="grid gap-4 grid-cols-1 md:grid-cols-[2fr_1fr]">
           <div class="left">
               <Cat></Cat>
           </div>
           <div class="right">
               <Popular></Popular>
           </div>
       </div>
   </div>
</template>

<script>
import Cat from './cat.vue'
import Popular from './popular.vue'
export default {
    name: 'ClientCategory',

    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
    components:{
        Cat,
        Popular
    }
};
</script>

<style lang="scss" scoped>

</style>