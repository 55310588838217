<template>
    <div>
        <VacancyTableVue></VacancyTableVue>
    </div>
</template>

<script>
import VacancyTableVue from '@/components/Admin/Component/VacancyComponent/VacancyTable.vue';
export default {
    name: 'PadmawebVacancyView',

    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
    components:{
        VacancyTableVue
    }
};
</script>

<style lang="scss" scoped>

</style>