<template>
    <div>
        <TestimonialTableVue></TestimonialTableVue>
    </div>
</template>

<script>
import TestimonialTableVue from '@/components/Admin/Component/TestimonialTable.vue';
export default {
    name: 'PadmawebTestimonialView',

    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
    components:{
        TestimonialTableVue
    }
};
</script>

<style lang="scss" scoped>

</style>