<template>
    <div class="container-y pb-10">
        <div class="text-sm md:text-base leading-loose md:leading-loose w-full dark:text-gray-200 font-normal dark:font-normal content mb-5 text-gray-700 md:w-4/5" v-html="data.detail"></div>
        <div class="md:hidden">
            <Share></Share>
        </div>
   </div>
</template>

<script>
import Share from '../../layouts/Share.vue'
export default {
    name: 'ClientContentdetailbunga',
    props:['data'],
    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
         href(link,i){
            if(i === '1'){
                this.$router.push(link)
            }else{
                window.location.href= link;
            }			
   		},
    },
    components:{
        Share
    }
};
</script>

<style lang="scss" scoped>

@import '../../assets/main.scss'

</style>