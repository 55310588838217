<template>
    <div class="container-y py-5">
        <div class="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
            <div class="card flex flex-col justify-between items-start overflow-hidden" v-for="item in items.data" :key="item">
                <div class="mb-3 h-52 md:h-56 lg:h-80 overflow-hidden">
                    <img :src="item.imagepath" :alt="item.title" class="object-cover object-center w-full h-full ">
                </div>
                <div class="flex flex-col w-full justify-between items-start mb-5">
                    <div class="text-xs md:text-sm text-gray-600 dark:text-gray-100 w-full">
                        {{moment(item.published_date).locale('id').format("DD MMMM YYYY")}}
                    </div>
                    <div class="w-full mb-5 text-lg font-bold text-primary hover:text-secondary duration-300 dark:text-gray-100">
                        <router-link :to="{name : 'DBlog', params: { blog: item.permalink } }">{{item.title}}</router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="px-5 flex justify-between items-center h-10 my-6" v-if="page > 1">
            <ul class="flex items-center h-full mr-5">
                <li v-if="currentPage > 1 " @click="prev" class="h-full flex justify-center items-center px-2 text-xs font-medium border cursor-pointer text-primary bg-gray-100 dark:bg-gray-900 border-gray-300 dark:border-gray-800 dark:text-gray-100">Prev</li>
                <li v-for="p in pages" :key="p" class="h-full flex justify-center items-center px-3 text-sm font-medium border cursor-pointer" :class="currentPage === p ? 'bg-primary text-gray-100 border-primary' : 'text-primary bg-gray-100 dark:bg-gray-900 dark:text-gray-100 border-gray-300 dark:border-gray-800'" @click="change(p)">{{p}}</li>
                <li v-if="currentPage < page"  @click="next" class="h-full px-2 text-xs flex justify-center items-center font-medium border cursor-pointer text-primary bg-gray-100 dark:bg-gray-900 border-gray-300 dark:text-gray-100 dark:border-gray-800">Next</li>
            </ul>
        </div>
    </div>
</template>

<script>
import moment from "moment";
export default {
    name: 'PadmawebContentSearch',
    props:['data'],
    data() {
        return {
            items:[],
            moment:moment,
            currentPage: 1,
        };
    },

    mounted() {
        this.getData();
    },

    methods: {
        change(e){
           this.currentPage = e
       },
        prev(){
           this.currentPage = this.currentPage - 1
       },
       next(){
           this.currentPage = this.currentPage + 1
       },
        getData(){
            this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
            this.axios.get('api/getblogs/kategori/'+this.data+'?page='+this.currentPage).then((response) => {
                this.items = response.data
            }).catch(error => {
               
              console.log(error)

            }).finally(() => {
                
            })
        },

        changetitle(e){
            this.$emit('gantititle',e);
        }
    },

    watch:{
        items:function(){
            this.changetitle(this.items.data[0].category)
        },
        currentPage:function(){          
            this.getData();
        }
    },
    computed:{
        page(){
            return Math.ceil(this.items.total / this.items.per_page)
        },
        pages(){
            const onSides = 1
            let pages = [];
            // Loop through
            for (let i = 1; i <= this.page; i++) {
                // Define offset
                let offset = (i == 1 || this.page) ? onSides + 1 : onSides;
                // If added
                if (i == 1 || (this.currentPage - offset <= i && this.currentPage + offset >= i) ||
                    i == this.currentPage || i == this.page) {
                    pages.push(i);
                } else if (i == this.currentPage - (offset + 1) || i == this.page + (offset + 1)) {
                    pages.push('...');
                }
            }
            return pages;            
        }
    },
};
</script>

<style lang="scss" scoped>

</style>