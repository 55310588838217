<template>
    <div>
        <FaqTableVue></FaqTableVue>
    </div>
</template>

<script>
import FaqTableVue from '@/components/Admin/Component/FaqTable.vue';
export default {
    name: 'PadmawebFaqView',

    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
    components:{
        FaqTableVue
    }
};
</script>

<style lang="scss" scoped>

</style>