<template>
  <div class="container-y py-8 lg:py-12 bg-white overflow-hidden dark:bg-gray-800" v-if="items.length">
    <div class="testi-head">
        <div class="header font-black text-3xl lg:text-4xl text-gray-700 dark:text-gray-100 mb-5 lg:mb-14">Apa kata Mereka ?</div>
    </div>
    <Carousel :autoplay="5000" :settings="settings" :breakpoints="breakpoints" :wrap-around="true" loop>
        <Slide v-for="(item,i) in items" :key="i">
            <div class="carousel__item">
                <div class="testimoni h-96 drop-shadow p-3 self-start justify-self-start">
                    <div class="t-heading flex flex-row justify-start items-center h-24">
                        <div class="image h-20 w-20 rounded-full overflow-hidden mr-5">
                            <img :src="item.imagepath" class="h-full w-full object-cover object-center" alt="Testimonial Bank Padma">
                        </div>
                        <div class="profil text-left">
                            <div class="name text-lg font-bold text-gray-700 dark:text-gray-100">
                                {{item.name}}
                            </div>
                            <div class="job text-sm font-light leading-tight text-secondary dark:text-gray-100">   
                                {{item.status}}
                            </div>
                        </div>
                    </div>
                    <div class="t-content text-justify mt-3 text-sm text-gray-700 dark:text-gray-100 leading-loose">
                        {{item.testimonial}}
                    </div>                    
                </div>
            </div>
        </Slide>
    </Carousel>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { Carousel, Slide } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent({
  name: 'ClientBlog',
 data(){
     return{
          items: [],
            settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
            breakpoints: {
                // 700px and up
                768: {
                    itemsToShow: 2,
                    snapAlign: 'start',
                },
                // 1024 and up
                1024: {
                    itemsToShow: 3,
                    snapAlign: 'start',
                },
            },
     }
 },
  components: {
    Carousel,
    Slide
  },
  mounted() {
    this.getData()
  },
  methods:{
    getData(){
      this.axios.get('api/gettestimonials').then((response) => {
            this.items = response.data
        }).catch(error => {
            
          console.log(error)

        }).finally(() => {
            
        })
    }
  }
});
</script>

<style lang="scss">
@import "../../assets/main.scss";
.header{
    position: relative;
    &::after{
        content: '';
        bottom: -10px;
        left: 0;
        height: 5px;
        width: 100px;
        background-color: $secondary;
        position: absolute;
    }
}
.carousel__item {
  width: 100%;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__slide {
  padding: 10px;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid white;
  background-color: $primary !important;
}
</style>