<template>
    <div>
        <SubmissionComponentVue></SubmissionComponentVue>
    </div>
</template>

<script>
import SubmissionComponentVue from '@/components/Admin/Component/submissionComponent/SubmissionComponent.vue';
export default {
    name: 'PadmawebSubmissionView',

    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
    components:{
        SubmissionComponentVue
    }
};
</script>

<style lang="scss" scoped>

</style>