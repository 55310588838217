<template>
    <div class="tag-input">
        <div class="flex flex-wrap">
            <div v-for="(tag, index) in tags" :key="index" class="tag-input__tag">
                <span @click='remove(index)'>x</span>
                {{ tag }}
            </div>
        </div>
        <div class="flex">
            <input type="text" class="bg-gray-100 w-full border-[1px] border-gray-400 rounded-sm mt-2 dark:border-gray-800 dark:text-gray-100 focus:outline-none py-2 px-2 text-sm font-normal text-gray-700" v-on:keyup.enter="add" v-on:keyup.188="add" label="Keyword">
        </div>
    </div>
</template>

<script>
export default {
    name: 'PadmawebTaginput',
    props: [
        'tags'
    ],
    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        add(event) {
                event.preventDefault()
                var val = event.target.value.toLowerCase();
                    this.$emit('addTags', val)
                    event.target.value = ''
            },
            remove(index) {
                this.$emit('removeTags', index)
            }
    },
};
</script>

<style lang="scss" scoped>
.tag-input {
        width: 100%;
        font-size: 0.9em;
        min-height: 50px;
        box-sizing: border-box;
    }

    .tag-input__tag {
        height: 30px;
        float: left;
        margin-right: 10px;
        background-color: #eee;
        margin-top: 10px;
        line-height: 30px;
        padding: 0 5px;
        border-radius: 5px;
    }

    .tag-input__tag > span {
        cursor: pointer;
        opacity: 0.75;
    }

    .tag-input__text {
        border: none;
        outline: none;
        font-size: 0.9em;
        line-height: 50px;
        background: none;
    }
</style>