<template>
    <div>
        <tabNavVue :tabs="tabs" :selected="selected" @selected="setSelected">
            <tabVue :isSelected="selected === '1'">
               <SliderTableVue></SliderTableVue>
            </tabVue>
            <tabVue :isSelected="selected === '2'">
               <BannerTableVue></BannerTableVue>
            </tabVue>
        </tabNavVue>
    </div>
</template>

<script>
import tabNavVue from './tabNav.vue';
import tabVue from './tab.vue';
import SliderTableVue from './SliderTable.vue';
import BannerTableVue from './BannerTable.vue';

export default {
    name: 'PadmawebHeroComponent',

    data() {
        return {
            selected:'1',

            tabs:[
                {
                    name: 'Slider',
                    initial: '1',
                    icon: 'piggy-bank-outline'
                },
                 {
                    name: 'Banner',
                    initial: '2',
                    icon: 'chart-line'
                }
            ],
        };
    },

    mounted() {
        
    },

    methods: {
        setSelected(tab){
            this.selected = tab;
        },
    },
    components:{
        tabNavVue,
        tabVue,
        SliderTableVue,
        BannerTableVue
    }
};
</script>

<style lang="scss" scoped>

</style>