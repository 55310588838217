<template>
  <section class="report shadow-md border border-gray-200 dark:bg-gray-900 dark:border-gray-900 rounded-lg px-6 md:px-8 py-6 md:py-8">
      <div class="r-container" v-if="reports.length">
        <div class="r-card flex justify-start mb-6 items-center" v-for="r in reports" :key="r.id">
          <div class="report-icon hidden mr-4 lg:block">
             <mdicon name="file" size="50" class="text-primary dark:text-secondary" />
          </div>
          <div class="report-content w-full mr-4">
              <div class="report-date text-sm md:text-base text-gray-500 dark:text-gray-200 t-laporan">
                {{moment(r.date).locale('id').format('LL')}}
              </div>
              <div class="report-name text-base md:text-lg font-semibold dark:font-medium text-gray-700 dark:text-gray-200 j-laporan">
                {{r.report}}
              </div>
          </div>
          <button class="bg-primary dark:bg-secondary text-white text-sm font-medium px-4 rounded-sm hover:bg-secondary duration-300 py-2">
            <a :href="r.filepath" target="_blank">Unduh</a>
          </button>          
        </div>
      </div>
  </section>
</template>

<script>
import moment from "moment";
import store from '@/store';
export default {

  name: 'PadmaPublikasi',

  data () {
    return {
      reports:[],
      type: 'Publikasi',
      moment : moment,
    }
  },
  mounted(){
    this.getData()
  },
  methods:{
     getData(){
              store.commit('ChangeLoader',true)
             this.axios.get('api/getreport/'+this.type).then((response) => {
              this.reports = response.data     
               
            }).catch(error => {
                
            console.log(error)

            }).finally(() => {
              store.commit('ChangeLoader',false)
                
            })
      }
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/main.scss";
</style>