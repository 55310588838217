<template>
    <div v-if="shares.length">
        <div class="text-xl font-semibold text-gray-700 dark:text-gray-200 mb-3">
            Bagikan
        </div>
        <div class="flex flex-row justify-start items-center">
           <div class="h-full mr-1 lg:mr-2 hover:-translate-y-1 duration-300" v-for="s in shares" :key="s">
                <ShareNetwork
                    :network="s.platform"
                    :url="s.sharelink"
                    title=" "
                    description=" "
                    quote=" "
                    hashtags=" "
                    >
                    <mdicon :name="s.icon" size="35" :style="{'color' : s.color}" class="dark:text-white" />
                </ShareNetwork>
            </div>               
        </div>
    </div>
</template>

<script>
export default {
    name: 'ClientShare',

    data() {
        return {
             shares:[]
        };
    },

    mounted() {
        this.getData()
    },

    methods: {
        getData(){
            this.axios.get('api/getshare').then((response) => {
               let obj = {}

               for(let i = 0 ; i < response.data.length ; i ++){
                    obj = {
                        platform : response.data[i].platform,
                        icon : response.data[i].icon,
                        color : response.data[i].color,
                        share : response.data[i].share,
                        display : response.data[i].display,
                        chat : response.data[i].chat,
                        chaturl : response.data[i].chaturl,
                        url : response.data[i].url,
                        sharelink :document.location.href,
                    }

                    this.shares.push(obj)
               }

            }).catch(error => {
                
            console.log(error)

            }).finally(() => {
                
            })
        }
    },
};
</script>

<style lang="scss" scoped>

</style>