<template>
    <div>
        <tabNavVue :tabs="tabs" :selected="selected" @selected="setSelected">
            <tabVue :isSelected="selected === '1'">
               <RoleTableVue></RoleTableVue>
            </tabVue>
            <tabVue :isSelected="selected === '2'">
               <PermissionTableVue></PermissionTableVue>
            </tabVue>
            <tabVue :isSelected="selected === '3'">
               <ModuleTableVue></ModuleTableVue>
            </tabVue>
        </tabNavVue>
    </div>
</template>

<script>
import tabNavVue from './tabNav.vue';
import tabVue from './tab.vue';
import ModuleTableVue from './ModuleTable.vue';
import PermissionTableVue from './PermissionTable.vue';
import RoleTableVue from './RoleTable.vue';
export default {
    name: 'PadmawebAccesstComponent',

    data() {
        return {
            selected:'1',

            tabs:[
                {
                    name: 'Roles',
                    initial: '1',
                    icon: 'lock'
                },
                 {
                    name: 'Permissions',
                    initial: '2',
                    icon: 'account-key'
                },
                {
                    name: 'Modules',
                    initial: '3',
                    icon: 'book-open'
                }
            ],
        };
    },

    mounted() {
        
    },

    methods: {
        setSelected(tab){
            this.selected = tab;
        },
    },
    components:{
        tabNavVue,
        tabVue,
        ModuleTableVue,
        PermissionTableVue,
        RoleTableVue
    }
};
</script>

<style lang="scss" scoped>

</style>