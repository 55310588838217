<template>
    <div class="md:mb-8 border-b-2 md:border-b-0 border-secondary">
        <div class="text-lg md:text-2xl font-bold text-primary dark:text-gray-200 md:mb-1">{{item.name}}</div>
        <div class="text-base md:text-xl font-semibold uppercase text-secondary dark:text-gray-200 mb-2 md:mb-3">{{item.position}}</div>
        <div class="overflow-hidden h-96 w-full md:w-80 rounded-sm drop-shadow-md mb-2 md:mb-3">
            <img :src="item.imagepath" :alt="item.name" class="w-full h-full object-cover object-center">
        </div>
        <div v-html="item.detail" class="text-sm md:text-base leading-loose md:leading-loose w-full dark:text-gray-200 font-normal dark:font-normal content mb-5 text-gray-700 mt-5"></div>
        <div class="block md:hidden my-6">
            <Share></Share>
        </div>
    </div>
</template>

<script>
import Share from '../../layouts/Share.vue'
export default {
    name: 'ClientContentDetailManajemen',
    props:['item'],

    data() {
        return {
            
        };
    },

    mounted() {
    },

    methods: {
        
    },
    components:{
        Share
    }
};
</script>

<style lang="scss" scoped>
@import "../../assets/main.scss";

</style>