<template>
    <div class="container-y pb-10">
         <div class="grid gap-5 grid-cols-1 lg:grid-cols-[2fr_1fr]">
            <div class="left">
                <div class="text-sm md:text-base leading-loose md:leading-loose w-full dark:text-gray-200 font-normal dark:font-normal content mb-5 text-gray-700" v-html="data.detail"></div>
                <button @click="href(data.link,data.internallink)" v-if="data.showlink == '1'" class="bg-secondary text-white rounded p-3 text-xs md:text-sm font-semibold drop-shadow-sm md:mt-5 active:translate-y-0.5 duration-200 mb-5">{{data.buttontext}}</button>
                <div class="md:hidden">
                    <Share></Share>
                </div>
            </div>
            <div class="right">
                <Calc></Calc>
            </div>
        </div>
   </div>
</template>

<script>
import Share from '../../layouts/Share.vue'
import Calc from './Calc.vue'
export default {
    name: 'ClientContentdetailkredit',
    props:['data'],
    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
         href(link,i){
            
            if(i == true){
                
                this.$router.push(link)
            }else{
                window.location.href= link;
            }				
   		},
    },
    components:{
        Share,
        Calc
    }
};
</script>

<style lang="scss" scoped>

@import '../../assets/main.scss'

</style>