<template>
    <div>
        <blogComponentVue></blogComponentVue>
    </div>
</template>

<script>
import blogComponentVue from '@/components/Admin/Component/BlogComponent/blogComponent.vue';

export default {
    name: 'PadmawebBlogView',

    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
    components:{
        blogComponentVue
    }
};
</script>

<style lang="scss" scoped>

</style>