<template>
    <div class="bg-white dark:bg-gray-800">       
        <Hero :hero="params"></Hero>
        <Breadcrumbs :breadcrumbs="crumbs" :page="pagename"></Breadcrumbs>
        <Content></Content>
    </div>
</template>

<script>
import store from '@/store';
import Breadcrumbs from '../layouts/breadcrumb.vue'
import Hero from '@/layouts/Hero.vue'
import Content from '../components/Other/ContentKritik.vue'
export default {
    name: 'ClientKritikSaran',

    data() {
        return {
            params:{},
            pagename: 'Kritik & Saran',
            crumbs: [],
            pageparams: 'Kritik & Saran'
        };
    },

    created() {
       this.getparams()
    },

    methods: {
         getparams(){
            store.commit('ChangeLoader',true)
             this.axios.get('api/getbanner/'+this.pageparams).then((response) => {
               this.params = {
                    id: response.data.id,
                    title: response.data.type,
                    img: response.data.imagepath,
                    description : response.data.description,
                    showtitle : response.data.showtitle,
                    showdesc : response.data.showdesc,
                }

                document.title = response.data.type + ' | Bank Padma'
                document.querySelector('head meta[name="description"]').setAttribute('content', response.data.description);
                document.querySelector('head meta[name="og:description"]').setAttribute('content', response.data.description);
                document.querySelector('head meta[name="og:title"]').setAttribute('content', response.data.type + ' | Bank Padma');
                document.querySelector('head meta[name="keywords"]').setAttribute('content', response.data.keyword);
                document.querySelector('head meta[name="og:url"]').setAttribute('content', window.location.href);
                document.querySelector('head meta[name="og:image"]').setAttribute('content', response.data.imagepath);
            }).catch(error => {
                
            console.log(error)

            }).finally(() => {
                store.commit('ChangeLoader',false)
            })
            
            this.crumbs = [{key:"Kritik & Saran", link:"/kritik-dan-saran"}]
        }
    },
    components:{
        Hero,
        Breadcrumbs,
        Content
    }
};
</script>

<style lang="scss" scoped>

</style>