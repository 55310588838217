<template>
    <button  class="rounded px-5 text-sm font-medium py-2 cursor-pointer duration-300 shadow">
        <slot></slot>
    </button>
</template>

<script>
export default {
    name: 'PadmawebButtonLarge',

    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>

<style lang="scss" scoped>

</style>