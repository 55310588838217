<template>
<div class="container-y pb-5">
    <div>
        <div class="grid grid-cols-1 gap-2 mb-5" v-if="data != null">
            <div>
                <div class="text-xl font-bold text-primary">{{ data.title }}</div>
                <div class="text-sm mt-5 md:text-base leading-loose md:leading-loose w-full dark:text-gray-200 font-normal dark:font-normal content mb-5 text-gray-700" v-html="data.detail"></div>

            </div>
            <div class="w-3/4">
                <img :src="data.image_path" class="w-full" alt="pengaduan nasabag" srcset="">
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'ClientContentKritik',

    data() {
        return {
            isloading: false,
            data: null
        };
    },

    mounted() {
        this.getData();
    },

    methods: {
        getData() {
            this.isloading = true
            this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
            this.axios.get('api/pengaduan-nasabah').then((response) => {
                this.data = response.data
            }).catch(error => {

                console.log(error)

            }).finally(() => {
                this.isloading = false
            })
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../../assets/main.scss"
</style>
