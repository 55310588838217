<template>
    <div class="bg-white dark:bg-gray-800">       
        <Breadcrumbs :breadcrumbs="crumbs" :page="pagename"></Breadcrumbs>
        <Highlight></Highlight>
        <NewBlog v-if="!loading"></NewBlog>
        <Category v-if="!loading"></Category>
        <div v-if="!loading" class="container-y pb-4 md:pb-8">
             <Share class="block"></Share>
        </div>       
    </div>
</template>

<script>
import Breadcrumbs from '../layouts/breadcrumb.vue'
import Highlight from '../components/Blog/highlight.vue'
import NewBlog from '../components/Blog/newblog.vue'
import Category from '../components/Blog/category.vue'
import Share from '../layouts/Share.vue'
import store from '@/store'
export default {
    name: 'ClientBlogview',

    data() {
        return {
            pagename: 'Blog',
            crumbs: [],
            pageparams:'General'
        };
    },

    mounted() {
       this.getparams()
    },

    methods: {

        getparams(){
             this.axios.get('api/getbanner/'+this.pageparams).then((response) => {
                document.title = 'Blog | Bank Padma'
                document.querySelector('head meta[name="description"]').setAttribute('content', response.data.description);
                document.querySelector('head meta[name="og:description"]').setAttribute('content', response.data.description);
                document.querySelector('head meta[name="og:title"]').setAttribute('content', 'Blog | Bank Padma');
                document.querySelector('head meta[name="og:url"]').setAttribute('content', window.location.href);
                document.querySelector('head meta[name="keywords"]').setAttribute('content', response.data.keyword);
                document.querySelector('head meta[name="og:image"]').setAttribute('content', response.data.imagepath);
            }).catch(error => {
                
            console.log(error)

            }).finally(() => {
                
            })
            
            this.crumbs = [{key:"Blog", link:"/blog"}]
        }
    },
    components:{
        Breadcrumbs,
        Highlight,
        NewBlog,
        Category,
        Share
    },
    computed:{
        loading(){
            return store.state.preloader
        }
    }
};
</script>

<style lang="scss" scoped>

</style>