<template>
    <div>
        <div class="fixed w-full h-screen z-10 bg-b-overlay inset-0" @click="this.$emit('closeModal')">

        </div>
        <div class="z-20 absolute inset-0 w-[98%] h-[95%] bg-gray-100 dark:bg-gray-900 top-[50%] -translate-y-[50%] left-[50%] -translate-x-[50%] rounded-md shadow-md">
            <div class="relative h-[100%] w-full overflow-hidden flex flex-col justify-between">
                <div class="relative w-full">
                    <mdicon @click="this.$emit('closeModal')" name="close" size="30" class="cursor-pointer absolute right-5 top-5"/>
                </div>
                <div class="text-2xl h-20 flex items-center border-b-[1px] border-gray-300 px-5">
                    <slot name="formName"></slot>
                </div>
                <div class="relative h-[100%] overflow-y-auto w-full p-5">
                    <slot name="main"/>
                </div> 
                <div class="h-10 w-full border-t-[1px] border-gray-300 px-5 mt-5">

                </div> 
            </div>     
        </div>
    </div>
</template>

<script>
export default {
    name: 'PadmawebLargeFormBase',

    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>

<style lang="scss" scoped>

</style>