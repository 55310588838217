<template>
    <div v-if="!loading">
        <div class="w-full py-5 px-10 bg-gray-100 dark:bg-gray-900">
            <div class="text-lg font-bold text-gray-700 text-center mb-7 dark:text-gray-200">Kalkulator kredit</div>

        <form @submit.prevent="hitung">
                <div class="mb-6">
                    <label for="plafon" class="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-200">Plafon</label>
                    <VueNumberFormat
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:focus:outline-none dark:border-0"
                    v-model:value="calc.plafon"
                    :options="{ precision: 0, prefix: 'Rp. ', decimal: '', thousand: ',', acceptNegative: false, isInteger: false  }"
                    ></VueNumberFormat>
                </div>
                <div class="mb-6">
                    <label for="jk" class="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-200 ">Jangka Waktu (Bulan)</label>
                    <input type="number" v-model="calc.jw" class="border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:focus:outline-none dark:border-0" placeholder="Jangka Waktu Dalam Bulan" required>
                </div>
                <div class="mb-6">
                    <label for="metode" class="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-200 ">Jenis Bunga</label>
                    <select name="" v-model="calc.metode" id="" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:focus:outline-none dark:border-0">
                        <option value="">Pilih Jenis Bunga</option>
                        <option value="A">Anuitas</option>
                        <option value="F">Flat</option>
                        <option value="M">Menurun</option>
                    </select>
                    </div>
                <div class="mb-6">
                    <label for="bunga" class="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-200 ">Suku bunga</label>
                    <input type="number" step="0.01" v-model="calc.bunga" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:focus:outline-none dark:border-0" placeholder="Suku Bunga Pertahun" required>
                </div>
                <button type="submit" class="text-white bg-primary hover:bg-secondary duration-300 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Submit</button>
            </form>
        </div>

       <transition name="fademenu">
			<div class="overlay" v-if="modalOpen" @click="modalOpen = false">	
			</div>
		</transition>
            <transition name="slideT">
                <div class="angsuran-container drop-shadow-sm fixed w-5/6 md:w-3/5 mx-auto p-10 my-3 overflow-y-auto bg-white inset-0 rounded z-40" v-if="modalOpen">
                    <div class="a-heading">
                        <div class="text-xl text-gray-700 font-bold mb-5">Tabel Angsuran</div>
                        <div class="btn-close">
                            <div @click="modalOpen = false" class="bar"></div>
                        </div>
                    </div>
                    <div class="a-box">
                        <div>
                            <table class="table-auto w-full text-sm tabel-angsuran">
                            <thead class="font-bold text-left px-10 text-gray-700">
                                <tr class="border-collapse border-b border-gray-300">
                                    <th scope="col" class="py-3 px-10 md:px-0">Angsuran ke #</th>
                                    <th scope="col" class="py-3 px-10 md:px-0">Pokok</th>
                                    <th scope="col" class="py-3 px-10 md:px-0">Bunga</th>
                                    <th scope="col" class="py-3 px-10 md:px-0">Total</th>
                                    <th scope="col" class="py-3 px-10 md:px-0">Outstanding</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class=" border-collapse border-b border-gray-300">
                                <td class="px-10 md:px-0"></td>
                                <td class="px-10 md:px-0"></td>
                                <td class="px-10 md:px-0"></td>
                                <td class="px-10 md:px-0"></td>
                                <td class="px-10 md:px-0">{{pl}}</td>
                                </tr>
                                <tr v-for="(item,i) in result" :key="i" class=" border-collapse border-b p-14 border-gray-300">
                                <td class="px-10 md:px-0">{{ item.no }}</td>
                                <td class="px-10 md:px-0">{{ item.pokok }}</td>
                                <td class="px-10 md:px-0">{{ item.bunga }}</td>
                                <td class="px-10 md:px-0">{{ item.full }}</td>
                                <td class="px-10 md:px-0">{{ item.outstanding }}</td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="a-close">
                        <div class="close-btn" @click="modalOpen = false">
                            close
                        </div>
                    </div>
                </div>
            </transition>
    </div>
</template>

<script>
import store from '@/store';
export default {
    name: 'ClientCalc',

    data() {
        return {
            modalOpen:false,
            pl: null,
            calc:{
                plafon:"",
                jw:"",
                bunga:"",
                metode:"",
            },
            result:[],
        };
    },

    mounted() {
        
    },

   methods: {
        hitung(){
            
            this.result = [];
            let nf = new Intl.NumberFormat('en-US');
            this.pl = nf.format(this.calc.plafon)
            if(this.calc.plafon == "" || this.calc.jw == "" || this.calc.bunga == "" || this.calc.metode  == "" ){
  			alert("form tidak diisi !");
           
  		} else {
              let pl = this.calc.plafon, waktu = this.calc.jw, bg = this.calc.bunga , mt = this.calc.metode;
  		      let no = 1;
              if(mt == "M"){
                 let pokok = pl/waktu;
                for(pl ; Math.trunc(pl) > 0 ; pl -= pokok ){
                    let nBunga = pl*bg/12/100;
                    let total = pokok + nBunga;
                    let os = pl - pokok;
                    let obj = {
                        no: no,
                        pokok: nf.format(pokok.toFixed(0)),
                        bunga: nf.format(nBunga.toFixed(0)),
                        full: nf.format(total.toFixed(0)),
                        outstanding: nf.format(os.toFixed(0))
                    }
                    no++;
                    this.result.push(obj)
                }
                this.modalOpen = true;
              }else if(mt == "A"){
                  this.result = [];
                    let os = pl;
                    let skBunga = bg/100;
                    let pembagi = 1-1/(Math.pow(1+skBunga/12,waktu));
                    let base = pl*(skBunga/12);
                    let total = (base/pembagi);
                    let nBunga = (os*skBunga/12);
                    let pokok = (total - nBunga);

                    for(pl ; Math.trunc(os) > 0 ; pl -= pokok ){
                    nBunga = (os*skBunga/12);
                    pokok = (total - nBunga);
                    os = (os - pokok);
                    if(os < 0 ){
                        os = 0
                    }
                    let obj = {
                        no: no,
                        pokok: nf.format(pokok.toFixed(0)),
                        bunga: nf.format(nBunga.toFixed(0)),
                        full: nf.format(total.toFixed(0)),
                        outstanding: nf.format(os.toFixed(0))
                    }
                    no++;
                    this.result.push(obj)
                }
                this.modalOpen = true;
                  
              }else if(mt == "F"){
  			this.result = [];
  			let pokok = pl/waktu;
  			let paramflet = pl;
  			for(pl ; Math.trunc(pl) > 0 ; pl -= pokok ){
  				let nBunga = paramflet*bg/100/12;
  				let total = pokok + nBunga;
  				let os = pl - pokok;
  				let obj = {
  					no: no,
  					pokok: nf.format(pokok.toFixed(0)),
  					bunga: nf.format(nBunga.toFixed(0)),
  					full: nf.format(total.toFixed(0)),
  					outstanding: nf.format(os.toFixed(0))
  				}
  				no++;
  				this.result.push(obj)
  			}
  			this.modalOpen = true;
  		}else{
  			console.log("Error");
  		}
          }
        }
    },

    components:{
        
    },

    computed:{
        loading(){
            return store.state.preloader
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../../assets/main.scss";
.fademenu-enter-active,
.fademenu-leave-active{
	transition: all .5s ease-in-out;
}

.fademenu-enter-from,
.fademenu-leave-to{
	opacity: 0;
	transform: translateY(10px);
}

.slideT-enter-active{
	animation: bounce-in .3s;
}
.slideT-leave-active{
	animation: bounce-in .3s reverse;
}

 .a-close{
    position: relative;
    width: 100%;
    margin: 30px 0;
    .close-btn{
        display: inline-block;
        background-color: $secondary;
        transition: all .3s ease-in-out;
        padding: 10px 20px;
        border-radius: 2px;
        color: white;
        cursor: pointer;
        position: absolute ;
        left: 0;       
        bottom: -30px;
        font-size: 16px;
        
        &:hover{
            background-color: darken($secondary,5%);
        }
        
    }
}

@keyframes bounce-in {
  0% {
    top: -600px;
  }
  100% {
    top: 20px;
  }
}

.overlay{
    height:100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    box-sizing: border-box;
    background-color: rgba(0,0,0,0.8);
    z-index: 39;
    overflow: hidden;		
}

.tabel-angsuran{
    tbody{
        tr{ 
            
            td{
                padding: .75rem 0 !important;
            }
        }
    }
}

@media screen and (min-width: 1024px){
     .a-close{
        .close-btn{
            left: unset;
            right: 0;      
            
        }
    }
}

</style>