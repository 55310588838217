<template>
    <div class="container-y">
        <div class="w-full h-64 md:h-96 lg:h-screen" v-if="loading"></div>
        <div class="relative h-container overflow-hidden" v-if="items.length">
            <div class="highlight w-full grid gap-0.5 grid-cols-1 md:grid-cols-3 lg:grid-cols-4">
                <div class="highlight-item w-full" v-for="item in items" :key="item">
                    <div class="overflow-hidden h-card cursor-pointer relative">
                        <div class="w-full h-img relative h-full">
                            <img :src="item.imagepath" :alt="item.title" class="object-cover w-full h-full object-center">
                        </div>
                        <div class="headline absolute w-full bg-b-overlay-half h-full z-10 inset-0 p-7 flex flex-col justify-end">
                            <div class="text-sm text-white mb-3 font-semibold">{{moment(item.published_date).locale('id').format("DD MMMM YYYY")}}</div>
                            <div class="tag text-xs bg-white w-24 p-1 text-center mb-3">
                                <router-link :to="'/blog/kategori/'+item.blog_category.permalink">{{item.blog_category.category}}</router-link>
                            </div>
                            <div class="headline-item text-white font-bold hover:text-secondary duration-300 text-base md:text-sm leading-normal">
                                <router-link :to="{name : 'DBlog', params: { blog: item.permalink } }">{{item.title}}</router-link>                            
                            </div>                        
                        </div>
                    </div>
                </div>
            </div>
            <div class="absolute h-item text-sm inset-0 h-10 w-24 flex justify-center items-center uppercase z-20 bg-w-overlay">
                Highlight
            </div>
        </div>
    </div>
</template>

<script>
import store from "@/store";
import moment from "moment";
export default {
    name: 'ClientHighlight',

    data() {
        return {
            items:[],
            moment : moment
        };
    },

    mounted() {
        this.getData()
    },

    methods: {
         getData(){
           store.commit('ChangeLoader',true)
           this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
            this.axios.get('api/getbloghighlight').then((response) => {
                this.items = response.data
            }).catch(error => {
               
              console.log(error)

            }).finally(() => {
                store.commit('ChangeLoader',false)
            })
        },
    },
    computed:{
        loading(){
            return store.state.preloader
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../assets/main.scss';

.highlight{
    min-height: 70vh;
    .highlight-item{
        height: 100%;
        .h-card{
            height: 100%;
        }
    }
}

.h-container:hover{
    .h-item{
         transform:  translateY(0px);
    }
}

.h-container{
    .h-item{
        transition:  all .4s ease-in-out !important;
        transform:  translateY(-100px);
    }
}

@media screen and (min-width:768px) {
    .highlight-item:nth-child(1){
        grid-column: 1 / 3;
        grid-row: 1 / 3;
        .h-card{
            .headline{
                .headline-item{
                    font-size:1.3em;
                    line-height: 1.5em !important;
                }
            }
        }
    }
    .highlight{
        min-height: 60vh;
        .highlight-item{
            height: 100%;
            .h-card{
                height: 100%;
            }
        }
    }
}

@media screen and (min-width:1024px) {
    .highlight-item:nth-child(1){
        grid-column: 1 / 3;
        grid-row: 1 / 3;
        .h-card{
            .headline{
                .headline-item{
                    font-size:1.3em !important;
                    font-weight: 600;
                }
            }
        }
    }
    .highlight{
        min-height: 70vh;
        .highlight-item{
            height: 100%;
            .h-card{
                height: 100%;
            }
        }
    }
}
</style>