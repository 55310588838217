<template>
    <div class="container-y py-8 lg:pt-12 dark:bg-gray-800" v-if="product.length">
        <div class="text-2xl lg:text-4xl font-semibold text-center text-primary dark:text-gray-200 mb-3 lg:mb-5" v-if="company.length">{{company[0].prodhead}}</div>
        <p class="text-sm md:text-md text-center font-reguler text-gray-700 dark:text-gray-200" v-if="company.length">{{company[0].prodsub}}</p>
        <div class="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 grid-rows-auto mt-8 md:mt-10 lg:mt-16" v-if="product.length">
            <router-link v-for="(p,i) in product" :key="i" :to="p.url" class="px-7 py-10 lg:py-12 bg-primary dark:bg-gray-900 rounded text-gray-200 w-full h-72 md:h-56 flex flex-col md:flex-row justify-center items-center md:justify-start md:items-start">
                <div class="w-2/4 lg:self-start flex justify-center items-center overflow-hidden">
                    <img :src="p.imagepath" :alt="p.title">
                </div>
                <div class="flex w-full flex-col">
                    <div class="text-center md:text-left text-2xl mb-2 font-bold">{{p.title}}</div>
                    <div class="text-center md:text-left text-sm ">{{p.description}}</div>
                </div>
            </router-link>
        </div>
    </div>
</template>

<script>
import store from '@/store';
export default {
    name: 'ClientProduct',

    data() {
        return {
            product: [],
        };
    },

    mounted() {
        this.getData()  
    },

    methods: {
         getData(){
            this.axios.get('api/getproduknav').then((response) => {
                this.product = response.data
            }).catch(error => {
                
            console.log(error)

            }).finally(() => {
                
            })
        }
    },
     computed:{
         company(){
            return store.state.company
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../../assets/main.scss";

</style>