<template>
    <div>
        <ReportTableVue></ReportTableVue>
    </div>
</template>

<script>
import ReportTableVue from '@/components/Admin/Component/ReportTable.vue';
export default {
    name: 'PadmawebReportView',

    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
    components:{
        ReportTableVue
    }
};
</script>

<style lang="scss" scoped>

</style>