<template>
    <div>
        <tabNavVue :tabs="tabs" :selected="selected" @selected="setSelected">
            <tabVue :isSelected="selected === '1'">
              <SavingSubmissionVue></SavingSubmissionVue>
            </tabVue>
            <tabVue :isSelected="selected === '2'">
              <DepositSubmissionVue></DepositSubmissionVue>
            </tabVue>
            <tabVue :isSelected="selected === '3'">
              <LoanSubmissionVue></LoanSubmissionVue>
            </tabVue>
        </tabNavVue>
    </div>
</template>

<script>
import tabNavVue from './tabNav.vue';
import tabVue from './tab.vue';
import SavingSubmissionVue from './SavingSubmission.vue';
import DepositSubmissionVue from './DepositSubmission.vue';
import LoanSubmissionVue from './LoanSubmission.vue';

export default {
    name: 'PadmawebSubmissionComponent',

    data() {
        return {
            selected:'1',

            tabs:[
                {
                    name: 'Savings',
                    initial: '1',
                    icon: 'piggy-bank-outline'
                },
                 {
                    name: 'Deposits',
                    initial: '2',
                    icon: 'chart-line'
                },
                {
                    name: 'Loans',
                    initial: '3',
                    icon: 'handshake-outline'
                }
            ],
        };
    },

    mounted() {
        
    },

    methods: {
        setSelected(tab){
            this.selected = tab;
        },
    },
    components:{
        tabNavVue,
        tabVue,
        SavingSubmissionVue,
        DepositSubmissionVue,
        LoanSubmissionVue
        

    }
};
</script>

<style lang="scss" scoped>

</style>