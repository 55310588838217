<template>
    <div class="container-y flex flex-col justify-center items-center h-screen bg-gray-100">
        <div class="font-bold text-primary text-9xl mb-16">
            404
        </div>
        <div class="text-2xl font-semibold text-gray-700 mb-16">Mohon Maaf Halaman Tidak Tersedia</div>
        <div>
            <router-link to="/">
                <img :src="LogoPadma" alt="Bank Padma" class="w-72">
            </router-link>
        </div>
    </div>
</template>

<script>
import logo from '@/assets/logo-padma.png'
export default {
    name: 'PadmawebNotFound',

    data() {
        return {
            LogoPadma: logo
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>

<style lang="scss" scoped>

</style>