<template>
<div>
    <div class="bg-gray-100 dark:bg-gray-900 w-full shadow rounded-md p-4 h-[520px] overflow-hidden">
        <div class="flex">
            <button class="text-sm font-medium focus:outline-none active:translate-y-[2px] duration-200 bg-primary inline-block relative h-8 rounded shadow w-16 text-center text-gray-100 mb-2 mr-2 float" @click="addModal()" v-if="isCreatePermitted">
                Add
            </button>
            <button class="text-sm font-medium focus:outline-none active:translate-y-[2px] duration-200 bg-secondary inline-block relative h-8 rounded shadow w-16 text-center text-gray-100 mb-2 mr-2 float" @click="editModal()" v-if="isUpdatePermitted">
                Edit
            </button>
            <button class="text-sm font-medium focus:outline-none active:translate-y-[2px] duration-200 bg-danger inline-block relative h-8 rounded shadow w-16 text-center text-gray-100 mb-2 mr-2 float" @click="delete_modal()" v-if="isDeletePermitted">
                Delete
            </button>
        </div>
        <div v-if="items  != null">
            <div class="text-xl font-semibold mb-3">
                Title
            </div>
            <div class="content" v-html="items.title"></div>
        </div>

        <div class="mb-10"></div>

        <div v-if="items != null">

            <div class="text-sm md:text-base leading-loose md:leading-loose w-full font-normal content mb-2 text-gray-7003">
                Detail
            </div>
            <div class="h-[150px] overflow-y-auto overflow-x-hidden">
                <div class="content" v-html="items.detail">

                </div>
            </div>
        </div>
        <div class="mb-5"></div>
        <div v-if="items != null">
            <div class="text-xl font-semibold mb-3">
                File
            </div>
            <a v-if="items.image != null" target="_blank" :href="items.image_path">
                <button>View File</button>
            </a>
        </div>
    </div>
    <LargeFormBaseVue v-if="modal" @closeModal="modal = !modal">
        <template v-slot:formName>
            {{ title }}
        </template>
        <template v-slot:main>
            <div class="grid gap-5 grid-cols-1">
                <div class="left">
                    <div class="form-group mb-2">
                        <label for="phone" class="text-sm font-bold text-gray-500">Title</label>
                        <input type="text" class="w-full border-b border-gray-400 dark:border-gray-800 dark:text-gray-100 bg-transparent focus:outline-none py-2 px-2 text-sm font-normal text-gray-700" v-model="form.title" />
                    </div>
                    <div class="mb-5"></div>
                    <div class="mb-5">
                        <div class="text-sm font-bold text-gray-500 mb-2">Detail</div>
                        <quill-editor v-model:value="form.detail" :options="state.editorOption" />
                    </div>
                    <div class="mb-5">
                        <button class="border-gray-400 border-[1px] items-center flex text-gray-500 px-3 py-2 rounded-sm text-xs font-medium" onclick="document.getElementById('fileImage').click()">
                            <mdicon name="paperclip" size="15" class="text-gray-500 " /> <span>{{ form.image == null ? 'Upload Image' : 'Image Uploaded' }}</span></button>
                        <input type='file' id="fileImage" accept="image/png, image/jpeg, image/jpg" style="display:none" @change="getFile">
                    </div>
                    <div class="flex mt-10">
                        <LButton class="text-white bg-secondary mr-2" :class="isloading ? 'pointer-events-none' : 'cursor-pointer'" :disabled="isloading" @click="save()">
                            {{ isloading ? 'Loading...' : 'Save' }}
                        </LButton>
                        <LButton class="bg-gray-300 text-gray-800">
                            Cancel
                        </LButton>
                    </div>
                </div>
            </div>
        </template>
    </LargeFormBaseVue>
    <Transition name="fade">
        <Confirm v-if="deletemodal" @closeconfirm="deletemodal = false">
            <template v-slot:header>
                Confirm
            </template>
            <template v-slot:main>
                Are You Sure You Want To Delete This Data ?
            </template>
            <template v-slot:footer>
                <div class="flex justify-end items-center">
                    <LButton class="bg-gray-300 text-gray-800 mr-2" @click="deletemodal = false">
                        Cancel
                    </LButton>
                    <LButton class="text-white bg-secondary" :class=" isloading ? 'pointer-events-none' : 'cursor-pointer'" :disabled="isloading" @click="deleteData">
                        {{ isloading ? 'Loading...' : 'Confirm' }}
                    </LButton>
                </div>
            </template>
        </Confirm>
    </Transition>
</div>
</template>

<script>
import { reactive } from 'vue'; // Tambahkan ini
import LargeFormBaseVue from '@/layouts/LargeFormBase.vue';
import { quillEditor } from 'vue3-quill';
import LButton from '@/layouts/button.vue';
import Confirm from '@/layouts/confirm.vue'

export default {
    name: 'PadmawebPengaduanNasabahParams',

    data() {
        return {
            items: null,
            isloading: false,
            modal: false,
            deletemodal: false,
            deleteID: null,
            editIndex: -1,
            form: {
                id: '',
                title: '',
                detail: '',
                image: null,
            },
            defaultForm: {
                id: '',
                title: '',
                detail: '',
                image: null,
            }
        };
    },
    mounted() {
        this.getData()
    },
    setup() {
        const state = reactive({
            dynamicComponent: null,
            content: '',
            _content: '',
            editorOption: {
                placeholder: 'Detail Here',
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'],
                        ['blockquote', 'code-block'],
                        [{ header: 1 }, { header: 2 }],
                        [{ list: 'ordered' }, { list: 'bullet' }],
                        [{ script: 'sub' }, { script: 'super' }],
                        [{ indent: '-1' }, { indent: '+1' }],
                        [{ direction: 'rtl' }],
                        [{ size: ['small', false, 'large', 'huge'] }],
                        [{ header: [1, 2, 3, 4, 5, 6, false] }],
                        [{ color: [] }, { background: [] }],
                        [{ font: [] }],
                        [{ align: [] }],
                        ['clean'],
                        ['link', 'image', 'video']
                    ]
                }
            },
            disabled: false
        });

        const onEditorBlur = (quill) => {
            console.log('editor blur!', quill);
        };
        const onEditorFocus = (quill) => {
            console.log('editor focus!', quill);
        };
        const onEditorReady = (quill) => {
            console.log('editor ready!', quill);
        };
        const onEditorChange = ({ quill, html, text }) => {
            console.log('editor change!', quill, html, text);
            state._content = html;
        };

        return { state, onEditorBlur, onEditorFocus, onEditorReady, onEditorChange };
    },

    methods: {
        getFile(event) {
            this.form.image = event.target.files[0];
        },
        getData() {
            this.isLoading = true;

            this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
            this.axios.get('api/pengaduan-nasabah-params').then((response) => {
                this.items = response.data
            }).catch(error => {
                console.log(error)
            }).finally(() => {
                this.isLoading = false;
            })
        },
        addModal() {
            this.editIndex = -1;
            this.modal = !this.modal;
        },
        clearform: function () {
            this.modal = false
            this.$nextTick(() => {
                this.form = Object.assign({}, this.defaultForm);
                this.editIndex = -1;
            });
        },
        delete_modal() {
            this.deleteID = this.items.id
            this.deletemodal = true
        },
        save() {

            let formData = new FormData();
            formData.append('id', this.form.id);
            formData.append('title', this.form.title);
            formData.append('detail', JSON.stringify(this.form.detail));
            formData.append('image', this.form.image);

            this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
            this.axios.post('/api/pengaduan-nasabah-params/storeOrUpdate', formData).then(() => {
                this.getData();
                this.clearform()
                this.$notify({
                    title: "Notification",
                    type: "success",
                    text: "Data Updated Successfully"
                });
            }).catch(error => {
                console.log(error)
            }).finally(() => {
                this.isLoading = false;

            })

        },

        editModal() {
            this.editIndex = 1

            this.form.id = this.items.id
            this.form.title = this.items.title
            this.form.detail = this.items.detail
            this.modal = true
        },

        deleteData() {
            this.isLoading = true;
            this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
            this.axios.delete('api/pengaduan-nasabah-params/' + this.deleteID).then(() => {
                this.getData()
                this.deleteID = null
                this.deletemodal = false
            }).catch(error => {

                console.log(error)

            }).finally(() => {
                this.isLoading = false;

            })
        }
    },

    components: {
        LargeFormBaseVue,
        quillEditor,
        LButton,
        Confirm
    },

    computed: {
        title() {
            return this.editIndex > -1 ? 'Edit Item' : 'New Item';
        }
    }
};
</script>

<style lang="scss" scoped>
</style>
