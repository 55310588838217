<template>
    <div class="container-x">
        <div class="w-full h-64 md:h-96 lg:h-screen" v-if="h == null">
            
        </div>
        <div class="w-full relative overflow-hidden" v-if="h !== null">
            <img :src="h.img" :alt="h.title" class="w-full object-cover object-center">
            <div class=" hidden md:block absolute z-10 md:left-16 md:bottom-16 lg:left-28 lg:bottom-32">
                <div v-if="h.showtitle == 'true'" class="title md:text-3xl lg:text-5xl font-black mb-8">{{h.title}}</div>
                <p  v-if="h.showdesc == 'true'" class="text-sm md:w-2/4 lg:w-2/4 font-medium leading-relaxed">{{h.description}}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ClientHero',
    props:['hero'],
    data() {
        return {
            h:null
        };
    },

    mounted() {
    
    },

    methods: {
        
    },

    watch:{
        hero:function(){

            if(this.hero.id == undefined){
                this.h = null
            }else{
                this.h = this.hero
            }
           
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../assets/main.scss"
</style>