<template>
    <div class="w-full bg-gray-100 dark:bg-gray-900 rounded-md shadow-md py-8">
        <div class="px-5">
            <slot name="table-slot"></slot>
        </div>
        <table class="w-full border-collapse" :class="fixed ? 'table-fixed' : 'table-auto'">
            <thead>
                <tr class="table-auto w-full border-b border-gray-200 dark:border-gray-800">
                    <td v-for="h in head" :key="h" class="text-gray-600 dark:text-gray-100 text-xs font-bold py-2 px-5">{{h.Text}}</td>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in data" :key="item" class="table-auto w-full last:border-b-0 border-b border-gray-200 dark:border-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700">
                    <td v-for="c in header" :key="c" class="text-sm font-normal text-gray-600 dark:text-gray-100 py-2 px-5">
                        {{ c.type =='string' ? (item[c.value]) : '' }}
                        <div v-if="c.type == 'images'" class="h-8 w-8 rounded-3xl overflow-hidden">
                             <img  :src="item[c.value]" alt="Admin Bank Padma" class="w-full h-full object-cover object-center">
                        </div>
                        <div v-if="c.type == 'icon'" class="h-8 w-8 rounded-3xl overflow-hidden">
                            <mdicon :name="item[c.value]" size="21" class="text-gray-500"/>
                        </div>
                        <div v-if="c.action == true">
                            <slot name="action" :item="item"></slot>
                        </div>     
                        <div v-if="c.type == 'chip'">
                            <slot name="chip" :item="item[c.value]"></slot>
                        </div>                            
                    </td>
                </tr>
            </tbody>
        </table>

        <div class="px-5 flex justify-between items-center h-10 mt-6" v-if="page > 1">
            <ul class="flex items-center h-full">
                <li v-if="currentPage > 1 " @click="prev" class="h-full flex justify-center items-center px-2 text-xs font-medium border cursor-pointer text-primary bg-gray-100 dark:bg-gray-900 border-gray-300 dark:border-gray-800 dark:text-gray-100">Prev</li>
                <li v-for="p in pages" :key="p" class="h-full flex justify-center items-center px-3 text-sm font-medium border cursor-pointer" :class="currentPage === p ? 'bg-primary text-gray-100 border-primary' : 'text-primary bg-gray-100 dark:bg-gray-900 dark:text-gray-100 border-gray-300 dark:border-gray-800'" @click="change(p)">{{p}}</li>
                <li v-if="currentPage < page"  @click="next" class="h-full px-2 text-xs flex justify-center items-center font-medium border cursor-pointer text-primary bg-gray-100 dark:bg-gray-900 border-gray-300 dark:text-gray-100 dark:border-gray-800">Next</li>
            </ul>
            <div class="flex h-full justify-end items-center">
                <span class="text-sm text-gray-700 dark:text-gray-100">Go To Page</span>
                <input v-model="go" type="text" class="rounded border border-gray-400 bg-transparent focus:outline-none px-2 text-sm h-3/4 mx-2 w-16">
                <span class="text-sm text-gray-700 cursor-pointer dark:text-gray-100" @click="goPage">Go</span>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PadmawebDataTable',
    props: ['header','datatable','fixed'],
    data() {
        return {
            fixedData:[],
            go:null
        };
    },
    mounted() {
        
    },
    methods: {
       change(e){
           this.$emit('page-changed',e)
       },
       prev(){
           this.$emit('page-changed', this.currentPage - 1)
       },
       next(){
           this.$emit('page-changed', this.currentPage + 1)
       },
       goPage(){
           this.$emit('page-changed', this.go)
           this.go = null
       }
    }
    ,
    computed:{
        head(){
            return this.header
        },  
        data(){
            return this.datatable.data
        },
        total(){
            return this.datatable.total
        },
        currentPage(){
            return this.datatable.current_page
        },
        perPage(){
            return this.datatable.per_page
        },
        page(){
            return Math.ceil(this.datatable.total / this.datatable.per_page)
        },
         pages(){
            const onSides = 1
            let pages = [];
            // Loop through
            for (let i = 1; i <= this.page; i++) {
                // Define offset
                let offset = (i == 1 || this.page) ? onSides + 1 : onSides;
                // If added
                if (i == 1 || (this.currentPage - offset <= i && this.currentPage + offset >= i) ||
                    i == this.currentPage || i == this.page) {
                    pages.push(i);
                } else if (i == this.currentPage - (offset + 1) || i == this.page + (offset + 1)) {
                    pages.push('...');
                }
            }
            return pages;            
        }
    }
};
</script>

<style lang="scss" scoped>

</style>