<template>
    <div class="px-10 h-screen">
        <div class="mb-5 w-full">
            <div class="grid grid-cols-[2fr_4fr] gap-5 h-full">
                <div class="">
                   
                    <div class="group">
                        <div>
                            <img :src="Placeholder" alt="">
                        </div>
                        
                        <div class="mb-5 mt-4">                            
                            <div class="text-md uppercase font-bold text-white bg-primary mb-2 pb-4 px-2">contact</div>
                            <div class="flex flex-row mb-3">
                                <div class="flex justify-center items-center">
                                    <mdicon name="map-marker" size="20" class="mr-1 text-gray-700 dark:text-gray-100"/>
                                </div>
                                <div class="text-sm font-normal capitalize">{{data.address}}</div>
                            </div>
                            <div class="flex flex-row mb-3">
                                <div class="flex justify-center items-center">
                                    <mdicon name="phone" size="20" class="mr-1 text-gray-700 dark:text-gray-100"/>
                                </div>
                                <div class="text-sm font-normal capitalize">{{data.phone}}</div>
                            </div>
                            <div class="flex flex-row mb-2">
                                <div class="flex justify-center items-center">
                                    <mdicon name="email" size="20" class="mr-1 text-gray-700 dark:text-gray-100"/>
                                </div>
                                <div class="text-sm font-normal capitalize self-center">{{data.email}}</div>
                            </div>
                        </div>

                        <div class="mb-5 mt-4">                            
                            <div class="text-md uppercase font-bold text-white bg-primary mb-2 pb-4 px-2">Bahasa</div>
                            <ul>
                                <li class="list-disc flex flex-col" v-for="(language,index) in data.language" :key="language">
                                     <div class="flex font-bold mb-2">
                                        <div>{{language.languagename}}</div>
                                    </div>
                                    <ul class="ml-5 text-sm font-normal">
                                        <li class="flex mb-2">
                                            Berbicara : 
                                            {{language.languagespeaking}}
                                        </li>
                                        <li class="flex mb-2">
                                            Menulis : 
                                            {{language.languagewriting}}
                                        </li>
                                        <li class="flex mb-2">
                                            Mendengar : 
                                            {{language.languagelistening}}
                                        </li>
                                        <li class="flex mb-2">
                                            Membaca : 
                                            {{language.languagereading}}
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="uppercase text-xl font-bold">{{data.name}}</div>
                    <div class="uppercase text-lg font-normal">{{data.job}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Img from '../../../../../public/placeholder.png'
export default {
    name: 'PadmawebVacancyPDF',
    props:['data'],
    data() {
        return {
            Placeholder : Img
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>

<style lang="scss" scoped>

</style>