<template>
   <div class="py-4 md:pb-8" v-if="items.length">
       <div class="container-y">
           <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 relative gap-5 items-center w-full mb-5">
               <div v-for="item in items" :key="item" class="relative item  shadow-md overflow-hidden cursor-pointer group w-full">
                    <a :href="item.filepath" target="_blank">
                        <img :src="item.imagepath" :alt="item.title" class="w-full h-full object-cover object-center group-hover:scale-[1.1] duration-300 group-hover:grayscale">
                    </a> 
                    <div class="text-center w-full  py-3 group-hover:translate-y-[100px] duration-300 text-sm font-bold z-20 absolute bottom-0 bg-w-overlay dark:bg-b-overlay text-gray-900 dark:text-gray-200">
                        {{item.title}}
                    </div>                   
               </div>
           </div>
           <div class="md:hidden">
                <Share></Share>
            </div>
       </div>
       
   </div>
</template>

<script>
import store from '@/store';
import Share from '@/layouts/Share.vue';
export default {
    name: 'ClientContentBrosur',

    data() {
        return {
            items:[]
        };
    },

    mounted() {
        this.getData()
    },

    methods: {
        getData(){
            store.commit('ChangeLoader',true)
            this.axios.get('api/getbrosur').then((response) => {
                this.items = response.data
            }).catch(error => {
                
            console.log(error)

            }).finally(() => {
                store.commit('ChangeLoader',false)
            })
        }
    },
    components:{
        Share
    }
};
</script>

<style lang="scss" scoped>

</style>