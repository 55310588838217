<template>
    <div>
        <AssetTableVue></AssetTableVue>
    </div>
</template>

<script>
import AssetTableVue from '@/components/Admin/Component/AssetTable.vue';
export default {
    name: 'PadmawebAssetView',

    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        
    },

    components:{
        AssetTableVue
    }
};
</script>

<style lang="scss" scoped>

</style>