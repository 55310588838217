<template>
    <div>
       <criticTableVue></criticTableVue>
    </div>
</template>

<script>
import criticTableVue from '@/components/Admin/Component/criticTable.vue';
export default {
    name: 'PadmawebCriticView',

    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
    components:{
        criticTableVue
    }
};
</script>

<style lang="scss" scoped>

</style>