<template>
    <div class=" border-b-2 pb-4 md:pb-8 border-secondary md:border-0">
       <Tabnav :tabs="tabs" :selected="selected" @selected="setSelected">
			<Tab :isSelected="selected === 'laporan-tahunan'">
				<Annual />
			</Tab>
			<Tab :isSelected="selected === 'laporan-tata-kelola'">
				<Tatakelola />
			</Tab>
			<Tab :isSelected="selected === 'laporan-publikasi'">
				<Publikasi />
			</Tab>
		</Tabnav>

		 <div class="share">
            <Share></Share>
        </div>
    </div>
</template>

<script>
import Tabnav from './Tabnav.vue';
import Tab from './Tab.vue';
import Annual from './annual.vue';
import Tatakelola from './tatakelola.vue';
import Publikasi from './publikasi.vue';
import Share from '../../layouts/Share.vue'
export default {
    name: 'ClientContentLaporan',

    data() {
        return {
            laporan: '',
            selected:'laporan-tahunan',

            tabs:[
                {
                    name: 'Laporan Tahunan',
                    link: 'laporan-tahunan',
                    initial: 'laporan-tahunan',
                    icon: 'finance'
                },
                {
                    name: 'Laporan Publikasi',
                    link: 'laporan-publikasi',
                    initial: 'laporan-publikasi',
                    icon: 'newspaper-variant-multiple-outline'
                },
                {
                    name: 'Laporan Tata Kelola',
                    link: 'laporan-tata-kelola',
                    initial: 'laporan-tata-kelola',
                    icon: 'sitemap'
                },

            ],
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
    components:{
        Tabnav,
        Tab,
        Publikasi,
        Annual,
        Tatakelola,
        Share
    },
    methods:{
        setSelected(tab){
            this.selected = tab;
        },
    }
};
</script>

<style lang="scss" scoped>
.share{
    margin-top: 25px;
}

@media screen and (min-width:768px) {
	.share{
		display: none;
	}
}
</style>