<template>
    <div class="w-full py-7 md:py-14 container-y bg-white dark:bg-gray-900" v-if="company.length">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-10 h-full justify-center items-start">
            <div class="order-2 md:order-1">
                <div class=" text-lg md:text-2xl text-primary font-bold md:mb-5 capitalize leading-loose dark:text-gray-200">{{company[0].videotitle}}</div>
                <div class=" text-sm md:text-lg text-primary font-normal dark:font-thin leading-7 md:leading-relaxed h-full dark:text-gray-200">{{company[0].videodescription}}</div>
            </div>
            <div class="order-1 md:order-2 relative overflow-hidden w-full h-60 md:h-72 lg:h-80">
                <div class="content" v-html="video"></div>
            </div>
           
        </div>
    </div>
</template>

<script>
import store from '@/store';

export default {
    name: 'PadmawebVideo',

    data() {
        return {            
        };
    },

    created() {
    },
    components: { 
        
    },
    methods: {
        onReady() {
            this.$refs.youtube.pauseVideo()
        }
    },
    computed:{
        video(){
            return this.company[0].videopath
        },
         company(){
            return store.state.company
        }
    }
};
</script>

<style lang="scss" scoped>
.content::v-deep iframe{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}
</style>