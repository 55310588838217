<template>
  <div class="container-y py-8 lg:py-16 bg-gray-100 overflow-hidden dark:bg-gray-800" v-if="items.length">
    <div class="blog-head">
        <div class="text-sm lg:text-base lg:mb-3 font-semibold uppercase text-gray-700 dark:text-gray-100">Blog Terbaru kami</div>
        <div class="header font-black text-3xl lg:text-4xl uppercase text-gray-700 dark:text-gray-100 mb-5 lg:mb-8">BPR Padma</div>
    </div>
    <Carousel :breakpoints="breakpoints" :wrap-around="true" loop>
        <Slide v-for="(item,i) in items" :key="i">
            <div class="carousel__item">
                <div class="bg-white dark:bg-gray-900 overflow-hidden rounded flex flex-col justify-between drop-shadow">
                    <div class="b-image h-[200px] md:h-[250px] overflow-hidden">
                        <img :src="item.imagepath" :alt="item.title" class="h-full w-full object-cover object-center">
                    </div>
                    <div class="text-primary dark:text-gray-100 p-3 lg:p-4">
                        <div class="w-full h-[80px] text-left mb-5 text-lg">
                          <router-link :to="{ name: 'DBlog', params: { blog: item.permalink }}" class="b-headine text-left hover:text-secondary duration-300 font-semibold mb-5 lg:mb-8">
                              {{item.title}}
                          </router-link>
                        </div>
                        <div class="flex h-[50px] flex-row items-center justify-start dark:text-gray-100 text-gray-500">
                            <div class="footer-group flex flex-row mr-3 items-center justify-center">
                                <div class="footer-icon mr-2"><mdicon name="account" size="20"/></div>
                                <div class="footer-content text-sm">{{item.user.name}}</div>
                            </div>
                            <div class="footer-group flex flex-row items-center justify-center">
                                <div class="footer-icon mr-2"><mdicon name="calendar" size="20" /></div>
                                <div class="footer-content text-sm">{{moment(item.published_date).locale('id').format("DD MMMM YYYY")}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Slide>

        <template #addons>
        <Navigation />
        </template>
    </Carousel>
  </div>
</template>

<script>
import moment from "moment";
import { defineComponent } from 'vue'
import { Carousel, Navigation, Pagination, Slide } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent({
  name: 'ClientBlog',
 data(){
     return{
        moment : moment,
         items: [],
            breakpoints: {
                // 700px and up
                768: {
                    itemsToShow: 2,
                    snapAlign: 'start',
                },
                // 1024 and up
                1024: {
                    itemsToShow: 3,
                    snapAlign: 'start',
                },
            },
     }
 },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },

  methods: {
    getData(){
      this.axios.get('api/getblogs').then((response) => {
            this.items = response.data
        }).catch(error => {
            
          console.log(error)

        }).finally(() => {
            
        })
    }
  },
  mounted() {
    this.getData()
  },
});
</script>

<style lang="scss">
@import "../../assets/main.scss";
.header{
    position: relative;
    &::after{
        content: '';
        bottom: -10px;
        left: 0;
        height: 4px;
        width: 120px;
        background-color: $secondary;
        position: absolute;
    }
}
.carousel__item {
  width: 100%;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__slide {
  padding: 10px;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid white;
  background-color: $primary !important;
}
</style>