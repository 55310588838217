<template>
    <div>
        <v-loading v-if="isLoading"></v-loading>
       <DataTable :datatable="items" :fixed="false" :header="headers" @page-changed="pageChange($event)">
           <template v-slot:table-slot>
               <div class="grid w-full grid-cols-[5fr_1fr_1fr] gap-10">
                   <form @submit.prevent action="" class="w-full">
                       <div class="flex border-b-2 bg-gray-100 border-gray-300 dark:bg-gray-900 dark:text-gray-100 dark:border-gray-800 focus:outline-none py-1 mb-4 w-full text-gray-700 font-medium">
                           <input type="text" class="focus:outline-none bg-transparent w-full" placeholder="Search..." v-model="search">
                           <mdicon name="magnify" size="28" class="mr-1 text-gray-700 dark:text-gray-100"/>
                       </div>
                    </form>

                    <div class="flex border-b-2 bg-gray-100 border-gray-300 dark:bg-gray-900 dark:text-gray-100 dark:border-gray-800 focus:outline-none py-1 mb-4 w-full text-gray-700 font-medium">
                        <select name="" id="" class="focus:outline-none bg-transparent w-full text-sm text-gray-500 font-medium" v-model="typesearch">
                            <option value="">All</option>
                            <option value="new">new</option>
                            <option value="process">process</option>
                            <option value="rejected">rejected</option>
                            <option value="approved">approved</option>
                        </select>
                    </div>

                     <JsonCSV :data="items.data">
                        <button class="bg-primary text-white px-3 py-2 rounded-sm text-xs font-weight">Download CSV</button>
                    </JsonCSV>   

             </div>
           </template>

           <template v-slot:action="item">
                <div class="flex">
                    <button class="flex justify-center items-center bg-primary  px-2 py-1 text-gray-100 rounded text-xs font-medium focus:outline-none" @click="editModal(item)" v-if="isUpdatePermitted"><mdicon name="gesture-double-tap" class="text-gray-100 mr-[2px]" size="15"/></button>
                    <button class="flex justify-center items-center bg-secondary mx-1 px-2 py-1 text-gray-100 rounded text-xs font-medium focus:outline-none" @click="deleteModal(item)" v-if="isDeletePermitted"><mdicon name="trash-can-outline" class="text-gray-100 mr-[2px]" size="15"/></button>
                    <button class="flex justify-center items-center bg-danger px-2 mr-1 py-1 text-gray-100 rounded text-xs font-medium focus:outline-none" @click="generateReport(item)" v-if="isUpdatePermitted"><mdicon name="file" class="text-gray-100 mr-[2px]" size="15"/></button>
                    <button class="flex justify-center items-center bg-gray-900 px-2 py-1 text-gray-100 rounded text-xs font-medium focus:outline-none" @click="downloadimage(item)" v-if="isUpdatePermitted"><mdicon name="account" class="text-gray-100 mr-[2px]" size="15"/></button>
               </div>
           </template>


           <template v-slot:chip="item">
                <div class="text-white text-center font-medium text-xs">
                    <div class="bg-gray-700 p-1 rounded-full" v-if="item.item == 'new'">
                         {{item.item}}
                    </div>
                    <div class="bg-danger p-1 rounded-full" v-if="item.item == 'rejected'">
                         {{item.item}}
                    </div>
                    <div class="bg-secondary p-1 rounded-full" v-if="item.item == 'process'">
                         {{item.item}}
                    </div>
                    <div class="bg-primary p-1 rounded-full" v-if="item.item == 'approved'">
                         {{item.item}}
                    </div>
                </div>              
           </template>
       </DataTable>
        <Transition name="fade">
            <Confirm v-if="modal" @closeconfirm="modal = false">
                <template v-slot:header>
                    Process
                </template>
                <template v-slot:main>
                    Are You Sure You Want To Process This Data ?
                </template>
                <template v-slot:footer>
                    <div class="flex justify-end items-center">
                        <LButton class="text-white bg-secondary mr-2" @click="modal = false">
                            Cancel
                        </LButton>
                        <LButton class="text-white bg-primary" :class=" isloading ? 'pointer-events-none' : 'cursor-pointer'" :disabled="isloading" @click="process">
                            {{ isloading ? 'Loading...' : 'Confirm' }}
                        </LButton>                            
                    </div>
                </template>
            </Confirm>  
        </Transition>

      <vue3-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="false"
        :preview-modal="true"
        :paginate-elements-by-height="1400"
        filename='Lamaran Kerja'
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="legal"
        pdf-orientation="landscape"
        ref="html2Pdf"
    >
        <template  v-slot:pdf-content>
            <vacancyPDFVue :data="pdf"></vacancyPDFVue>
        </template>        
    </vue3-html2pdf>

       <Transition name="fade">
            <Confirm v-if="deletemodal" @closeconfirm="deletemodal = false">
                <template v-slot:header>
                    Confirm
                </template>
                <template v-slot:main>
                    Are You Sure You Want To Delete This Data ?
                </template>
                <template v-slot:footer>
                    <div class="flex justify-end items-center">
                        <LButton class="bg-gray-300 text-gray-800 mr-2" @click="deletemodal = false">
                            Cancel
                        </LButton>
                        <LButton class="text-white bg-secondary" :class=" isloading ? 'pointer-events-none' : 'cursor-pointer'" :disabled="isloading" @click="deleteData">
                            {{ isloading ? 'Loading...' : 'Confirm' }}
                        </LButton>                            
                    </div>
                </template>
            </Confirm>  
        </Transition>
    </div> 
</template>

<script>
import LButton from '@/layouts/button.vue'
import Confirm from '@/layouts/confirm.vue'
import DataTable from '@/layouts/DataTable.vue'
import LargeFormBaseVue from '@/layouts/LargeFormBase.vue'
import taginputVue from '@/layouts/taginput.vue'

import Vue3Html2pdf from 'vue3-html2pdf'
import vacancyPDFVue from './vacancyPDF.vue'
import JsonCSV from 'vue-json-csv'

export default {
    name: 'PadmawebVacancyTable',

    data() {
        return {
            isLoading:false,
            deletemodal: false,
            deleteID: null,
            form : {
                id:'',
                status:'',
            },
           defaultForm : {
                id:'',
                status:'',
            },
            headers:[],
            items:[],
            currentPage : 1,
            modal:false,
            roles: [],
            search: '',
            typesearch: '',
            pdf:{},
            processed:false
        };
    },

    watch:{
        search:function(){
            if(this.search.length > 0){
                this.searchData(this.search)
            } else {
                this.getData()
            }
            
        },
        modal(val) {
			val || this.clearform();
		},
        typesearch:function(){
            if(this.typesearch.length > 0){
                this.typesearchdata(this.typesearch)
            } else {
                this.getData()
            }
            
        },
    },

    mounted() {
        this.getData()
        this.getheader()
    },

    methods: {
        addTag(val) {			
           this.form.keyword.push(val)
        },
		removeTag(index){
			 this.form.keyword.splice(index, 1)
		},
        getheader(){
            if(this.isDeletePermitted == true || this.isUpdatePermitted == true){
                this.headers = [
                    {
                    Text : 'ID',
                    value : 'id',
                    type: 'string'
                },
                   {
                    Text : 'Name',
                    value : 'name',
                    type: 'string'
                },
                {
                    Text: 'Position',
                    value: 'job',
                    type: 'string'
                }
                ,
                {
                    Text: 'Birth',
                    value: 'birth',
                    type: 'string'
                }
                ,
                {
                    Text: 'Gender',
                    value: 'gender',
                    type: 'string'
                },
                {
                    Text: 'Religion',
                    value: 'religion',
                    type: 'string'
                },
                {
                    Text: 'Married Status',
                    value: 'marriedstatus',
                    type: 'string'
                },
                {
                    Text: 'Status',
                    value: 'status',
                    type: 'chip'
                },
                {
                    Text: 'Actions',
                    action: true,
                }
                ]
            }else{
                this.headers = [
                    {
                    Text : 'ID',
                    value : 'id',
                    type: 'string'
                },
                   {
                    Text : 'Name',
                    value : 'name',
                    type: 'string'
                },
                {
                    Text: 'Position',
                    value: 'job',
                    type: 'string'
                }
                ,
                {
                    Text: 'Birth',
                    value: 'birth',
                    type: 'string'
                }
                ,
                {
                    Text: 'Gender',
                    value: 'gender',
                    type: 'string'
                },
                {
                    Text: 'Religion',
                    value: 'religion',
                    type: 'string'
                },
                {
                    Text: 'Married Status',
                    value: 'marriedstatus',
                    type: 'string'
                },
                {
                    Text: 'Status',
                    value: 'status',
                    type: 'chip'
                }
                ]
            }
        },
        addModal(){
            this.editIndex = -1
            this.modal = !this.modal
        },
        deleteModal(val){
            this.deleteID = JSON.parse(JSON.stringify(val)).item.id
            this.deletemodal = true
        },
        editModal(val){
            let obj = JSON.parse(JSON.stringify(val)).item
            
            this.form.id = obj.id
            this.form.status = obj.status
            this.modal = true
        },
        downloadimage(val){
            let obj = JSON.parse(JSON.stringify(val)).item
            let photopath = obj.photopath
            let photo = obj.photo 

             const links = document.createElement('a')
            links.href = photopath           
            links.setAttribute('target', '_blank');
            links.setAttribute('download', photo);
            document.body.appendChild(links)
            links.click()
            document.body.removeChild(links)
        }
        ,
        generateReport (val) {

            let obj = JSON.parse(JSON.stringify(val)).item
            let documentpath = JSON.parse(obj.documentpath)
            let doc = JSON.parse(obj.document)          

            for(let i = 0 ; i < documentpath.length ; i++){
                const link = document.createElement('a')
                link.href = documentpath[i]           
                link.setAttribute('target', '_blank');
                link.setAttribute('download', doc[i]);
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            }
        },
        getImage(event){
           this.form.image = event.target.files[0];
        },
        getThumbnail(event){
           this.form.thumbnail = event.target.files[0];
        },
        changePage(e){
            this.currentPage = e;
            this.getData()
        },

        getData(){
            this.isLoading = true;
           this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
            this.axios.get(`api/vacancy?page=${this.currentPage}`).then((response) => {
                this.items = response.data
                console.log(this.items)
            }).catch(error => {
               
              console.log(error)

            }).finally(() => {
                this.isLoading = false;
            })
        },
        searchData(val){
            this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
            this.axios.get('api/vacancy/search/'+val).then((response) => {
                this.items = response.data
            }).catch(error => {
               
              console.log(error)

            }).finally(() => {
                
            })
        },
        typesearchdata(val){
            this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
            this.axios.get('api/vacancy/searchtype/'+val).then((response) => {
                this.items = response.data
            }).catch(error => {
               
              console.log(error)

            }).finally(() => {
                
            })
        },
        pageChange: function(e){
           this.currentPage = e;
           this.getData()
        },

        clearform: function() {
            this.modal = false
            this.$nextTick(() => {
                this.form = Object.assign({}, this.defaultForm);
                this.editIndex = -1;
            });
        }
           
        ,
        process(){
            this.isLoading = true
            let formData = new FormData();
            formData.append('id', this.form.id);		
            formData.append('status', this.form.status);		
                this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
                this.axios.post('/api/vacancy/process',formData).then(() => {
                this.getData();
                this.clearform()
                this.$notify({
                    title: "Notification",
                    type: "success",
                    text: "Data Processed Successfully"
                });
            }).catch(error => {                
                console.log(error)
            }).finally(() => {
                this.isLoading = false
            })

        },
        deleteData(){
            this.isLoading = true;
            this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
            this.axios.delete('api/vacancy/'+this.deleteID).then(() => {
                this.getData()
                this.deleteID = null
                this.deletemodal = false
            }).catch(error => {
               
              console.log(error)

            }).finally(() => {
                this.isLoading = false;
            })
        },
        thousand(val){
            return 'Rp. ' + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    },
    components:{
        DataTable,
        Confirm,
        LButton,
        LargeFormBaseVue,
        taginputVue,
        Vue3Html2pdf,
        vacancyPDFVue,
        JsonCSV
    },
    computed:{
        title(){
            if(this.editIndex > -1){
                return 'Edit Item'
            }else{
                return 'New Item'
            }
        }        
    }
};
</script>

<style lang="scss" scoped>

</style>