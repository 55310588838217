<template>
    <div v-if="items.length">
        <div class="text-3xl font-medium border-b-2 pb-3 mb-8 border-secondary text-gray-700 dark:text-gray-200">Populer</div>
        <div class="w-ful">
            <div class="grid gap-2 md:gap-4 grid-cols-[2fr_3fr] md:grid-cols-2 mb-4" v-for="item in items" :key="item.id">
                <div class="i-img">
                    <img :src="item.imagepath" :alt="item.title">
                </div>
                <div class="i-desc">
                    <div class="i-date text-sm font-medium text-gray-500 dark:text-gray-200 mb-1">
                       {{moment(item.published_date).locale('id').format("DD MMMM YYYY")}}
                    </div>
                    <div class="r-tag bg-primary mb-1 text-xs font-medium text-white px-2 my-2 py-1 rounded-sm w-28 text-center">
                        <router-link :to="'/blog/kategori/'+item.blog_category.permalink">
                            {{item.blog_category.category}}
                        </router-link>
                    </div>
                    <div class="i-headline text-sm font-semibold text-primary dark:text-gray-200 duration-300 hover:text-secondary dark:hover:text-secondary">
                         <router-link :to="{name : 'DBlog', params: { blog: item.permalink } }">
                              {{item.title}}
                         </router-link>                       
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
export default {
    name: 'ClientPopular',

    data() {
        return {
             items:[],
             moment: moment,
        };
    },

    mounted() {
        this.getData()
    },

    methods: {
        async getData(){
            this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
            await this.axios.get('api/getpopularblog').then((response) => {
                this.items = response.data
            }).catch(error => {
               
              console.log(error)

            }).finally(() => {
                
            })
        },
    },
};
</script>

<style lang="scss" scoped>

</style>