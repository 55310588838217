<template>
    <div>
        <div class="flex justify-between items-center border-b-2 pb-3 mb-8 border-secondary">
            <div class="text-3xl font-medium text-gray-700 dark:text-gray-200">Category</div>
            <select name="" id="" class=" bg-primary text-white text-sm py-1 px-1 focus:outline-none" v-model="category" @change="changecategory">
                <option value="All">All</option>
                <option v-for="c in categories" :key="c" :value="c.id">{{c.category}}</option>
            </select>
        </div>
        <div class="w-full">
            <div class="grid gap-2 md:gap-4 grid-cols-[2fr_3fr] md:grid-cols-[2fr_5fr] mb-4" v-for="item in items.data" :key="item">
                <div class="i-img w-full object-cover object-center">
                    <img :src="item.imagepath" :alt="item.title">
                </div>
                <div class="i-desc flex flex-col w-full">
                    <div class="i-date text-sm font-medium text-gray-500 dark:text-gray-200 mb-2">
                        {{moment(item.published_date).locale('id').format("DD MMMM YYYY")}}
                    </div>
                    <div class="text-sm md:text-base font-bold text-primary dark:text-gray-200 duration-300 hover:text-secondary dark:hover:text-secondary mb-2">
                        <router-link :to="{name : 'DBlog', params: { blog: item.permalink } }">
                             {{item.title}}
                        </router-link>                       
                    </div>
                    <div class="hidden md:block text-sm leading-relaxed font-normal text-gray-600 dark:text-gray-200 mb-2" v-html="item.description">

                    </div>
                </div>
            </div>            
        </div>
        <div class="px-5 flex justify-between items-center h-10 my-6" v-if="page > 1">
            <ul class="flex items-center h-full mr-5">
                <li v-if="currentPage > 1 " @click="prev" class="h-full flex justify-center items-center px-2 text-xs font-medium border cursor-pointer text-primary bg-gray-100 dark:bg-gray-900 border-gray-300 dark:border-gray-800 dark:text-gray-100">Prev</li>
                <li v-for="p in pages" :key="p" class="h-full flex justify-center items-center px-3 text-sm font-medium border cursor-pointer" :class="currentPage === p ? 'bg-primary text-gray-100 border-primary' : 'text-primary bg-gray-100 dark:bg-gray-900 dark:text-gray-100 border-gray-300 dark:border-gray-800'" @click="change(p)">{{p}}</li>
                <li v-if="currentPage < page"  @click="next" class="h-full px-2 text-xs flex justify-center items-center font-medium border cursor-pointer text-primary bg-gray-100 dark:bg-gray-900 border-gray-300 dark:text-gray-100 dark:border-gray-800">Next</li>
            </ul>
        </div>
    </div>
</template>

<script>
import moment from "moment";
export default {
    name: 'ClientCat',

    data() {
        return {
             
             categories: [],
             items:[],
             currentPage: 1,
             moment : moment,
             category: 'All'
        };
    },

    mounted() {
        this.getData()
        this.getCategory()
    },

    methods: {
        changecategory(){           
            if(this.category == 'All'){
                this.getData()
            }else{
                this.dataCategory()
            }
            
        },
        change(e){
           this.currentPage = e
       },
        prev(){
           this.currentPage = this.currentPage - 1
       },
       next(){
           this.currentPage = this.currentPage + 1
       },
        async getData(){
            this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
            await this.axios.get('api/getcategorydata?page='+this.currentPage).then((response) => {
                this.items = response.data
            }).catch(error => {
               
              console.log(error)

            }).finally(() => {
                
            })
        },
        async getCategory(){
            this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
            await this.axios.get('api/getblogcategory').then((response) => {
                this.categories = response.data
            }).catch(error => {
               
              console.log(error)

            }).finally(() => {
                
            })
        },
        async dataCategory(){
            this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
            await this.axios.get('api/getblog/category/'+this.category+'?page='+this.currentPage).then((response) => {
                this.items = response.data
                console.log(this.items)
            }).catch(error => {
               
              console.log(error)

            }).finally(() => {
                
            })
        },
    },
     computed:{
        page(){
            return Math.ceil(this.items.total / this.items.per_page)
        },
        pages(){
            const onSides = 1
            let pages = [];
            // Loop through
            for (let i = 1; i <= this.page; i++) {
                // Define offset
                let offset = (i == 1 || this.page) ? onSides + 1 : onSides;
                // If added
                if (i == 1 || (this.currentPage - offset <= i && this.currentPage + offset >= i) ||
                    i == this.currentPage || i == this.page) {
                    pages.push(i);
                } else if (i == this.currentPage - (offset + 1) || i == this.page + (offset + 1)) {
                    pages.push('...');
                }
            }
            return pages;            
        }
    },
    watch:{
        currentPage:function(){
            if(this.category == 'All'){
                this.getData();
            }else{
                this.dataCategory()
            }
        }
    }
};
</script>

<style lang="scss" scoped>

</style>