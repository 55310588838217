<template>
    <div class="container-y md:h-auto flex flex-col lg:flex-row py-10 lg:py-14 dark:bg-gray-800" v-if="items.length">
        <div class="w-full lg:w-2/3">
            <div class="why text-2xl md:text-4xl lg:text-5xl font-bold text-primary dark:text-gray-200 leading flex flex-row lg:flex-col justify-center items-start mb-10 md:mb-16">
                <span class="mb-3 mr-1">Mengapa</span>
                <span class="">Bank Padma ?</span>
            </div>
        </div>
        <div class="w-full">
            <div class="grid gap-4 grid-cols-1 md:grid-cols-2 md:gap-10">
                <div class="card flex flex-col mb-10 lg:mb-0 justify-center items-center lg:justify-start lg:items-start" v-for="item in items" :key="item.id">
                    <div class="icon">
                        <mdicon :name="item.icon" size="100" class="dark:text-gray-200 text-primary" />
                    </div>
                    <div class="text-2xl font-semibold text-primary dark:text-gray-200 my-3 lg:my-5">
                            {{item.title}}
                    </div>       
                    <div class="text-sm text-center lg:text-left text-gray-700 dark:text-gray-200 leading-relaxed">
                        {{item.description}}
                    </div>                 
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ClientWhy',

    data() {
        return {
            items:[]
        };
    },

    mounted() {
        this.getData();
    },

    methods: {
        getData(){
            this.axios.get('api/getwhy').then((response) => {
                    this.items = response.data
                }).catch(error => {
                    
                console.log(error)

                }).finally(() => {
                    
                })
            }
    },
};
</script>

<style lang="scss" scoped>
@import '../../assets/main.scss';
svg{
    height: 70px;
    width: 70px;
}

.why{
    position: relative;
    &::before{
        content: '';
        position: absolute;
        height: 5px;
        width: 150px;
        background-color: $secondary;
        bottom: -10px;
    }
}

@media screen and (min-width:1024px){
    svg{
        height: 80px;
        width: 80px;
    }
    .why{
        position: relative;
        &::before{
            content: '';
            position: absolute;
            height: 8px;
            width: 150px;
            background-color: $secondary;
            bottom: -25px;
        }
    }
}
</style>