<template>
    <div class="border-b-2 border-secondary md:border-b-0">
       <div class="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
           <div class="card group" v-for="item in items" :key="item">
                <div class="w-full overflow-hidden rounded-sm h-80 md:h-64 lg:h-72 shadow-lg mb-3">
                    <img :src="item.imagepath" :alt="item.name" class="w-full h-full object-cover object-center group-hover:scale-[1.1] group-hover:grayscale duration-300">
                </div>
                <div class="text-lg lg:text-xl font-bold text-primary dark:text-gray-200 hover:text-secondary duration-300 mb-1">
                    <router-link :to="{ name: 'DManajemen', params: { permalink: item.permalink} }">{{item.name}}</router-link>                    
                </div>
                <div class="text-gray-500 dark:text-gray-200 text-lg md:text-base lg:text-lg font-normal uppercase mb-2">
                    {{item.position}}
                </div>
           </div>
       </div>
        <div class="block md:hidden py-4">
            <Share></Share>
        </div>
    </div>
</template>

<script>
import store from '@/store';
import Share from '../../layouts/Share.vue'
export default {
    name: 'ClientContentvisi',

    data() {
        return {
           items:[],
        };
    },

    mounted() {
         this.getData()
    },

    methods: {
        getData(){
        store.commit('ChangeLoader',true)
        this.axios.get('api/getmanajemen').then((response) => {
                this.items = response.data
            }).catch(error => {
                
            console.log(error)

            }).finally(() => {
                store.commit('ChangeLoader',false)
                
            })
        }
    },
    components:{
        Share
    }
};
</script>

<style lang="scss" scoped>
@import "../../assets/main.scss"

</style>