<template>
    <aside class="sidebar fixed md:relative h-screen :h-auto bg-gray-100 dark:bg-gray-900 border-r border-gray-300 dark:border-gray-800 overflow-hidden duration-500 md:duration-300 box-border" :class=" menu ? 'w-full md:w-[400px] overflow-y-auto' : 'w-0 md:w-16 overflow-y-auto' ">
        <div class="profile flex relative py-5 md:py-3 border-b border-gray-300 dark:border-gray-800">
            <div class="picture flex w-16 justify-center items-center">
                <div class="h-10 w-10 rounded-full overflow-hidden">
                    <img :src="this.user.imgpath" class="w-full h-full object-cover object-center" alt="admin">
                </div>
            </div>
            <div class="flex flex-col justify-center" v-if="menu">
                <div class="text-xs font-bold">
                   {{this.user.name}}
                </div>
                <div class="text-xs font-thin">
                    {{this.user.role.role}}
                </div>
            </div>

            <div class="absolute right-4 top-6 block md:hidden">
                <mdicon name="menu" size="30" class="cursor-pointer" @click="this.$emit('closemenu')"/>
            </div>
        </div>
        <div class="menu py-5">
            <ul>
                <li v-for="item in menuFiltered" :key="item" class="h-10">
                    <router-link :to="item.route" class="flex justify-start items-center">
                        <div class="icon w-16 h-full flex justify-center items-center">
                            <mdicon :name="item.icon" size="25"/>
                        </div>
                        <div class="menu-text h-full text-xs font-semibold capitalize" v-if="menu">
                            {{item.title}}
                        </div>
                    </router-link>
                </li>
            </ul>
        </div>       
        
    </aside>
</template>

<script>
export default {
    name: 'ClientSidebar',
    props: ['menu','menuItems'],
    data() {
        return {
           
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
    computed:{
        menuFiltered(){
            const newMenu = []
            for(let i = 0 ; i < this.menuItems.length ; i++){
                if(this.menuItems[i].read == true){
                    let obj = {
                        route: this.menuItems[i].route,
                        icon: this.menuItems[i].icon,
                        title: this.menuItems[i].title,
                    }
                    newMenu.push(obj)
                }
            }
            return newMenu
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../assets/main.scss';
.sidebar::-webkit-scrollbar {
  display: none;
}

.router-link-active.router-link-exact-active {
    color: $primary;
}
     
</style>