<template>
<div>
    <v-loading v-if="isLoading"></v-loading>
    <DataTable :datatable="items" :header="headers" @page-changed="pageChange($event)">
        <template v-slot:table-slot>
            <div class="w-full grid gap-5 grid-cols-[1fr_auto]">
                <form @submit.prevent action="" class="w-full">
                    <div class="flex border-b-2 bg-gray-100 border-gray-300 dark:bg-gray-900 dark:text-gray-100 dark:border-gray-800 focus:outline-none py-1 mb-4 w-full text-gray-700 font-medium">
                        <input type="text" class="focus:outline-none bg-transparent w-full" placeholder="Search..." v-model="search">
                        <mdicon name="magnify" size="28" class="mr-1 text-gray-700 dark:text-gray-100" />
                    </div>
                </form>
                <button class="text-sm font-medium focus:outline-none active:translate-y-[2px] duration-200 bg-primary inline-block relative h-8 rounded shadow w-16 text-center text-gray-100" @click="addModal" v-if="isCreatePermitted">Add</button>
            </div>
        </template>

        <template v-slot:action="item">
            <div class="flex">
                <button class="flex justify-center items-center bg-primary mr-2 px-2 py-1 text-gray-100 rounded text-xs font-medium focus:outline-none" @click="editModal(item)" v-if="isUpdatePermitted">
                    <mdicon name="pencil" class="text-gray-100 mr-[2px]" size="15" /><span>Edit</span></button>
                <button class="flex justify-center items-center bg-secondary px-2 py-1 text-gray-100 rounded text-xs font-medium focus:outline-none" @click="deleteModal(item)" v-if="isDeletePermitted">
                    <mdicon name="trash-can-outline" class="text-gray-100 mr-[2px]" size="15" /><span>Delete</span></button>
            </div>
        </template>
    </DataTable>
    <FormBase v-if="modal" @closeModal="modal = !modal" >
        <template v-slot:formName>
            {{title}}
        </template>
        <template v-slot:form class="h-full" >
            <div class="grid gap-10 grid-cols-1 mb-5">
                <div class="form-group">
                    <input type="text" placeholder="Title" class="w-full border-b border-gray-400 dark:border-gray-800 dark:text-gray-100 bg-transparent focus:outline-none py-2 px-2 text-sm font-normal text-gray-700" v-model="form.title">
                </div>
            </div>
            <div class="grid gap-10 grid-cols-1 mb-5">
                <div class="form-group">
                    <textarea name="" placeholder="Address" id="" cols="30" rows="10" class="w-full border-b border-gray-400 dark:border-gray-800 dark:text-gray-100 bg-transparent focus:outline-none py-2 px-2 text-sm font-normal text-gray-700" v-model="form.address"></textarea>
                </div>
            </div>
            <div class="grid gap-10 grid-cols-2 mb-5">
                <div class="form-group">
                    <input type="text" placeholder="City" class="w-full border-b border-gray-400 dark:border-gray-800 dark:text-gray-100 bg-transparent focus:outline-none py-2 px-2 text-sm font-normal text-gray-700" v-model="form.city">
                </div>
                <div class="form-group">
                    <input type="text" placeholder="Wide" class="w-full border-b border-gray-400 dark:border-gray-800 dark:text-gray-100 bg-transparent focus:outline-none py-2 px-2 text-sm font-normal text-gray-700" v-model="form.wide">
                </div>
            </div>
            <div class="grid gap-10 grid-cols-2 mb-5">
                <div class="form-group">
                    <input type="text" placeholder="Price" class="w-full border-b border-gray-400 dark:border-gray-800 dark:text-gray-100 bg-transparent focus:outline-none py-2 px-2 text-sm font-normal text-gray-700" v-model="form.price">
                </div>
                <div class="form-group">
                    <div class="flex items-center justify-start">
                        <input type="text" class="w-full border-b border-gray-400 dark:border-gray-800 dark:text-gray-100 bg-transparent focus:outline-none py-2 px-2 text-sm font-normal text-gray-400" :value="form.images == null ? 'Upload Your Image':'Image Uploaded' " onclick="document.getElementById('fileImage').click()" readonly>
                        <mdicon name="paperclip" size="25" class="text-gray-400 " />
                    </div>
                    <input type='file' multiple id="fileImage" style="display:none" @change="selectImage">
                </div>
            </div>
            <div class="grid gap-10 grid-cols-2 mb-5">
                <div class="form-group">
                    <input type="Phone" placeholder="PIC" class="w-full border-b border-gray-400 dark:border-gray-800 dark:text-gray-100 bg-transparent focus:outline-none py-2 px-2 text-sm font-normal text-gray-700" v-model="form.pic">
                </div>
                <div class="form-group">
                    <input type="Phone" placeholder="Phone" class="w-full border-b border-gray-400 dark:border-gray-800 dark:text-gray-100 bg-transparent focus:outline-none py-2 px-2 text-sm font-normal text-gray-700" v-model="form.phone">
                </div>
            </div>
            <div class="grid gap-10 grid-cols-1 mb-5">
                <input type="text" placeholder="Legal Number" class="w-full border-b border-gray-400 dark:border-gray-800 dark:text-gray-100 bg-transparent focus:outline-none py-2 px-2 text-sm font-normal text-gray-700" v-model="form.legal_no">
            </div>
            <div class="w-full mb-[100px]">
                <div class="mb-2 font-bold text-gray-600">Facility</div>
                <div class=" h-[200px]">
                    <quill-editor v-model:value="form.facility" :options="state.editorOption" />
                </div>
            </div>
        </template>
        <template v-slot:formFooter>
            <div class="text-primary dark:text-gray-100 font-medium text-lg cursor-pointer hover:bg-gray-300 dark:hover:bg-gray-800 dark:hover:text-gray-100 py-2 px-4 rounded" @click="modal = !modal">
                Cancel
            </div>
            <div class="text-primary dark:text-gray-100 font-medium text-lg cursor-pointer hover:bg-gray-300 dark:hover:bg-gray-800 dark:hover:text-gray-100 py-2 px-4 rounded" @click="save">
                Save
            </div>
        </template>
    </FormBase>
    <Transition name="fade">
        <Confirm v-if="deletemodal" @closeconfirm="deletemodal = false">
            <template v-slot:header>
                Confirm
            </template>
            <template v-slot:main>
                Are You Sure You Want To Delete This Data ?
            </template>
            <template v-slot:footer>
                <div class="flex justify-end items-center">
                    <LButton class="bg-gray-300 text-gray-800 mr-2" @click="deletemodal = false">
                        Cancel
                    </LButton>
                    <LButton class="text-white bg-secondary" :class=" isloading ? 'pointer-events-none' : 'cursor-pointer'" :disabled="isloading" @click="deleteData">
                        {{ isloading ? 'Loading...' : 'Confirm' }}
                    </LButton>
                </div>
            </template>
        </Confirm>
    </Transition>
</div>
</template>

<script>
import LButton from '../../../layouts/button.vue'
import Confirm from '../../../layouts/confirm.vue'
import FormBase from '../../../layouts/FormBase.vue'
import DataTable from '../../../layouts/DataTable.vue'
import { reactive } from 'vue'
import { quillEditor } from 'vue3-quill'
export default {
    name: 'PadmawebAssetTable',

    data() {
        return {
            isLoading: false,
            deletemodal: false,
            editIndex: -1,
            deleteID: null,
            form: {
                id: '',
                title: '',
                address: '',
                city: '',
                wide: '',
                price: '',
                pic: '',
                phone: '',
                images: [],
                facility: '',
                legal_no: '',
            },
            defaultForm: {
                id: '',
                title: '',
                address: '',
                city: '',
                wide: '',
                price: '',
                pic: '',
                phone: '',
                images: [],
                facility: '',
                legal_no: '',
            },
            headers: [],
            items: [],
            currentPage: 1,
            modal: false,
            roles: [],
            search: ''
        };
    },

    watch: {
        search: function () {
            if (this.search.length > 0) {
                this.searchData(this.search)
            } else {
                this.getData()
            }

        },
        modal(val) {
            val || this.clearform();
        },
    },

    mounted() {
        this.getData()
        this.getheader()
    },

    methods: {
        getheader() {
            if (this.isDeletePermitted == true || this.isUpdatePermitted == true) {
                this.headers = [{
                        Text: 'ID',
                        value: 'id',
                        type: 'string'
                    },
                    {
                        Text: 'Title',
                        value: 'title',
                        type: 'string'
                    },
                    {
                        Text: 'Address',
                        value: 'address',
                        type: 'string'
                    },
                    {
                        Text: 'City',
                        value: 'city',
                        type: 'string'
                    },
                    {
                        Text: 'Wide',
                        value: 'wide',
                        type: 'string'
                    },
                    {
                        Text: 'Price',
                        value: 'price',
                        type: 'string'
                    },
                    {
                        Text: 'PIC',
                        value: 'pic',
                        type: 'string'
                    },
                    {
                        Text: 'Actions',
                        action: true,
                    }
                ]
            } else {
                this.headers = [{
                        Text: 'ID',
                        value: 'id',
                        type: 'string'
                    },
                    {
                        Text: 'Title',
                        value: 'title',
                        type: 'string'
                    },
                    {
                        Text: 'Address',
                        value: 'address',
                        type: 'string'
                    },
                    {
                        Text: 'City',
                        value: 'city',
                        type: 'string'
                    },
                    {
                        Text: 'Wide',
                        value: 'wide',
                        type: 'string'
                    },
                    {
                        Text: 'Price',
                        value: 'price',
                        type: 'string'
                    },
                    {
                        Text: 'PIC',
                        value: 'pic',
                        type: 'string'
                    },
                ]
            }
        },
        addModal() {
            this.editIndex = -1
            this.modal = !this.modal
        },
        deleteModal(val) {
            this.deleteID = JSON.parse(JSON.stringify(val)).item.id
            this.deletemodal = true
        },
        editModal(val) {
            this.editIndex = 1
            let obj = JSON.parse(JSON.stringify(val)).item

            this.form.id = obj.id
            this.form.title = obj.title
            this.form.address = obj.address
            this.form.city = obj.city
            this.form.wide = obj.wide
            this.form.price = obj.price
            this.form.pic = obj.pic
            this.form.phone = obj.phone
            this.form.facility = obj.facility
            this.form.legal_no = obj.legal_no

            this.modal = true
        },
        selectImage(event) {
            this.form.images = [];
            let selectedFiles = event.target.files;
            if (!selectedFiles.length) {
                return false;
            }
            for (let i = 0; i < selectedFiles.length; i++) {
                this.form.images.push(selectedFiles[i]);

            }
            console.log(this.form.images)

        },
        changePage(e) {
            this.currentPage = e;
            this.getData()
        },

        getData() {
            this.isLoading = true;
            this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
            this.axios.get(`api/asset?page=${this.currentPage}`).then((response) => {
                this.items = response.data
            }).catch(error => {

                console.log(error)

            }).finally(() => {
                this.isLoading = false;
            })
        },
        searchData(val) {
            this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
            this.axios.get('api/asset/search/' + val).then((response) => {
                this.items = response.data
            }).catch(error => {

                console.log(error)

            }).finally(() => {

            })
        },
        pageChange: function (e) {
            this.currentPage = e;
            this.getData()
        },

        clearform: function () {
                this.modal = false
                this.$nextTick(() => {
                    this.form = Object.assign({}, this.defaultForm);
                    this.editIndex = -1;
                });
            }

            ,
        save() {
            this.isLoading = true;
            if (this.editIndex > -1) {
                let formData = new FormData();

                formData.append('id', this.form.id);
                formData.append('title', this.form.title);
                formData.append('address', this.form.address);
                formData.append('city', this.form.city);
                formData.append('wide', this.form.wide);
                formData.append('price', this.form.price);
                formData.append('pic', this.form.pic);
                formData.append('phone', this.form.phone);
                formData.append('legal_no', this.form.legal_no);
                formData.append('facility', this.form.facility);

                if (this.form.images.length > 0) {
                    for (let i = 0; i < this.form.images.length; i++) {
                        formData.append('images[]', this.form.images[i]);
                    }
                }

                this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
                this.axios.post('api/asset/update', formData).then(() => {
                    this.getData();
                    this.modal = false
                    this.clearform();
                    this.$notify({
                        title: "Notification",
                        type: "success",
                        text: "Data Updated Successfully"
                    });
                }).catch(error => {
                    console.log(error)
                }).finally(() => {
                    this.isLoading = false;
                })
            } else {
                let formData = new FormData();
                formData.append('title', this.form.title);
                formData.append('address', this.form.address);
                formData.append('city', this.form.city);
                formData.append('wide', this.form.wide);
                formData.append('legal_no', this.form.legal_no);
                formData.append('facility', this.form.facility);
                formData.append('price', this.form.price);
                formData.append('pic', this.form.pic);
                formData.append('phone', this.form.phone);
                for (let i = 0; i < this.form.images.length; i++) {
                    formData.append('images[]', this.form.images[i]);
                }
                this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
                this.axios.post('api/asset', formData).then((response) => {
                    this.getData();
                    this.clearform()
                    this.$notify({
                        title: "Notification",
                        type: "success",
                        text: "Data Created Successfully"
                    });
                }).catch(error => {
                    console.log(error)
                }).finally(() => {
                    this.isLoading = false;
                })
            }
        },
        deleteData() {
            this.isLoading = true;
            this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
            this.axios.delete('api/asset/' + this.deleteID).then(() => {
                this.getData()
                this.deleteID = null
                this.deletemodal = false;
                this.clearform();
                this.$notify({
                    title: "Notification",
                    type: "success",
                    text: "Data Deleted Successfully"
                });
            }).catch(error => {

                console.log(error)

            }).finally(() => {
                this.isLoading = false;
            })
        }
    },
    components: {
        quillEditor,
        DataTable,
        FormBase,
        Confirm,
        LButton
    },
    setup() {
        const state = reactive({
            dynamicComponent: null,
            content: '',
            _content: '',
            editorOption: {
                placeholder: 'Detail Here',
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'],
                        ['blockquote', 'code-block'],
                        [{ header: 1 }, { header: 2 }],
                        [{ list: 'ordered' }, { list: 'bullet' }],
                        [{ script: 'sub' }, { script: 'super' }],
                        [{ indent: '-1' }, { indent: '+1' }],
                        [{ direction: 'rtl' }],
                        [{ size: ['small', false, 'large', 'huge'] }],
                        [{ header: [1, 2, 3, 4, 5, 6, false] }],
                        [{ color: [] }, { background: [] }],
                        [{ font: [] }],
                        [{ align: [] }],
                        ['clean'],
                        ['link', 'image', 'video']
                    ]
                    // other moudle options here
                }
                // more options
            },
            disabled: false
        })

        const onEditorBlur = quill => {
            console.log('editor blur!', quill)
        }
        const onEditorFocus = quill => {
            console.log('editor focus!', quill)
        }
        const onEditorReady = quill => {
            console.log('editor ready!', quill)
        }
        const onEditorChange = ({ quill, html, text }) => {
            console.log('editor change!', quill, html, text)
            state._content = html
        }

        setTimeout(() => {
            state.disabled = true
        }, 2000)

        return { state, onEditorBlur, onEditorFocus, onEditorReady, onEditorChange }
    },
    computed: {
        title() {
            if (this.editIndex > -1) {
                return 'Edit Item'
            } else {
                return 'New Item'
            }
        }
    }
};
</script>

<style lang="scss" scoped>

</style>
