<template>
	<div class="navigation">
		<div class="tab hidden lg:flex mb-1">
			<div v-for="(tab,index) in tabs" :key="index" @click="settab(index, tab.initial)" class="tab-item flex mr-1 py-4 px-4 cursor-pointer border dark:border-0 border-gray-300 dark:border-gray-900 rounded-sm shadow-sm" :class=" currentTab == index ? 'bg-primary dark:bg-secondary text-white':'dark:bg-gray-900 text-primary dark:text-gray-200' ">
				 <mdicon :name="tab.icon" size="20" class="mr-1"/> <div  class="tab-link text-sm font-bold">{{ tab.name }} </div>
			</div>
		</div>

		<select name="tab-mobile" class="block lg:hidden bg-primary dark:bg-gray-900 text-white text-sm font-bold px-2 py-2 mb-2 w-full focus:outline-none" id="" @change="changetab($event)">
			<option v-for="tab in tabs" :key="tab.initial" :value="tab.initial" >
				{{ tab.name }}
			</option>
		</select>
		<slot></slot>
	</div>
</template>

<script>
export default {

  name: 'Tabnav',
  props:{
  	tabs:{
  		type: Array,
  		required: true
  	},
  	selected:{
  		type: String,
  		required: true
  	}

  },

  methods:{
	changetab(event){
		this.$emit('selected', event.target.value);
	},
	settab(e,tab){
		this.currentTab = e
		this.$emit('selected', tab);
	}
  },

mounted(){

},
  data () {
    return {
		currentTab: 0,
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/main.scss";
</style>