<template>
    <div>
        <VisitorTableVue></VisitorTableVue>
    </div>
</template>

<script>
import VisitorTableVue from '@/components/Admin/Component/VisitorTable.vue';
export default {
    name: 'PadmawebVisitorsView',

    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
    components:{
        VisitorTableVue
    }
};
</script>

<style lang="scss" scoped>

</style>