<template>
    <div>
        <ParameterComponentVue></ParameterComponentVue>
    </div>
</template>

<script>
import ParameterComponentVue from '@/components/Admin/Component/ParameterComponent/ParameterComponent.vue';
export default {
    name: 'PadmawebParameterView',

    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
    components:{
        ParameterComponentVue
    }
    
};
</script>

<style lang="scss" scoped>

</style>