<template>
    <div>
        <v-loading v-if="isLoading"></v-loading>
        <div class="bg-gray-100 dark:bg-gray-900 rounded shadow w-full py-8 px-5 h-[550px]">
            <div class="flex w-full justify-between items-center">
                <div class="bg-transparent w-full">
                    <select v-model="selectedRole" @change="changeRole" class="bg-transparent focus:outline-none border border-gray-200 px-4 py-2 rounded">
                        <option v-for="r in role" :key="r.id" :value="r.id">{{ r.role }}</option>
                    </select>
                </div>
                <div class="flex w-full justify-end items-center">
                    <div class="mr-2">
                        <button @click="checkAll" class="bg-gray-500 focus:outline-none rounded px-2 py-1 text-gray-100" color="primary"  elevation="0" v-if="isUpdatePermitted"> {{ isCheckAll ? 'Uncheck' : 'Check' }}</button>
                    </div>
                    <div class="mr-2">
                        <button small color="primary" class="bg-primary focus:outline-none rounded px-2 py-1 text-gray-100" @click="save"  elevation="0" v-if="isUpdatePermitted">Save</button>
                    </div>
                    <div class="mr-2">
                        <button small color="error" class="bg-secondary focus:outline-none rounded px-2 py-1 text-gray-100" @click="reset" elevation="0" v-if="isUpdatePermitted">Reset</button>
                    </div>
               </div>	
            </div>
            <div class="overflow-y-auto h-[430px] mt-5 px-5 border border-gray-200">
                <table class="table-auto w-full border-collapse">
                    <thead>
                        <tr class="border-b border-gray-200 text-xs font-bold text-gray-700">
                            <td class="py-5">Icon</td>
                            <td class="py-5">Title</td>
                            <td class="py-5">Route</td>
                            <td class="py-5">Create</td>
                            <td class="py-5">Read</td>
                            <td class="py-5">Update</td>
                            <td class="py-5">Delete</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="p in permissions" :key="p" class="border-b border-gray-200 last:border-b-0 text-sm font-normal text-gray-700">
                            <td class="py-4"><mdicon :name="p.icon" size="20" class="text-gray-800"/></td>
                            <td class="py-4">{{p.title}}</td>
                            <td class="py-4">{{p.route}}</td>
                            <td class="py-4"><input type="checkbox" name="" id="" v-model="p.create" :disabled="!isUpdatePermitted"></td>
                            <td class="py-4"><input type="checkbox" name="" id="" v-model="p.read" :disabled="!isUpdatePermitted"></td>
                            <td class="py-4"><input type="checkbox" name="" id="" v-model="p.update" :disabled="!isUpdatePermitted"></td>
                            <td class="py-4"><input type="checkbox" name="" id="" v-model="p.delete" :disabled="!isUpdatePermitted"></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            
        </div>
    </div>
</template>

<script>
export default {
    name: 'PadmawebPermissionTable',

    data() {
        return {
            isLoading : false,
            checkbox : true,
			permissions : [],
			defaultpermissions:[], 
			role: {},
			selectedRole:null,
			isCheckAll:false,
        };
    },

    mounted() {
        this.getmodule()
        this.getPermission()
       
    },

    methods: {
        getPermission(){
            this.isLoading = true;

            this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
            this.axios.get('api/getpermissions').then((response) => {
                this.role = response.data.role;	
                if(response.data.role.length){
					this.selectedRole = response.data.role[0].id
					if(response.data.role[0].permissions){
						this.isCheckAll = true
						this.permissions = JSON.parse(response.data.role[0].permissions);
					}					
				}
            }).catch(error => {
               
              console.log(error)

            }).finally(() => {
                this.isLoading = false;

            })
        },

        changeRole(){
			let index = this.role.findIndex(role => role.id == this.selectedRole)
			let permission = this.role[index].permissions
			if(!permission){
				this.isCheckAll = false
        		this.permissions = this.defaultpermissions
        	}else{
        		this.isCheckAll = true
        		this.permissions = JSON.parse(permission);
        	}
		},

		checkAll(){
			this.isCheckAll = !this.isCheckAll

            for(let rs of this.permissions){

                rs.read = this.isCheckAll;
                rs.create = this.isCheckAll;
                rs.update = this.isCheckAll;
                rs.delete = this.isCheckAll;
            }
		},
		reset(){
            this.getmodule()
			this.isCheckAll = false

            this.permissions = this.defaultpermissions
		},
		save(){
            this.isLoading = true;

			let data = JSON.stringify(this.permissions)
			 this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
			 

            this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
            this.axios.post('api/assignpermission', {'permissions' : data, 'id': this.selectedRole}).then(() => {
                this.getPermission();
            }).catch(error => {                
                console.log(error)
            }).finally(() => {
                this.isLoading = false;
            })
             
		},

        getmodule:function(){
			this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
			this.axios.get('api/modulesall').then(response => {
				this.defaultpermissions = response.data
			}).catch(error => {
				console.log(error)
			}).finally(() => {
				this.loadingdata = false
			})
		}
    }
};
</script>

<style lang="scss" scoped>

</style>