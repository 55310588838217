import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/tailwind.css'

import mdiVue from 'mdi-vue/v3'
import * as mdijs from '@mdi/js'
import VueSocialSharing from 'vue-social-sharing'
import VueSignaturePad from 'vue-signature-pad';
import VueNumberFormat from 'vue-number-format'

import Notifications from '@kyvg/vue3-notification'

import axios from 'axios';
import VueAxios from 'vue-axios';

import vLoading from '@/components/v-loading.vue';




import common from './plugin/common'
export const masterSiteKey = '6LcZyHwpAAAAAEfdMkSzkW2pehLTIKDrE3KVHFop';
// axios.defaults.baseURL = 'http://127.0.0.1:8000'
axios.defaults.baseURL = 'https://api.bankpadma.id'
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.withCredentials = true;



createApp(App).component('vLoading',vLoading).use(store).mixin(common).use(VueAxios, axios).use(VueNumberFormat).use(Notifications).use(VueSocialSharing).use(VueSignaturePad).use(router).use(mdiVue, {
    icons: mdijs
  }).mount('#app')
