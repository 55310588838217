<template>
    <div class="font-Poppins bg-white dark:bg-gray-800" :class=" isDark ? 'dark' : '' ">
        <preloaderVue v-if="preloader"></preloaderVue>
        <chatVue></chatVue>
        <FrontNav :dark="isDark" @darkmode="changedarkMode"></FrontNav>
        <slot/>
        <Footer :dark="isDark"></Footer>
    </div>
</template>

<script>
import chatVue from './chat.vue';
import preloaderVue from './preloader.vue';
import store from '@/store';
import FrontNav from './front-nav.vue'
import Footer from './footer.vue'
export default {
    name: 'ClientFrontLayout',
    data() {
        return {
          isDark : false,
        };
    },

    mounted() {
      this.darkmode()  
      
    },
    created(){
        this.getparam()
        this.getgeneral()
    },

    methods: {
        changedarkMode(){
            this.isDark = !this.isDark
        },


        darkmode(){
            if (window.matchMedia && 
                    window.matchMedia('(prefers-color-scheme: dark)').matches) {
                    this.isDark = true
            } else {
                this.isDark = false
            }
        },
         getparam(){
            this.axios.get('api/getcompany').then((response) => {
                store.commit("ChangeCompany", response.data)
                
            }).catch(error => {
               
              console.log(error)

            }).finally(() => {
                
            })
        },
         getgeneral(){
            this.axios.get('api/getgeneral').then((response) => {
                store.commit("ChangeGeneral", response.data)
            }).catch(error => {
               
              console.log(error)

            }).finally(() => {
                
            })
        }
    },
    components:{
        FrontNav,
        Footer,
        preloaderVue,
        chatVue
    },
    computed:{
        preloader(){
            return store.getters.getLoader
        }
    }
};
</script>

<style lang="scss" scoped>
</style>