<template>
    <div>
        <CompanyComponentVue></CompanyComponentVue>
    </div>
</template>

<script>
import CompanyComponentVue from '@/components/Admin/Component/CompanyComponent/CompanyComponent.vue';
export default {
    name: 'PadmawebCompanyView',

    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
    components:{
        CompanyComponentVue
    }
};
</script>

<style lang="scss" scoped>

</style>