<template>
    <div>
        <v-loading v-if="isLoading"></v-loading>
        <div class="bg-gray-100 dark:bg-gray-900 w-full shadow rounded-md p-4 h-[520px] overflow-hidden">
            <div class="flex">
                <button class="text-sm font-medium focus:outline-none active:translate-y-[2px] duration-200 bg-primary inline-block relative h-8 rounded shadow w-16 text-center text-gray-100 mb-2 mr-2 float" @click="addModal" v-if="isCreatePermitted">Add</button>
                <button class="text-sm font-medium focus:outline-none active:translate-y-[2px] duration-200 bg-secondary inline-block relative h-8 rounded shadow w-16 text-center text-gray-100 mb-2 mr-2 float" @click="editModal()" v-if="isUpdatePermitted">Edit</button>
                <button class="text-sm font-medium focus:outline-none active:translate-y-[2px] duration-200 bg-danger inline-block relative h-8 rounded shadow w-16 text-center text-gray-100 mb-2 mr-2 float" @click="deleteModal()" v-if="isDeletePermitted">Delete</button>
            </div>
            <div class="border w-full h-[450px] overflow-auto">
                <div class="grid grid-cols-2 gap-5 p-2 text-sm text-gray-500 font-medium text-justify" v-if="items">
                    <div>
                        <div class="text-2xl font-semibold mb-3">
                            History
                        </div>
                        <div v-html="items.history">

                        </div>
                    </div>

                    <div>
                        <div class="text-2xl font-semibold mb-3">
                            Vision
                        </div>
                        <div v-html="items.vision">

                        </div>
                    </div>
                    <div>
                        <div class="text-2xl font-semibold mb-3">
                            Description
                        </div>
                        <div>
                            {{items.description}}
                        </div>
                    </div>
                    <div>
                        <div class="text-2xl font-semibold mb-3">
                            Structure
                        </div>
                        <div class="w-full">
                            <img :src="items.structurepath" alt="Struktur Organisasi Bank Padma" class="w-full object-cover object-center">
                        </div>  
                    </div>
                    <div>
                        <div class="text-2xl font-semibold mb-3">
                            Phone
                        </div>
                        <div>
                            {{items.phone}}
                        </div>
                    </div>
                    <div>
                        <div class="text-2xl font-semibold mb-3">
                            Address
                        </div>
                        <div>
                            {{items.address}}
                        </div>
                    </div>
                    <div>
                        <div class="text-2xl font-semibold mb-3">
                            Video Title
                        </div>
                        <div>
                            {{items.videotitle}}
                        </div>
                    </div>
                    <div>
                        <div class="text-2xl font-semibold mb-3">
                            Video Path
                        </div>
                        <div>
                            {{items.videopath}}
                        </div>
                    </div>
                    <div>
                        <div class="text-2xl font-semibold mb-3">
                            Video Description
                        </div>
                        <div>
                            {{items.videodescription}}
                        </div>
                    </div>

                    <div>
                        <div class="text-2xl font-semibold mb-3">
                            Produk Header
                        </div>
                        <div>
                            {{items.prodhead}}
                        </div>
                    </div>
                    <div>
                        <div class="text-2xl font-semibold mb-3">
                            Produk Sutitle
                        </div>
                        <div>
                            {{items.prodsub}}
                        </div>
                    </div>
                    
                </div>
            
            </div>
        </div>

         <LargeFormBaseVue v-if="modal" @closeModal="modal = !modal">
            <template v-slot:formName>
               {{title}}
            </template>
            <template v-slot:main>
               <div class="grid gap-5 grid-cols-1">
                   <div class="left">
                       <div class="mb-5">
                            <div class="text-sm font-bold text-gray-500 mb-2">History</div>
                            <quill-editor
                                    v-model:value="form.history"
                                    :options="state.editorOption"
                                />
                        </div>

                        <div class="mb-5">
                        <div class="text-sm font-bold text-gray-500 mb-2">Vision</div>                        
                        <quill-editor
                                v-model:value="form.vision"
                                :options="state.editorOption"
                            />
                        </div>

                         <div class="form-group mb-2">
                                <label for="title" class="text-sm font-bold text-gray-500">Description</label>
                                <textarea name="" id="" cols="30" rows="10" class="w-full border-b border-gray-400 dark:border-gray-800 dark:text-gray-100 bg-transparent focus:outline-none py-2 px-2 text-sm font-normal text-gray-700" v-model="form.description"></textarea>
                        </div> 
                        <div class="form-group mb-2">
                            <label for="phone" class="text-sm font-bold text-gray-500">Phone</label>
                            <input type="text" class="w-full border-b border-gray-400 dark:border-gray-800 dark:text-gray-100 bg-transparent focus:outline-none py-2 px-2 text-sm font-normal text-gray-700" v-model="form.phone">
                        </div>
                         <div class="form-group mb-2">
                                <label for="title" class="text-sm font-bold text-gray-500">Address</label>
                                <textarea name="" id="" cols="30" rows="10" class="w-full border-b border-gray-400 dark:border-gray-800 dark:text-gray-100 bg-transparent focus:outline-none py-2 px-2 text-sm font-normal text-gray-700" v-model="form.address"></textarea>
                        </div> 
                        

                         <div class="text-sm font-bold text-gray-500 mb-2">Structure</div>
                         <div class="mr-3 mb-2">
                            <button class="border-gray-400 border-[1px] items-center flex text-gray-500 px-3 py-2 rounded-sm text-xs font-medium" onclick="document.getElementById('fileImage').click()"><mdicon name="paperclip" size="15" class="text-gray-500 " /> <span>{{ form.structure == null ? 'Upload Image' : 'Image Uploaded' }}</span></button>
                            <input type='file' id="fileImage" style="display:none" @change="getImage"> 
                        </div>

                        <div class="form-group mb-2">
                            <label for="phone" class="text-sm font-bold text-gray-500">Videopath</label>
                            <input type="text" class="w-full border-b border-gray-400 dark:border-gray-800 dark:text-gray-100 bg-transparent focus:outline-none py-2 px-2 text-sm font-normal text-gray-700" v-model="form.videopath">
                        </div>

                        <div class="form-group mb-2">
                            <label for="phone" class="text-sm font-bold text-gray-500">Video Title</label>
                            <input type="text" class="w-full border-b border-gray-400 dark:border-gray-800 dark:text-gray-100 bg-transparent focus:outline-none py-2 px-2 text-sm font-normal text-gray-700" v-model="form.videotitle">
                        </div>

                        <div class="form-group mb-2">
                                <label for="title" class="text-sm font-bold text-gray-500">Videodescription</label>
                                <textarea name="" id="" cols="30" rows="10" class="w-full border-b border-gray-400 dark:border-gray-800 dark:text-gray-100 bg-transparent focus:outline-none py-2 px-2 text-sm font-normal text-gray-700" v-model="form.videodescription"></textarea>
                        </div> 

                        <div class="form-group mb-2">
                            <label for="phone" class="text-sm font-bold text-gray-500">Produk Header</label>
                            <input type="text" class="w-full border-b border-gray-400 dark:border-gray-800 dark:text-gray-100 bg-transparent focus:outline-none py-2 px-2 text-sm font-normal text-gray-700" v-model="form.prodhead">
                        </div>

                        <div class="form-group mb-2">
                            <label for="phone" class="text-sm font-bold text-gray-500">Produk Subtitle</label>
                            <input type="text" class="w-full border-b border-gray-400 dark:border-gray-800 dark:text-gray-100 bg-transparent focus:outline-none py-2 px-2 text-sm font-normal text-gray-700" v-model="form.prodsub">
                        </div>

                        

                        <div class="flex mt-10">
                            <LButton class="text-white bg-secondary mr-2" :class=" isloading ? 'pointer-events-none' : 'cursor-pointer'" :disabled="isloading" @click="save">
                                {{ isloading ? 'Loading...' : 'Save' }}
                            </LButton>   
                           <LButton class="bg-gray-300 text-gray-800">
                            Cancel
                            </LButton>                            
                        </div>                 
                   </div>
               </div>
            </template>
       </LargeFormBaseVue>

        <Transition name="fade">
            <Confirm v-if="deletemodal" @closeconfirm="deletemodal = false">
                <template v-slot:header>
                    Confirm
                </template>
                <template v-slot:main>
                    Are You Sure You Want To Delete This Data ?
                </template>
                <template v-slot:footer>
                    <div class="flex justify-end items-center">
                        <LButton class="bg-gray-300 text-gray-800 mr-2" @click="deletemodal = false">
                            Cancel
                        </LButton>
                        <LButton class="text-white bg-secondary" :class=" isloading ? 'pointer-events-none' : 'cursor-pointer'" :disabled="isloading" @click="deleteData">
                            {{ isloading ? 'Loading...' : 'Confirm' }}
                        </LButton>                            
                    </div>
                </template>
            </Confirm>  
        </Transition>
   </div>
</template>

<script>
import LButton from '@/layouts/button.vue'
import LargeFormBaseVue from '@/layouts/LargeFormBase.vue'
import Confirm from '@/layouts/confirm.vue'
import { reactive } from 'vue'
import { quillEditor } from 'vue3-quill'

export default {
    name: 'PadmawebCompanyTable',

    data() {
        return {
            items: {},
            modal:false,
            editIndex : -1,
            isLoading:false,
            deletemodal: false,
            deleteID: null,
            form:{
                history:'',
                vision:'',
                structure: null,
                description:'',
                phone:'',
                address:'',
                videopath:'',
                videotitle:'',
                videodescription:'',
                prodhead:'',
                prodsub:'',
            },
            defaultform:{
                history:'',
                vision:'',
                structure: null,
                description:'',
                phone:'',
                address:'',
                videopath:'',
                videotitle:'',
                videodescription:'',
                prodhead:'',
                prodsub:'',
            }
        };
    },

    mounted() {
        this.getData()
    },

    methods: {
        getData(){
            this.isLoading = true;

           this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
            this.axios.get('api/company').then((response) => {
                this.items = response.data[0]
            }).catch(error => {
               
              console.log(error)

            }).finally(() => {
                this.isLoading = false;

            })
        },
         addModal(){
            this.editIndex = -1
            this.modal = !this.modal
        },
        getImage(event){
           this.form.structure = event.target.files[0];
        },
        deleteModal(){
            this.deleteID = this.items.id
            this.deletemodal = true
        },
        editModal(){
            this.editIndex = 1

            this.form.id = this.items.id
            this.form.history = this.items.history
            this.form.vision = this.items.vision   
            this.form.description = this.items.description         
            this.form.phone = this.items.phone   
            this.form.address = this.items.address   
            this.form.videopath = this.items.videopath         
            this.form.videodescription = this.items.videodescription   
            this.form.prodhead = this.items.prodhead   
            this.form.videotitle = this.items.videotitle   
            this.form.prodsub = this.items.prodsub   

            this.modal = true
        },
        clearform: function() {
            this.modal = false
            this.$nextTick(() => {
                this.form = Object.assign({}, this.defaultForm);
                this.editIndex = -1;
            });
        },
        save(){
            this.isLoading = true;

            if(this.editIndex > -1){
                let formData = new FormData();
                if( this.form.structure !== null){
					formData.append('structure', this.form.structure);
				}
                formData.append('id', this.form.id);		
                formData.append('history', this.form.history);		
				formData.append('vision', this.form.vision);
                formData.append('description', this.form.description);
                formData.append('phone', this.form.phone);
                formData.append('address', this.form.address);
                formData.append('videopath', this.form.videopath);
                formData.append('videodescription', this.form.videodescription);
                formData.append('prodhead', this.form.prodhead);
                formData.append('videotitle', this.form.videotitle);
                formData.append('prodsub', this.form.prodsub);

                    this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
                    this.axios.post('/api/company/update',formData).then(() => {
                    this.getData();
                    this.clearform()
                    this.$notify({
                        title: "Notification",
                        type: "success",
                        text: "Data Updated Successfully"
                    });
                }).catch(error => {                
                    console.log(error)
                }).finally(() => {
                    this.isLoading = false;

                })
            }else{
                
                let formData = new FormData();
                formData.append('history', this.form.history);		
				formData.append('vision', this.form.vision);
                formData.append('description', this.form.description);
                formData.append('phone', this.form.phone);
                formData.append('address', this.form.address);
                formData.append('videopath', this.form.videopath);
                formData.append('videodescription', this.form.videodescription);
                formData.append('prodhead', this.form.prodhead);
                formData.append('videotitle', this.form.videotitle);
                formData.append('prodsub', this.form.prodsub);
                formData.append('structure', this.form.structure);
                    this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
                    this.axios.post('/api/company',formData).then((response) => {
                    this.getData();
                    this.clearform()

                    if(response.status == 200){
                        this.$notify({
                            title: "Notification",
                            type: "success",
                            text: "Data Created Successfully"
                        });
                    } else if(response.status == 201){
                        this.$notify({
                            title: "Notification",
                            type: "error",
                            text: response.data.msg
                        });

                        console.log(response)
                    }
                  
                    
                }).catch(error => {                
                    console.log(error)
                }).finally(() => {
                    this.isLoading = false;

                })
            }
        },
        deleteData(){
            this.isLoading = true;

            this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
            this.axios.delete('api/company/'+this.deleteID).then(() => {
                this.getData()
                this.deleteID = null
                this.deletemodal = false
            }).catch(error => {
               
              console.log(error)

            }).finally(() => {
                this.isLoading = false;

                
            })
        }
    },
    components:{
        LargeFormBaseVue,
        quillEditor ,
        Confirm,
        LButton
    },
    computed:{
        title(){
            if(this.editIndex > -1){
                return 'Edit Item'
            }else{
                return 'New Item'
            }
        }
    },
      setup() {
        const state = reactive({
        dynamicComponent: null,
        content: '',
        _content: '',
        editorOption: {
            placeholder: 'Detail Here',
            modules: {
            toolbar: [
                ['bold', 'italic', 'underline', 'strike'],
                ['blockquote', 'code-block'],
                [{ header: 1 }, { header: 2 }],
                [{ list: 'ordered' }, { list: 'bullet' }],
                [{ script: 'sub' }, { script: 'super' }],
                [{ indent: '-1' }, { indent: '+1' }],
                [{ direction: 'rtl' }],
                [{ size: ['small', false, 'large', 'huge'] }],
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                [{ color: [] }, { background: [] }],
                [{ font: [] }],
                [{ align: [] }],
                ['clean'],
                ['link', 'image', 'video']
            ]
            // other moudle options here
            }
            // more options
        },
        disabled: false
        })

        const onEditorBlur = quill => {
        console.log('editor blur!', quill)
        }
        const onEditorFocus = quill => {
        console.log('editor focus!', quill)
        }
        const onEditorReady = quill => {
        console.log('editor ready!', quill)
        }
        const onEditorChange = ({ quill, html, text }) => {
        console.log('editor change!', quill, html, text)
        state._content = html
        }

        setTimeout(() => {
        state.disabled = true
        }, 2000)

        return { state, onEditorBlur, onEditorFocus, onEditorReady, onEditorChange }
    }
};
</script>

<style lang="scss" scoped>

</style>