<template>
<section class="h-auto w-full border-t-2 bg-white dark:bg-gray-800 border-t-secondary flex flex-col justify-center items-start md:items-center">
    <div class="container-y footer-top">
        <div class="py-4 lg:py-10 w-full grid gap-y-5 md:gap-10 grid-cols-1 md:grid-cols-2 lg:grid-cols-4 h-full">
            <div class="footer-content h-full w-full">
                <div class="logo-group mb-6">
                    <div class="text-base text-primary dark:text-gray-100 font-bold mb-3">
                        BPR Padma Berizin dan Diawasi oleh Otoritas Jasa Keuangan
                    </div>
                </div>
                <div class="logo-group">
                    <div class="text-base text-primary dark:text-gray-100 font-bold mb-3">
                        BPR Padma Merupakan Bank Peserta Penjaminan LPS
                    </div>
                </div>
            </div>
            <div class="footer-content h-full w-full">
                <div class="office-group h-full flex flex-col items-start justify-start text-gray-600">
                    <div class="text-base font-bold text-primary dark:text-gray-100 mb-1 lg:mb-2">
                        Kantor Pusat
                    </div>
                    <p class="text-sm font-normal mb-1 leading-loose lg:mb-2 dark:text-gray-100" v-if="company.length">
                        {{company[0].address}}
                    </p>
                    <div class="text-base font-bold text-primary dark:text-gray-100 mb-1">
                        Telepon
                    </div>
                    <p class="text-sm font-normal dark:text-gray-100" v-if="company.length">
                        {{company[0].phone}}
                    </p>
                </div>
            </div>
            <div class="footer-content h-full w-full">
                <div class="tautan-group">
                    <div class="text-base font-bold text-primary dark:text-gray-100 mb-1 lg:mb-2">Tautan Langsung</div>
                    <div class="text-sm flex flex-row mb-1 lg:mb-2" v-for="(nav,i) in navs" :key="i">
                        <mdicon name="square-small" size="20" class="mr-1 text-gray-900 dark:text-gray-200" />
                        <router-link :to="nav.link" class="text-primary dark:text-gray-100 hover:text-secondary duration-300">{{nav.name}}</router-link>
                    </div>
                </div>
            </div>
            <div class="footer-content h-full w-full">
                <div class="social-head text-base font-bold text-primary dark:text-gray-100 mb-1  lg:mb-2">
                    Media Sosial Kami
                </div>
                <div class="social-media flex flex-row">
                    <a v-for="(s,i) in sosmeds" :key="i" :href="s.url" target="_blank" class="mr-1 text-primary dark:text-gray-100 hover:text-secondary duration-300">
                        <mdicon :name="s.icon" size="25" /></a>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-botom h-10 w-full bg-primary  dark:bg-gray-900">
        <div class="container-y text-white text-sm font-light leading-10">
            &copy; 2022 PT.BPR Padma
        </div>
    </div>
</section>
</template>

<script>
import store from '@/store';
export default {
    name: 'ClientFooter',
    props: ['dark'],
    data() {
        return {
            navs: [{
                    name: 'Tabungan',
                    link: '/tabungan'
                },
                {
                    name: 'Deposito',
                    link: '/deposito'
                },
                {
                    name: 'Kredit',
                    link: '/kredit'
                },
                {
                    name: 'Layanan',
                    link: '/layanan'
                },
                {
                    name: 'Suku Bunga',
                    link: '/suku-bunga'
                },
                {
                    name: 'Blog',
                    link: '/blog'
                }
            ],
            sosmeds: []
        };
    },

    mounted() {
        this.getsosmed()
    },

    methods: {
        getsosmed() {
            this.axios.get('api/getsosmed').then((response) => {
                this.sosmeds = response.data
            }).catch(error => {

                console.log(error)

            }).finally(() => {

            })
        }
    },
    computed: {
        company() {
            return store.state.company
        }
    }
};
</script>

<style lang="scss" scoped>
</style>
