<template>
    <div>
        <v-loading v-if="isLoading"></v-loading>
       <DataTable :datatable="items" :fixed="false" :header="headers" @page-changed="pageChange($event)">
           <template v-slot:table-slot>
               <div class="grid w-full grid-cols-[5fr_1fr_1fr] gap-5">
                   <form @submit.prevent action="" class="w-full">
                       <div class="flex border-b-2 bg-gray-100 border-gray-300 dark:bg-gray-900 dark:text-gray-100 dark:border-gray-800 focus:outline-none py-1 mb-4 w-full text-gray-700 font-medium">
                           <input type="text" class="focus:outline-none bg-transparent w-full" placeholder="Search..." v-model="search">
                           <mdicon name="magnify" size="28" class="mr-1 text-gray-700 dark:text-gray-100"/>
                       </div>
                    </form>

                    <div class="flex border-b-2 bg-gray-100 border-gray-300 dark:bg-gray-900 dark:text-gray-100 dark:border-gray-800 focus:outline-none py-1 mb-4 w-full text-gray-700 font-medium">
                        <select name="" id="" class="focus:outline-none bg-transparent w-full text-sm text-gray-500 font-medium" v-model="typesearch">
                            <option value="">All</option>
                            <option value="new">new</option>
                            <option value="process">process</option>
                            <option value="rejected">rejected</option>
                            <option value="approved">approved</option>
                        </select>
                    </div>

                    <JsonCSV :data="items.data" :csv-title="'Data Pengajuan Kredit'">
                        <button class="bg-primary text-white px-3 py-2 rounded-sm text-xs font-weight">Download CSV</button>
                    </JsonCSV>    
                </div>
           </template>

           <template v-slot:action="item">
                <div class="flex">
                    <button class="flex justify-center items-center bg-primary  px-2 py-1 text-gray-100 rounded text-xs font-medium focus:outline-none" @click="editModal(item)" v-if="isUpdatePermitted"><mdicon name="gesture-double-tap" class="text-gray-100 mr-[2px]" size="15"/></button>
                    <button class="flex justify-center items-center bg-secondary mx-1 px-2 py-1 text-gray-100 rounded text-xs font-medium focus:outline-none" @click="deleteModal(item)" v-if="isDeletePermitted"><mdicon name="trash-can-outline" class="text-gray-100 mr-[2px]" size="15"/></button>
                    <button class="flex justify-center items-center bg-danger px-2 mr-1 py-1 text-gray-100 rounded text-xs font-medium focus:outline-none" @click="generateReport(item)" v-if="isUpdatePermitted"><mdicon name="file" class="text-gray-100 mr-[2px]" size="15"/></button>
                    <button class="flex justify-center items-center bg-gray-900 px-2 py-1 text-gray-100 rounded text-xs font-medium focus:outline-none" @click="downloadimage(item)" v-if="isUpdatePermitted"><mdicon name="paperclip" class="text-gray-100 mr-[2px]" size="15"/></button>
               </div>
           </template>

           <template v-slot:chip="item">
                <div class="text-white text-center font-medium text-xs">
                    <div class="bg-gray-700 p-1 rounded-full" v-if="item.item == 'new'">
                         {{item.item}}
                    </div>
                    <div class="bg-danger p-1 rounded-full" v-if="item.item == 'rejected'">
                         {{item.item}}
                    </div>
                    <div class="bg-secondary p-1 rounded-full" v-if="item.item == 'process'">
                         {{item.item}}
                    </div>
                    <div class="bg-primary p-1 rounded-full" v-if="item.item == 'approved'">
                         {{item.item}}
                    </div>
                </div>              
           </template>
       </DataTable>
       <LargeFormBaseVue v-if="modal" @closeModal="modal = !modal">
            <template v-slot:formName>
               {{title}}
            </template>
            <template v-slot:main>
               <div>
                    <table class="table-auto w-full border border-gray-300 text-sm font-medium">
                        <tr>
                            <td class="border py-2 px-2 border-gray-300 bg-primary w-full text-white" colspan="2"> Data Pemohon</td>
                        </tr>
                        <tr class="w-full">
                            <td class="border py-2 px-2 border-gray-300 w-2/6">Nama Lengkap :</td>
                            <td class="border py-2 px-2 border-gray-300 w-full">{{form.nama}}</td>
                        </tr>
                        <tr>
                            <td class="border py-2 px-2 border-gray-300">Nomor KTP : </td>
                            <td class="border py-2 px-2 border-gray-300">{{form.ktp}}</td>
                        </tr>
                        <tr>
                            <td class="border py-2 px-2 border-gray-300">Alamat : </td>
                            <td class="border py-2 px-2 border-gray-300">{{form.alamat}}</td>
                        </tr>
                        <tr>
                            <td class="border py-2 px-2 border-gray-300">Kota : </td>
                            <td class="border py-2 px-2  border-gray-300">{{form.kota}}</td>
                        </tr>
                        <tr>
                            <td class="border py-2 px-2 border-gray-300">Status Perkawinan : </td>
                            <td class="border py-2 px-2 border-gray-300">{{form.statuskawin}}</td>
                        </tr>
                        <tr>
                            <td class="border py-2 px-2 border-gray-300">Nomor Telp/Hp : </td>
                            <td class="border py-2 px-2 border-gray-300">{{form.handphone}}</td>
                        </tr>
                        <tr>
                            <td class="border py-2 px-2 border-gray-300">Alamat Email : </td>
                            <td class="border py-2 px-2 border-gray-300">{{form.email}}</td>
                        </tr>

                        <tr>
                            <td class="border py-2 px-2 border-gray-300">Nama Gadis Ibu Kandung : </td>
                            <td class="border py-2 px-2 border-gray-300">{{form.namaibu}}</td>
                        </tr>

                        <tr>
                            <td class="border py-2 px-2 border-gray-300 bg-primary w-full text-white" colspan="2"> Data Pekerjaan dan Penghasilan</td>
                        </tr>
                        <tr>
                            <td class="border py-2 px-2 border-gray-300">Pekerjaan : </td>
                            <td class="border py-2 px-2 border-gray-300">{{form.pekerjaan}}</td>
                        </tr>
                        <tr>
                            <td class="border py-2 px-2 border-gray-300">Tempat Bekerja : </td>
                            <td class="border py-2 px-2 border-gray-300">{{form.tempatkerja}}</td>
                        </tr>

                        <tr>
                            <td class="border py-2 px-2 border-gray-300">Sumber Penghasilan : </td>
                            <td class="border py-2 px-2 border-gray-300">{{form.sumberpenghasilan}}</td>
                        </tr>
                        <tr>
                            <td class="border py-2 px-2 border-gray-300">Penghasilan Pertahun : </td>
                            <td class="border py-2 px-2 border-gray-300">{{form.penghasilan}}</td>
                        </tr>
                       
                        <tr>
                            <td class="border py-2 px-2 border-gray-300 bg-primary w-full text-white" colspan="2"> Data Permohonan Deposito</td>
                        </tr>
                         <tr class="w-full">
                            <td class="border py-2 px-2 border-gray-300 w-2/6">Jenis Deposito :</td>
                            <td class="border py-2 px-2 border-gray-300 w-full">{{form.jenisdeposito}}</td>
                        </tr>
                        <tr class="w-full">
                            <td class="border py-2 px-2 border-gray-300 w-2/6">Nominal :</td>
                            <td class="border py-2 px-2 border-gray-300 w-full">{{form.nominal}}</td>
                        </tr>
                        <tr class="w-full">
                            <td class="border py-2 px-2 border-gray-300 w-2/6">Jangka Waktu :</td>
                            <td class="border py-2 px-2 border-gray-300 w-full">{{form.jangkawaktu}}</td>
                        </tr>
                           <tr class="w-full">
                            <td class="border py-2 px-2 border-gray-300 w-2/6">Perpanjangan :</td>
                            <td class="border py-2 px-2 border-gray-300 w-full">{{form.perpanjangan}}</td>
                        </tr>
                        <tr class="w-full">
                            <td class="border py-2 px-2 border-gray-300 w-2/6">Tujuan Deposito :</td>
                            <td class="border py-2 px-2 border-gray-300 w-full">{{form.tujuan}}</td>
                        </tr>
                         <tr class="w-full">
                            <td class="border py-2 px-2 border-gray-300 w-2/6">Ahli Waris :</td>
                            <td class="border py-2 px-2 border-gray-300 w-full">{{form.ahliwaris}}</td>
                        </tr>                   
                    </table>

                    <table class="table-auto w-full border-fixed border border-gray-300 text-sm font-medium">
                         <tr>
                            <td class="border py-2 px-2 border-gray-300 bg-primary w-full text-white" colspan="2">Data Lampiran</td>
                        </tr>
                        <tr>
                            <td class="border py-2 px-2 border-gray-300 w-2/6">Foto KTP</td>
                            <td class="border py-2 px-2 border-gray-300 w-full">
                                <img :src="form.ktpdiripath" width="450" alt="">
                            </td>
                        </tr>
                         <tr>
                            <td class="border py-2 px-2 border-gray-300 w-2/6">Signature</td>
                            <td class="border py-2 px-2 border-gray-300 w-full">
                                <img :src="form.sign" width="400" alt="">
                            </td>
                        </tr>
                    </table>

                    <div class="mt-5 grid gap-10 grid-cols-2">
                        <div class="mb-10">                             
                            <div class="form-group">
                                <label for="title" class="text-sm font-bold text-gray-500">Account Officer</label>
                                <input type="text" class="w-full border-b border-gray-400 dark:border-gray-800 dark:text-gray-100 bg-transparent focus:outline-none py-2 px-2 text-sm font-normal text-gray-700" v-model="form.ao">
                            </div>         
                        </div>

                        <div class="mb-10">
                             <label for="title" class="text-sm font-bold text-gray-500">Status</label>
                            <div class="form-group">
                                <select name="" id="" v-model="form.status" class="w-full border-b border-gray-400 dark:border-gray-800 dark:text-gray-100 bg-transparent focus:outline-none py-2 px-2 text-sm font-normal text-gray-700">
                                    <option value="">Pilih</option>
                                    <option value="new">new</option>
                                    <option value="process">process</option>
                                    <option value="rejected">rejected</option>
                                    <option value="approved">approved</option>
                                </select>
                            </div>         
                        </div>

                        <div class="mb-10">
                            <LButton class="bg-gray-300 text-gray-800 mr-2" @click="modal = !modal">
                                Cancel
                            </LButton>
                            <LButton class="text-white bg-secondary" :class=" isloading ? 'pointer-events-none' : 'cursor-pointer'" :disabled="isloading" @click="save">
                                {{ isloading ? 'Loading...' : 'Process' }}
                            </LButton>  
                        </div>                        
                    </div>
               </div>
            </template>
       </LargeFormBaseVue>

      <vue3-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="false"
        :paginate-elements-by-height="1400"
        filename='Form Pembukaan Deposito'
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="legal"
        pdf-orientation="portrait"
        ref="html2Pdf"
    >
        <template  v-slot:pdf-content>
            <downloadDepositSubmissionVue :data="pdf"></downloadDepositSubmissionVue>
        </template>        
    </vue3-html2pdf>

       <Transition name="fade">
            <Confirm v-if="deletemodal" @closeconfirm="deletemodal = false">
                <template v-slot:header>
                    Confirm
                </template>
                <template v-slot:main>
                    Are You Sure You Want To Delete This Data ?
                </template>
                <template v-slot:footer>
                    <div class="flex justify-end items-center">
                        <LButton class="bg-gray-300 text-gray-800 mr-2" @click="deletemodal = false">
                            Cancel
                        </LButton>
                        <LButton class="text-white bg-secondary" :class=" isloading ? 'pointer-events-none' : 'cursor-pointer'" :disabled="isloading" @click="deleteData">
                            {{ isloading ? 'Loading...' : 'Confirm' }}
                        </LButton>                            
                    </div>
                </template>
            </Confirm>  
        </Transition>
    </div> 
</template>

<script>
import LButton from '@/layouts/button.vue'
import Confirm from '@/layouts/confirm.vue'
import DataTable from '@/layouts/DataTable.vue'
import LargeFormBaseVue from '@/layouts/LargeFormBase.vue'
import taginputVue from '@/layouts/taginput.vue'
import JsonCSV from 'vue-json-csv'

import Vue3Html2pdf from 'vue3-html2pdf'
import downloadDepositSubmissionVue from './downloadDepositSubmission.vue'

export default {
    name: 'PadmawebDepositSubmission',

    data() {
        return {
            isLoading:false,
            deletemodal: false,
            deleteID: null,
            form : {
                nama:'',
                ktp:'',
                alamat:'',
                kota:'',
                handphone:'',
                email:'',
                statuskawin:'',
                namaibu:'',
                pekerjaan:'',
                tempatbekerja:'',
                sumberpenghasilan:'',
                penghasilan:'',
                jenisdeposito:'',
                nominal:0,
                jangkawaktu:'',
                perpanjangan:'',
                tujuan:'',
                ahliwaris:'',
                term: false,
                ktpdiri:null,
                sign:'',
                ao:'',
                status:''
            },
           defaultForm : {
                 nama:'',
                ktp:'',
                alamat:'',
                kota:'',
                handphone:'',
                email:'',
                statuskawin:'',
                namaibu:'',
                pekerjaan:'',
                tempatbekerja:'',
                sumberpenghasilan:'',
                penghasilan:'',
                jenisdeposito:'',
                nominal:0,
                jangkawaktu:'',
                perpanjangan:'',
                tujuan:'',
                ahliwaris:'',
                term: false,
                ktpdiri:null,
                sign:'',
                ao:'',
                status:''
            },
            headers:[],
            items:[],
            currentPage : 1,
            modal:false,
            roles: [],
            search: '',
            typesearch: '',
            pdf:{}
        };
    },

    watch:{
        search:function(){
            if(this.search.length > 0){
                this.searchData(this.search)
            } else {
                this.getData()
            }
            
        },
        modal(val) {
			val || this.clearform();
		},
        typesearch:function(){
            if(this.typesearch.length > 0){
                this.typesearchdata(this.typesearch)
            } else {
                this.getData()
            }
            
        },
    },

    mounted() {
        this.getData()
        this.getheader()
    },

    methods: {
        addTag(val) {			
           this.form.keyword.push(val)
        },
		removeTag(index){
			 this.form.keyword.splice(index, 1)
		},
        getheader(){
            if(this.isDeletePermitted == true || this.isUpdatePermitted == true){
                this.headers = [
                   {
                    Text : 'Ticket',
                    value : 'tiket',
                    type: 'string'
                },
                {
                    Text: 'Deposit Type',
                    value: 'jenisdeposito',
                    type: 'string'
                }
                ,
                {
                    Text: 'Amount',
                    value: 'nominal',
                    type: 'string'
                }
                ,
                {
                    Text: 'Time period',
                    value: 'jangkawaktu',
                    type: 'string'
                },
                {
                    Text: 'Name',
                    value: 'nama',
                    type: 'string'
                },
                {
                    Text: 'City',
                    value: 'kota',
                    type: 'string'
                },
                {
                    Text: 'Status',
                    value: 'status',
                    type: 'chip'
                },
                {
                    Text: 'Actions',
                    action: true,
                }
                ]
            }else{
                this.headers = [
                    {
                    Text : 'Ticket',
                    value : 'tiket',
                    type: 'string'
                },
                {
                    Text: 'Loan Type',
                    value: 'jenisPinjaman',
                    type: 'string'
                }
                ,
                {
                    Text: 'Amount',
                    value: 'plafon',
                    type: 'string'
                }
                ,
                {
                    Text: 'Time period',
                    value: 'jangkaWaktu',
                    type: 'string'
                },
                {
                    Text: 'Name',
                    value: 'nama',
                    type: 'string'
                },
                {
                    Text: 'City',
                    value: 'kota',
                    type: 'string'
                },
                {
                    Text: 'Status',
                    value: 'status',
                    type: 'chip'
                }
                ]
            }
        },
        addModal(){
            this.editIndex = -1
            this.modal = !this.modal
        },
        deleteModal(val){
            this.deleteID = JSON.parse(JSON.stringify(val)).item.id
            this.deletemodal = true
        },
        editModal(val){
            let obj = JSON.parse(JSON.stringify(val)).item
            
            this.form.id = obj.id
            this.form.nama = obj.nama
            this.form.ktp = obj.ktp
            this.form.alamat = obj.alamat
            this.form.kota = obj.kota
            this.form.email = obj.email
            this.form.statuskawin = obj.statuskawin
            this.form.handphone = obj.handphone
            this.form.namaibu = obj.namaibu
            this.form.penghasilan = obj.penghasilan
            this.form.sumberpenghasilan = obj.sumberpenghasilan
            this.form.pekerjaan = obj.pekerjaan
            this.form.tempatkerja = obj.tempatkerja
            this.form.nominal = this.thousand(obj.nominal)
            this.form.jenisdeposito = obj.jenisdeposito
            this.form.tujuan = obj.tujuan
            this.form.jangkawaktu = obj.jangkawaktu
            this.form.perpanjangan = obj.perpanjangan
            this.form.ahliwaris = obj.ahliwaris
            this.form.sign = obj.sign
            this.form.ktpdiripath = obj.ktpdiripath
            this.form.status = obj.status
            this.modal = true
        },
        downloadimage(val){
            let obj = JSON.parse(JSON.stringify(val)).item
            let url = obj.ktpdiripath
            let name = obj.ktpdiri

            let urlpasangan = obj.ktppasanganpath
            let namepasangan = obj.ktppasangan

            const link = document.createElement('a')
            link.href = url           
            link.setAttribute('target', '_blank');
            link.setAttribute('download', name);
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)

            if(namepasangan.length > 0){
                const link2 = document.createElement('a')
                link2.href = urlpasangan           
                link2.setAttribute('target', '_blank');
                link2.setAttribute('download', namepasangan);
                document.body.appendChild(link2)
                link2.click()
                document.body.removeChild(link)
            }


        }
        ,
        generateReport (val) {

            let obj = JSON.parse(JSON.stringify(val)).item

            this.pdf.id = obj.id
            this.pdf.nama = obj.nama
            this.pdf.ktp = obj.ktp
            this.pdf.alamat = obj.alamat
            this.pdf.kota = obj.kota
            this.pdf.email = obj.email
            this.pdf.statuskawin = obj.statuskawin
            this.pdf.handphone = obj.handphone
            this.pdf.namaibu = obj.namaibu
            this.pdf.penghasilan = obj.penghasilan
            this.pdf.sumberpenghasilan = obj.sumberpenghasilan
            this.pdf.pekerjaan = obj.pekerjaan
            this.pdf.tempatkerja = obj.tempatkerja
            this.pdf.nominal = this.thousand(obj.nominal)
            this.pdf.jenisdeposito = obj.jenisdeposito
            this.pdf.tujuan = obj.tujuan
            this.pdf.jangkawaktu = obj.jangkawaktu
            this.pdf.perpanjangan = obj.perpanjangan
            this.pdf.ahliwaris = obj.ahliwaris
            this.pdf.sign = obj.sign
            this.pdf.ktpdiripath = obj.ktpdiripath
            this.pdf.status = obj.status       
            this.pdf.formterm = JSON.parse(obj.formterm).baseterm
            this.pdf.subterm = JSON.parse(obj.formterm).subterm  

            this.$refs.html2Pdf.generatePdf()



        },
        getImage(event){
           this.form.image = event.target.files[0];
        },
        getThumbnail(event){
           this.form.thumbnail = event.target.files[0];
        },
        changePage(e){
            this.currentPage = e;
            this.getData()
        },

        getData(){
            this.isLoading = true;

           this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
            this.axios.get(`api/getfdepositos?page=${this.currentPage}`).then((response) => {
                this.items = response.data
                console.log(this.items)
            }).catch(error => {
               
              console.log(error)

            }).finally(() => {
                this.isLoading = false;

            })
        },
        searchData(val){
            this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
            this.axios.get('api/fdepositos/search/'+val).then((response) => {
                this.items = response.data
            }).catch(error => {
               
              console.log(error)

            }).finally(() => {
                
            })
        },
        typesearchdata(val){
            this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
            this.axios.get('api/fdepositos/searchtype/'+val).then((response) => {
                this.items = response.data
            }).catch(error => {
               
              console.log(error)

            }).finally(() => {
                
            })
        },
        pageChange: function(e){
           this.currentPage = e;
           this.getData()
        },

        clearform: function() {
            this.modal = false
            this.$nextTick(() => {
                this.form = Object.assign({}, this.defaultForm);
                this.editIndex = -1;
            });
        }
           
        ,
        save(){
            this.isloading = true
            let formData = new FormData();
            formData.append('id', this.form.id);		
            formData.append('status', this.form.status);		
            formData.append('ao', this.form.ao);
                this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
                this.axios.post('/api/fdepositos/process',formData).then(() => {
                this.getData();
                this.clearform()
                this.$notify({
                    title: "Notification",
                    type: "success",
                    text: "Data Processed Successfully"
                });
            }).catch(error => {                
                console.log(error)
            }).finally(() => {
                this.isloading = false
            })

        },
        deleteData(){
            this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
            this.axios.delete('api/fdepositos/'+this.deleteID).then(() => {
                this.getData()
                this.deleteID = null
                this.deletemodal = false
            }).catch(error => {
               
              console.log(error)

            }).finally(() => {
                
            })
        },
        thousand(val){
            return 'Rp. ' + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    },
    components:{
        DataTable,
        Confirm,
        LButton,
        LargeFormBaseVue,
        taginputVue,
        Vue3Html2pdf,
        downloadDepositSubmissionVue,
        JsonCSV
    },
    computed:{
        title(){
            if(this.editIndex > -1){
                return 'Edit Item'
            }else{
                return 'New Item'
            }
        }        
    }
};
</script>

<style lang="scss" scoped>

</style>