<template>
    <div>
        <div class="tab flex justify-start items-center mb-1">
			<div v-for="(tab,index) in tabs" :key="index" @click="settab(index, tab.initial)" class="flex px-3 justify-center shadow-sm rounded-sm border-gray-300 items-center cursor-pointer py-2 mr-1" :class=" currentTab == index ? 'bg-primary text-white':'dark:bg-gray-900 bg-gray-100'">
				 <mdicon :name="tab.icon" size="17" class="mr-1"/> <div  class="tab-link text-sm font-semibold">{{ tab.name }} </div>
			</div>
		</div>
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'PadmawebTabNav',
    props:{
        tabs:{
            type: Array,
            required: true
        },
        selected:{
            type: String,
            required: true
        }

    },
    data() {
        return {
            currentTab: 0,
        };
    },

    mounted() {
        
    },

    methods: {
        changetab(event){
            this.$emit('selected', event.target.value);
        },
        settab(e,tab){
            this.currentTab = e
            this.$emit('selected', tab);
        }
    },
};
</script>

<style lang="scss" scoped>

</style>