<template>
    <div class="container-x dark:bg-gray-800" >
        <div class="w-full h-64 md:h-96 lg:h-screen" v-if="!items.length">
        </div>
        <carousel :autoplay="4000" :wrap-around="true" v-if="items.length">
            <slide v-for="(item,i) in items" :key="i">
                <img :src="item.imagepath" :alt="item.title">
            </slide>
        </carousel>
    </div>
</template>

<script>
import store from '@/store';
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';

export default {
    name: 'ClientSlider',

    data() {
        return {
            items: [],
        };
    },

    mounted() {
        this.getData()
    },

    methods: {
         getData(){
            store.commit('ChangeLoader',true)
            this.axios.get('api/getsliders').then((response) => {
                    this.items = response.data
                }).catch(error => {
                    
                console.log(error)

                }).finally(() => {
                    store.commit('ChangeLoader',false)
                })
            }
    },
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,
    },

};
</script>

<style lang="scss" scoped>
@import "../../assets/main.scss";
.carousel{
    width: 100%;
    overflow: hidden;
    .carousel__viewport{
        width: 100%;
        .carousel__track{
            width: 100%;
            .carousel__slide{
                width: 100%;
                img{
                    width: 100%;
                    object-fit: cover;
                    object-position: center;
                }

            }
        }
    }
}

@media screen and (min-width:768px) {

    .carousel{
        width: 100%;
        overflow: hidden;
        .carousel__viewport{
            width: 100%;
            .carousel__track{
                width: 100%;
                .carousel__slide{
                    width: 100%;
                    img{
                        width: 100%;
                        object-fit: cover;
                        object-position: center;
                    }

                }
            }
        }
    }
    
}
@media screen and (min-width:1024px) {
    .carousel{
        width: 100%;
        overflow: hidden;
        .carousel__viewport{
            width: 100%;
            .carousel__track{
                width: 100%;
                .carousel__slide{
                    width: 100%;
                    img{
                        width: 100%;
                        object-fit: cover;
                        object-position: center;
                    }

                }
            }
        }
    }
    
}
</style>