<template>
    <div class="w-full">
        <tabNavVue :tabs="tabs" :selected="selected" @selected="setSelected">
            <tabVue :isSelected="selected === '1'">
                <GeneralVue></GeneralVue>
            </tabVue>
            <tabVue :isSelected="selected === '2'">
              <SocialMediaVue></SocialMediaVue>
            </tabVue>
            <tabVue :isSelected="selected === '3'">
              <WhyVue></WhyVue>
            </tabVue>
            <tabVue :isSelected="selected === '4'">
              <ProdukNavVue></ProdukNavVue>
            </tabVue>
            <tabVue :isSelected="selected === '5'">
              <JobVue></JobVue>
            </tabVue>
            <tabVue :isSelected="selected === '6'">
                <CityVue></CityVue>
            </tabVue>
            <tabVue :isSelected="selected === '7'">
                <DepositoDurationVue></DepositoDurationVue>
            </tabVue>
            <tabVue :isSelected="selected === '8'">
                <SavingDurationVue></SavingDurationVue>
            </tabVue>
            <tabVue :isSelected="selected === '9'">
                <LoanDurationVue></LoanDurationVue>
            </tabVue>
            <tabVue :isSelected="selected === '10'">
                <IncomeVue></IncomeVue>
            </tabVue>
            <tabVue :isSelected="selected === '11'">
                <TermVue></TermVue>
            </tabVue>
            <tabVue :isSelected="selected === '12'">
                <PengaduanNasabahParams></PengaduanNasabahParams>
            </tabVue>
        </tabNavVue>
    </div>
</template>

<script>
import tabNavVue from './tabNav.vue';
import tabVue from './tab.vue';
import GeneralVue from './General.vue';
import SocialMediaVue from './SocialMedia.vue';
import WhyVue from './Why.vue';
import ProdukNavVue from './ProdukNav.vue';
import JobVue from './Job.vue';
import CityVue from './City.vue';
import DepositoDurationVue from './DepositoDuration.vue';
import SavingDurationVue from './SavingDuration.vue';
import LoanDurationVue from './LoanDuration.vue';
import IncomeVue from './Income.vue';
import TermVue from './Term.vue';
import PengaduanNasabahParams from './PengaduanNasabahParams.vue';
export default {
    name: 'PadmawebParameterComponent',

    data() {
        return {
            selected:'1',

            tabs:[
                {
                    name: 'General',
                    initial: '1',
                    icon: 'domain'
                },
                 {
                    name: 'Social Media',
                    initial: '2',
                    icon: 'office-building-marker-outline'
                },
                 {
                    name: 'Why',
                    initial: '3',
                    icon: 'help'
                },
                 {
                    name: 'Navigation',
                    initial: '4',
                    icon: 'navigation-variant'
                },
                 {
                    name: 'Job',
                    initial: '5',
                    icon: 'account-hard-hat'
                },
                 {
                    name: 'City',
                    initial: '6',
                    icon: 'city'
                },
                 {
                    name: 'Deposit Time',
                    initial: '7',
                    icon: 'calendar-clock'
                },
                 {
                    name: 'Saving Time',
                    initial: '8',
                    icon: 'calendar-clock'
                }
                ,
                 {
                    name: 'Loan Time',
                    initial: '9',
                    icon: 'calendar-clock'
                }
                 ,
                 {
                    name: 'Income',
                    initial: '10',
                    icon: 'cash-100'
                },
                 {
                    name: 'Term',
                    initial: '11',
                    icon: 'alert-circle-outline'
                },
                {
                    name: 'pengaduan Nasabah',
                    initial: '12',
                    icon: 'alert-box-outline'
                }

            ],
        };
    },

    mounted() {
        
    },

    methods: {
        setSelected(tab){
            this.selected = tab;
        },
    },
    components:{
        tabNavVue,
        tabVue,
        GeneralVue,
        SocialMediaVue,
        WhyVue,
        ProdukNavVue,
        JobVue,
        CityVue,
        DepositoDurationVue,
        SavingDurationVue,
        LoanDurationVue,
        IncomeVue,
        TermVue,
        PengaduanNasabahParams
    }
};
</script>

<style lang="scss" scoped>

</style>