<template>
    <div>
       <DataTable :datatable="items" :header="headers" @page-changed="pageChange($event)">
           <template v-slot:table-slot>
               <div class="w-full grid gap-5 grid-cols-[1fr_auto]">
                   <form @submit.prevent action="" class="w-full">
                       <div class="flex border-b-2 bg-gray-100 border-gray-300 dark:bg-gray-900 dark:text-gray-100 dark:border-gray-800 focus:outline-none py-1 mb-4 w-full text-gray-700 font-medium">
                           <input type="text" class="focus:outline-none bg-transparent w-full" placeholder="Search..." v-model="search">
                           <mdicon name="magnify" size="28" class="mr-1 text-gray-700 dark:text-gray-100"/>
                       </div>
                    </form>

                     <JsonCSV :data="items.data">
                        <button class="bg-primary text-white px-3 py-2 rounded-sm text-xs font-weight">Download CSV</button>
                    </JsonCSV>                    
               </div>
           </template>

           <template v-slot:action="item">
                <div class="flex">
                   <button class="flex justify-center items-center bg-secondary px-2 py-1 text-gray-100 rounded text-xs font-medium focus:outline-none" @click="deleteModal(item)" v-if="isDeletePermitted"><mdicon name="trash-can-outline" class="text-gray-100 mr-[2px]" size="15"/><span>Delete</span></button>
               </div>
           </template>
       </DataTable>
        <Transition name="fade">
            <Confirm v-if="deletemodal" @closeconfirm="deletemodal = false">
                <template v-slot:header>
                    Confirm
                </template>
                <template v-slot:main>
                    Are You Sure You Want To Delete This Data ?
                </template>
                <template v-slot:footer>
                    <div class="flex justify-end items-center">
                        <LButton class="bg-gray-300 text-gray-800 mr-2" @click="deletemodal = false">
                            Cancel
                        </LButton>
                        <LButton class="text-white bg-secondary" :class=" isloading ? 'pointer-events-none' : 'cursor-pointer'" :disabled="isloading" @click="deleteData">
                            {{ isloading ? 'Loading...' : 'Confirm' }}
                        </LButton>                            
                    </div>
                </template>
            </Confirm>  
        </Transition>
    </div>
</template>

<script>
import LButton from '@/layouts/button.vue'
import Confirm from '@/layouts/confirm.vue'
import FormBase from '@/layouts/FormBase.vue'
import DataTable from '@/layouts/DataTable.vue'
import JsonCSV from 'vue-json-csv'

export default {
    name: 'PadmawebVCriticTable',

    data() {
        return {
            isLoading:false,
            deletemodal: false,
            editIndex : -1,
            deleteID: null,
            headers:[],
            items:[],
            currentPage : 1,
            modal:false,
            roles: [],
            search: ''
        };
    },

    watch:{
        search:function(){
            if(this.search.length > 0){
                this.searchData(this.search)
            } else {
                this.getData()
            }
            
        },
        modal(val) {
			val || this.clearform();
		}
    },

    mounted() {
        this.getData()
        this.getheader()
    },

    methods: {
            getheader(){
            if(this.isDeletePermitted == true || this.isUpdatePermitted == true){
                this.headers = [
                    {
                        Text : 'ID',
                        value : 'id',
                        type: 'string'
                    },
                    {
                        Text: 'Name',
                        value: 'name',
                        type: 'string'
                    },
                     {
                        Text: 'Address',
                        value: 'address',
                        type: 'string'
                    },
                     {
                        Text: 'City',
                        value: 'city',
                        type: 'string'
                    },
                     {
                        Text: 'Email',
                        value: 'email',
                        type: 'string'
                    },
                     {
                        Text: 'Phone',
                        value: 'phone',
                        type: 'string'
                    },
                     {
                        Text: 'Suggestion',
                        value: 'suggestion',
                        type: 'string'
                    },
                    {
                        Text: 'Actions',
                        action: true,
                    }
                ]
            }else{
                this.headers = [
                     {
                        Text : 'ID',
                        value : 'id',
                        type: 'string'
                    },
                    {
                        Text: 'Name',
                        value: 'name',
                        type: 'string'
                    },
                     {
                        Text: 'Address',
                        value: 'address',
                        type: 'string'
                    },
                     {
                        Text: 'City',
                        value: 'city',
                        type: 'string'
                    },
                     {
                        Text: 'Email',
                        value: 'email',
                        type: 'string'
                    },
                     {
                        Text: 'Phone',
                        value: 'phone',
                        type: 'string'
                    },
                     {
                        Text: 'Suggestion',
                        value: 'suggestion',
                        type: 'string'
                    }
                ]
            }
        },
        deleteModal(val){
            this.deleteID = JSON.parse(JSON.stringify(val)).item.id
            this.deletemodal = true
        },
        selectFile(event){
           this.form.image = event.target.files[0];
        },
        changePage(e){
            this.currentPage = e;
            this.getData()
        },

        getData(){
            this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
            this.axios.get(`api/critics?page=${this.currentPage}`).then((response) => {
                this.items = response.data
            }).catch(error => {
               
              console.log(error)

            }).finally(() => {
                
            })
        },
        searchData(val){
            this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
            this.axios.get('api/critics/search/'+val).then((response) => {
                this.items = response.data
            }).catch(error => {
               
              console.log(error)

            }).finally(() => {
                
            })
        },
        pageChange: function(e){
           this.currentPage = e;
           this.getData()
        },

        clearform: function() {
            this.modal = false
            this.$nextTick(() => {
                this.form = Object.assign({}, this.defaultForm);
                this.editIndex = -1;
            });
        },
        deleteData(){
            this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
            this.axios.delete('api/critics/'+this.deleteID).then((response) => {
                this.getData()
                this.deleteID = null
                this.deletemodal = false
            }).catch(error => {
               
              console.log(error)

            }).finally(() => {
                
            })
        }
    },
    components:{
        DataTable,
        FormBase,
        Confirm,
        LButton,
        JsonCSV
    },
    computed:{
    }
};
</script>

<style lang="scss" scoped>

</style>