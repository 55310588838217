<template>
   <div class="container-y py-8">
        <div>
            <div class="text-3xl font-medium text-gray-900 dark:text-gray-200 border-b-2 border-secondary pb-4 mb-8">Blog Terbaru</div>
            <div class="grid grid-cols-1 lg:grid-cols-[1fr_2fr] gap-4">
                <div class="left">
                        <div class="l-card w-full">
                            <div class="l-img w-full mb-3">
                                <img :src="baru.imagepath" class="w-full object-cover object-center" :alt="baru.title">
                            </div>
                            <div class="l-date text-sm font-medium mb-2 text-gray-600 dark:text-gray-200">
                                {{moment(baru.published_date).locale('id').format("DD MMMM YYYY")}}
                            </div>
                            <div class="l-tag w-28 rounded-sm text-white text-sm mb-2 py-1 bg-primary text-center px-2">
                                    <router-link :to="'/blog/kategori/'+baru.categorypermalink" >
                                        {{baru.category}}
                                    </router-link>
                            </div>
                            <div class="l-headline text-xl font-bold text-primary dark:text-gray-200 hover:text-secondary dark:hover:text-secondary duration-100 mt-3">
                                    <router-link :to="`/blog/`+baru.permalink">
                                        {{baru.title}}
                                    </router-link>
                            </div>
                        </div>
                </div>
                <div class="right">
                    <div class="w-full grid gap-5 grid-cols-1 md:grid-cols-2">
                        <div v-for="item in items.data" :key="item" class="r-card grid gap-2 md:gap-3 grid-cols-[2fr_3fr]">
                            <div class="r-img">
                                <div class="img-container">
                                    <img :src="item.imagepath" :alt="item.title" class="w-full h-full object-cover object-center">
                                </div>
                            </div>
                            <div class="r-desc">
                                <div class="r-date text-sm font-medium text-gray-500 mb-1 dark:text-gray-200">
                                        {{moment(item.published_date).locale('id').format("DD MMMM YYYY")}}
                                </div>
                                <div class="r-tag bg-primary mb-1 text-xs font-medium text-white px-2 py-1 rounded-sm w-28 text-center">
                                        <router-link :to="'/blog/kategori/'+item.blog_category.permalink">
                                            {{item.blog_category.category}}
                                        </router-link>
                                </div>
                                <div class="r-headlinen text-sm font-semibold dark:font-medium text-primary dark:text-gray-200 duration-300 hover:text-secondary dark:hover:text-secondary">
                                    <router-link class="link" :to="{name : 'DBlog', params: { blog: item.permalink } }">
                                            {{item.title}}
                                    </router-link>                              
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="px-5 flex justify-between items-center h-10 mt-6" v-if="page > 1">
                            <ul class="flex items-center h-full mr-5">
                                <li v-if="currentPage > 1 " @click="prev" class="h-full flex justify-center items-center px-2 text-xs font-medium border cursor-pointer text-primary bg-gray-100 dark:bg-gray-900 border-gray-300 dark:border-gray-800 dark:text-gray-100">Prev</li>
                                <li v-for="p in pages" :key="p" class="h-full flex justify-center items-center px-3 text-sm font-medium border cursor-pointer" :class="currentPage === p ? 'bg-primary text-gray-100 border-primary' : 'text-primary bg-gray-100 dark:bg-gray-900 dark:text-gray-100 border-gray-300 dark:border-gray-800'" @click="change(p)">{{p}}</li>
                                <li v-if="currentPage < page"  @click="next" class="h-full px-2 text-xs flex justify-center items-center font-medium border cursor-pointer text-primary bg-gray-100 dark:bg-gray-900 border-gray-300 dark:text-gray-100 dark:border-gray-800">Next</li>
                            </ul>
                        </div>
                </div>
            </div>
            </div>
        
   </div>
</template>

<script>
import moment from "moment";
export default {
    name: 'ClientNewblog',

    data() {
        return {
            baru :{},
            items:[],
            moment: moment,
            currentPage:1
        };
    },

    mounted() {
        this.getData();
        this.getnewdata();
        
    },

    methods: {
        change(e){
           this.currentPage = e
       },
        prev(){
           this.currentPage = this.currentPage - 1
       },
       next(){
           this.currentPage = this.currentPage + 1
       },
        getData(){
            this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
            this.axios.get('api/getblognew?page='+this.currentPage).then((response) => {
                this.items = response.data
                this.total = response.data.total
            }).catch(error => {
               
              console.log(error)

            }).finally(() => {
                
            })
        },
        getnewdata(){
            this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
            this.axios.get('api/getnewblog').then((response) => {
                this.baru = {
                    title:response.data[0].title,
                    imagepath:response.data[0].imagepath,
                    published_date:response.data[0].published_date,
                    id:response.data[0].id,
                    category:response.data[0].blog_category.category,
                    permalink:response.data[0].permalink,
                    categorypermalink:response.data[0].blog_category.permalink,
                }
            }).catch(error => {
               
              console.log(error)

            }).finally(() => {
                
            })
        },

        
    },  
    computed:{
        page(){
            return Math.ceil(this.items.total / this.items.per_page)
        },
        pages(){
            const onSides = 1
            let pages = [];
            // Loop through
            for (let i = 1; i <= this.page; i++) {
                // Define offset
                let offset = (i == 1 || this.page) ? onSides + 1 : onSides;
                // If added
                if (i == 1 || (this.currentPage - offset <= i && this.currentPage + offset >= i) ||
                    i == this.currentPage || i == this.page) {
                    pages.push(i);
                } else if (i == this.currentPage - (offset + 1) || i == this.page + (offset + 1)) {
                    pages.push('...');
                }
            }
            return pages;            
        }
    },
    watch:{
        currentPage:function(){
            this.getData();
        }
    }
};
</script>

<style lang="scss" scoped>

</style>