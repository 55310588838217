<template>
    <div class="bg-white dark:bg-gray-800">
        <div class="w-full h-64 md:h-96 lg:h-screen" v-if="loading">

        </div>
        <Content></Content>
    </div>
</template>

<script>
import store from '@/store';
import Content from '../components/Other/SukuBungaContent.vue'
export default {
    name: 'ClientSukubungaview',

    data() {
        return {
            params:{},
            pagename: 'Suku Bunga',
            crumbs: [],
            pageparams: 'General',
        };
    },

    mounted() {
        this.getparams();
    },

    methods: {
         getparams(){
             this.axios.get('api/getbanner/'+this.pageparams).then((response) => {
               this.params = {
                    id: response.data.id,
                    title: response.data.type,
                    img: response.data.imagepath,
                    description : response.data.description,
                    showtitle : response.data.showtitle,
                    showdesc : response.data.showdesc,
                }

                document.title = 'Suku Bunga | Bank Padma'
                document.querySelector('head meta[name="description"]').setAttribute('content', response.data.description);
                document.querySelector('head meta[name="og:description"]').setAttribute('content', response.data.description);
                document.querySelector('head meta[name="og:title"]').setAttribute('content', 'Suku Bunga | Bank Padma');
                document.querySelector('head meta[name="keywords"]').setAttribute('content', response.data.keyword);
                document.querySelector('head meta[name="og:url"]').setAttribute('content', window.location.href);
                document.querySelector('head meta[name="og:image"]').setAttribute('content', response.data.imagepath);
            }).catch(error => {
                
            console.log(error)

            }).finally(() => {
                
            })
            
            this.crumbs = [{key:"Tabungan", link:"/tabungan"}]
        }
    },
    components:{
        Content
    },
    computed:{
        loading(){
            return store.state.preloader
        }
    }
};
</script>

<style lang="scss" scoped>

</style>