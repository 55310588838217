<template>
    <div class="border-b-2 border-secondary md:border-b-0 md:mb-8">
        <div class="w-full overflow-hidden grid grid-cols-1 md:grid-cols-2 gap-1">
            <div class="w-full md:h-52 lg:h-96 cursor-pointer relative overflow-hidden group" v-for="item in items" :key="item">
                <div class="w-full h-full overflow-hidden">
                    <img :src="item.imagepath" :alt="item.award" class="h-full w-full duration-300 group-hover:scale-[1.1] object-center object-cover">
                </div>
                <div class="overlay absolute inset-0 bg-p-overlay dark:bg-b-overlay flex flex-col justify-center items-start p-4 opacity-0 group-hover:opacity-100 duration-300">
                    <div class="text-base font-bold text-center w-full text-white mb-2">{{item.award}}</div>
                    <div class="text-xs md:hidden lg:block font-normal text-white text-center leading-relaxed">{{item.detail}}</div>
                </div>
                <div class="absolute bg-w-overlay dark:bg-b-overlay z-20 bottom-0 w-full text-gray-900 dark:text-gray-200 text-xs text-center font-bold py-2 duration-300 group-hover:translate-y-10">{{item.award}}</div>
            </div>
        </div>
        <div class="block md:hidden py-4">
            <Share></Share>
        </div>
    </div>
</template>

<script>
import store from '@/store';
import Share from '../../layouts/Share.vue'
export default {
    name: 'ClientContentpenghargaan',

    data() {
        return {
            items: null,
        };
    },

    mounted() {
         this.getData()
    },

    methods: {
        getData(){
            store.commit('ChangeLoader',true)
            this.axios.get('api/getaward').then((response) => {
                this.items = response.data
            }).catch(error => {
                
            console.log(error)

            }).finally(() => {
                store.commit('ChangeLoader',false)
            })
        }
    },
    components:{
        Share
    }
};
</script>

<style lang="scss" scoped>
@import "../../assets/main.scss"

</style>