<template>
    <div class="fixed z-[999] bg-b-overlay-half inset-0 flex justify-center items-center w-full h-screen">
        <div class="text-black">Loading....</div>        
    </div>
</template>

<script>
export default {
    name: 'PadmaErpLoading',

    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>

<style lang="scss" scoped>

</style>