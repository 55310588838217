<template>
    <div class="bg-white dark:bg-gray-800">
        <Breadcrumbs :breadcrumbs="crumbs" :page="pagename"></Breadcrumbs>
        <Content :data="item"></Content>
           
    </div>
</template>

<script>
import store from '@/store';
import Breadcrumbs from '../layouts/breadcrumb.vue';
import Content from '../components/Blog/DBlog-C.vue'
export default {
    name: 'ClientDBlog',
    props:['blog'],
    data() {
        return {
             pagename: 'Blog Padma',
            crumbs: [],
            item:{}
        };
    },

    created() {
        this.getparams()
    },

    methods: {
         getparams(){
            store.commit('ChangeLoader',true)
             this.axios.get('api/getblogdetail/'+this.blog).then((response) => {
                this.item = response.data
                this.crumbs = [
                    {key:"Blog", link:"/blog"},
                    {key:"Blog Padma", link: "/blog/"+response.data.permalink}
                ]

                document.title = response.data.title
                document.querySelector('head meta[name="description"]').setAttribute('content', response.data.description);
                document.querySelector('head meta[name="og:description"]').setAttribute('content', response.data.description);
                document.querySelector('head meta[name="og:title"]').setAttribute('content', response.data.title);
                document.querySelector('head meta[name="keywords"]').setAttribute('content', response.data.keyword);
                document.querySelector('head meta[name="og:url"]').setAttribute('content', window.location.href);
                document.querySelector('head meta[name="og:image"]').setAttribute('content', response.data.imagepath);
                
               
            }).catch(error => {
                
            console.log(error)

            }).finally(() => {
                store.commit('ChangeLoader',false)
                
            })
        },
    },
    components:{
        Breadcrumbs,
        Content
        
    },
    watch:{
        blog:function(){
            this.getparams()
        }
    },
    computed:{
        loading(){
            return store.state.preloader
        }
    }
};
</script>

<style lang="scss" scoped>

</style>