<template>
   <div>
        <div class="fixed bg-b-overlay inset-0 w-full h-screen z-20" @click="this.$emit('closeModal')">
        </div>
        <div class="rounded shadow min-w-2/5 bg-gray-100 dark:bg-gray-900 px-6 py-6 absolute left-[50%] -translate-x-[50%] top-[10%] z-30" style="transform: translateX(-100%);">
            <div class="text-2xl font-normal text-gray-900 mb-4 dark:text-gray-100">
                <slot name="formName"></slot>
            </div>
            <div class="px-2 mb-10">
                <slot name="form"></slot>
            </div>
            <div class="flex justify-end items-center">
                <slot name="formFooter"></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PadmawebFormBase',

    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>

<style lang="scss" scoped>

</style>