<template>
  <div class="home">
    <Slider></Slider>
    <Produk v-if="!loading"></Produk>
    <About v-if="!loading"></About>
    <Why v-if="!loading"></Why>
    <Kredit v-if="!loading"></Kredit>
    <Blog v-if="!loading"></Blog>
    <VideoVue v-if="!loading"></VideoVue>
    <Testimonial v-if="!loading"></Testimonial>
  </div>
</template>

<script>
import store from '@/store'
import Slider from '../components/Home/Slider.vue'
import Produk from '../components/Home/Product.vue'
import About from '../components/Home/About.vue'
import Why from '../components/Home/Why.vue'
import Kredit from '../components/Home/Kredit.vue'
import Blog from '../components/Home/Blog.vue'
import Testimonial from '../components/Home/Testimonial.vue'
import VideoVue from '@/components/Home/Video.vue'
export default {
  name: 'HomeView',
  components: {
    Slider,
    Produk,
    About,
    Why,
    Kredit,
    Blog,
    Testimonial ,
    VideoVue
  },
  data(){
    return {
      pageparams: 'Home'
    }
  },
  mounted(){
    this.getparams()
  },
  methods:{
     getparams(){
             this.axios.get('api/getbanner/'+this.pageparams).then((response) => {
               this.params = {
                    id: response.data.id,
                    title: response.data.type,
                    img: response.data.imagepath,
                    description : response.data.description,
                    showtitle : response.data.showtitle,
                    showdesc : response.data.showdesc,
                }

                document.title = 'Bank Padma | Tumbuh Berkembang Bersama'
                document.querySelector('head meta[name="description"]').setAttribute('content', response.data.description);
                document.querySelector('head meta[name="og:description"]').setAttribute('content', response.data.description);
                document.querySelector('head meta[name="og:title"]').setAttribute('content', 'Bank Padma | Tumbuh Berkembang Bersama');
                document.querySelector('head meta[name="keywords"]').setAttribute('content', response.data.keyword);
                document.querySelector('head meta[name="og:url"]').setAttribute('content', window.location.href);
                document.querySelector('head meta[name="og:image"]').setAttribute('content', response.data.imagepath);
            }).catch(error => {
                
            console.log(error)

            }).finally(() => {
                
            })
            
            this.crumbs = [{key:"Karir", link:"/karir"}]
        }
  },
  computed:{
    loading(){
      return store.state.preloader
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../assets/main.scss"
</style>
