<template>
    <div>
       <DataTable :datatable="items" :fixed="false" :header="headers" @page-changed="pageChange($event)">
           <template v-slot:table-slot>
               <div class="w-full grid gap-5 grid-cols-[1fr_auto]">
                   <form @submit.prevent action="" class="w-full">
                       <div class="flex border-b-2 bg-gray-100 border-gray-300 dark:bg-gray-900 dark:text-gray-100 dark:border-gray-800 focus:outline-none py-1 mb-4 w-full text-gray-700 font-medium">
                           <input type="text" class="focus:outline-none bg-transparent w-full" placeholder="Search..." v-model="search">
                           <mdicon name="magnify" size="28" class="mr-1 text-gray-700 dark:text-gray-100"/>
                       </div>
                    </form>

                    <button class="text-sm font-medium focus:outline-none active:translate-y-[2px] duration-200 bg-primary inline-block relative h-8 rounded shadow w-16 text-center text-gray-100" @click="addModal" v-if="isCreatePermitted">Add</button>
               </div>
           </template>

           <template v-slot:action="item">
                <div class="flex">
                   <button class="flex justify-center items-center bg-primary mr-2 px-2 py-1 text-gray-100 rounded text-xs font-medium focus:outline-none" @click="editModal(item)" v-if="isUpdatePermitted"><mdicon name="pencil" class="text-gray-100 mr-[2px]" size="15"/><span>Edit</span></button>
                   <button class="flex justify-center items-center bg-secondary px-2 py-1 text-gray-100 rounded text-xs font-medium focus:outline-none" @click="deleteModal(item)" v-if="isDeletePermitted"><mdicon name="trash-can-outline" class="text-gray-100 mr-[2px]" size="15"/><span>Delete</span></button>
               </div>
           </template>
       </DataTable>
       <LargeFormBaseVue v-if="modal" @closeModal="modal = !modal">
            <template v-slot:formName>
               {{title}}
            </template>
            <template v-slot:main>
               <div class="grid gap-5 grid-cols-1">
                   <div class="left">
                       <div class="form-group mb-5">
                           <label for="title" class="text-sm font-bold text-gray-500">Title</label>
                           <input type="text" class="bg-gray-100 w-full border-[1px] border-gray-400 rounded-sm mt-2 dark:border-gray-800 dark:text-gray-100 focus:outline-none py-2 px-2 text-sm font-normal text-gray-700" v-model="form.title">
                        </div>

                        <div class="form-group flex mb-5">
                            <div class="mr-3">
                                <button class="border-gray-400 border-[1px] items-center flex text-gray-500 px-3 py-2 rounded-sm text-xs font-medium" onclick="document.getElementById('fileImage').click()"><mdicon name="paperclip" size="15" class="text-gray-500 " /> <span>{{ form.image == null ? 'Upload Image' : 'Image Uploaded' }}</span></button>
                                <input type='file' id="fileImage" style="display:none" @change="getImage"> 
                            </div>
                            <div>
                                <button class="border-gray-400 border-[1px] items-center flex text-gray-500 px-3 py-2 rounded-sm text-xs font-medium" onclick="document.getElementById('fileThumbnail').click()"><mdicon name="paperclip" size="15" class="text-gray-500" /> <span>{{ form.thumbnail == null ? 'Upload Thumbnail' : 'Thumbnail Uploaded' }}</span></button>
                                <input type='file' id="fileThumbnail" style="display:none" @change="getThumbnail"> 
                            </div>
                        </div>  
                        <div class="text-sm font-bold text-gray-500 mb-2">Detail</div>
                        <quill-editor
                                v-model:value="form.detail"
                                :options="state.editorOption"
                            />

                        <div class="flex mt-10">
                            <LButton class="text-white bg-secondary mr-2" :class=" isloading ? 'pointer-events-none' : 'cursor-pointer'" :disabled="isloading" @click="save">
                                {{ isloading ? 'Loading...' : 'Save' }}
                            </LButton>   
                           <LButton class="bg-gray-300 text-gray-800">
                            Cancel
                            </LButton>                            
                        </div>                 
                   </div>
               </div>
            </template>
       </LargeFormBaseVue>
       
       <Transition name="fade">
            <Confirm v-if="deletemodal" @closeconfirm="deletemodal = false">
                <template v-slot:header>
                    Confirm
                </template>
                <template v-slot:main>
                    Are You Sure You Want To Delete This Data ?
                </template>
                <template v-slot:footer>
                    <div class="flex justify-end items-center">
                        <LButton class="bg-gray-300 text-gray-800 mr-2" @click="deletemodal = false">
                            Cancel
                        </LButton>
                        <LButton class="text-white bg-secondary" :class=" isloading ? 'pointer-events-none' : 'cursor-pointer'" :disabled="isloading" @click="deleteData">
                            {{ isloading ? 'Loading...' : 'Confirm' }}
                        </LButton>                            
                    </div>
                </template>
            </Confirm>  
        </Transition>
    </div> 
</template>

<script>
import LButton from '@/layouts/button.vue'
import Confirm from '@/layouts/confirm.vue'
import DataTable from '@/layouts/DataTable.vue'
import LargeFormBaseVue from '@/layouts/LargeFormBase.vue'
import taginputVue from '@/layouts/taginput.vue'

import { reactive } from 'vue'
import { quillEditor } from 'vue3-quill'


export default {
    name: 'PadmawebRateTable',

    data() {
        return {
            isLoading:false,
            deletemodal: false,
            editIndex : -1,
            deleteID: null,
            form : {
                id: '',
                title: '',
                detail: '',
                image: null,
                thumbnail: null,            
            },
           defaultForm : {
                id: '',
                title: '',
                detail: '',
                image: null,
                thumbnail: null,
            },
            headers:[],
            items:[],
            currentPage : 1,
            modal:false,
            roles: [],
            search: ''
        };
    },

    watch:{
        search:function(){
            if(this.search.length > 0){
                this.searchData(this.search)
            } else {
                this.getData()
            }
            
        },
        modal(val) {
			val || this.clearform();
		},
    },

    mounted() {
        this.getData()
        this.getheader()
    },

    methods: {
        addTag(val) {			
           this.form.keyword.push(val)
        },
		removeTag(index){
			 this.form.keyword.splice(index, 1)
		},
        getheader(){
            if(this.isDeletePermitted == true || this.isUpdatePermitted == true){
                this.headers = [
                   {
                    Text : 'ID',
                    value : 'id',
                    type: 'string'
                },
                {
                    Text: 'Title',
                    value: 'title',
                    type: 'string'
                }
                ,
                {
                    Text: 'Image',
                    value: 'imagepath',
                    type: 'images'
                }
                ,
                {
                    Text: 'Permalink',
                    value: 'permalink',
                    type: 'string'
                },
                {
                    Text: 'Actions',
                    action: true,
                }
                ]
            }else{
                this.headers = [
                    {
                    Text : 'ID',
                    value : 'id',
                    type: 'string'
                },
                {
                    Text: 'Title',
                    value: 'title',
                    type: 'string'
                }
                ,
                {
                    Text: 'Image',
                    value: 'imagepath',
                    type: 'images'
                }
                ,
                {
                    Text: 'Permalink',
                    value: 'permalink',
                    type: 'string'
                }
                ]
            }
        },
        addModal(){
            this.editIndex = -1
            this.modal = !this.modal
        },
        deleteModal(val){
            this.deleteID = JSON.parse(JSON.stringify(val)).item.id
            this.deletemodal = true
        },
        editModal(val){
            this.editIndex = 1
            let obj = JSON.parse(JSON.stringify(val)).item


            this.form.id = obj.id
            this.form.title = obj.title
            this.form.detail = obj.detail           

            this.modal = true
        },
        getImage(event){
           this.form.image = event.target.files[0];
        },
        getThumbnail(event){
           this.form.thumbnail = event.target.files[0];
        },
        changePage(e){
            this.currentPage = e;
            this.getData()
        },

        getData(){

           this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
            this.axios.get(`api/rate?page=${this.currentPage}`).then((response) => {
                this.items = response.data
            }).catch(error => {
               
              console.log(error)

            }).finally(() => {
                
            })
        },
        searchData(val){
            this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
            this.axios.get('api/rate/search/'+val).then((response) => {
                this.items = response.data
            }).catch(error => {
               
              console.log(error)

            }).finally(() => {
                
            })
        },
        pageChange: function(e){
           this.currentPage = e;
           this.getData()
        },

        clearform: function() {
            this.modal = false
            this.$nextTick(() => {
                this.form = Object.assign({}, this.defaultForm);
                this.editIndex = -1;
            });
        }
           
        ,
        save(){
            if(this.editIndex > -1){
                let formData = new FormData();
                if( this.form.image !== null){
					formData.append('image', this.form.image);
				}
                if( this.form.thumbnail !== null){
					formData.append('thumbnail', this.form.thumbnail);
				}
                formData.append('id', this.form.id);		
                formData.append('title', this.form.title);		
				formData.append('detail', this.form.detail);

                    this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
                    this.axios.post('/api/rate/update',formData).then(() => {
                    this.getData();
                    this.clearform()
                    this.$notify({
                        title: "Notification",
                        type: "success",
                        text: "Data Updated Successfully"
                    });
                }).catch(error => {                
                    console.log(error)
                }).finally(() => {
                })
            }else{
                
                let formData = new FormData();
                formData.append('title', this.form.title);		
				formData.append('detail', this.form.detail);
                formData.append('image', this.form.image);		
				formData.append('thumbnail', this.form.thumbnail);

                    this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
                    this.axios.post('/api/rate',formData).then(() => {
                    this.getData();
                    this.clearform()
                    this.$notify({
                        title: "Notification",
                        type: "success",
                        text: "Data Created Successfully"
                    });
                }).catch(error => {                
                    console.log(error)
                }).finally(() => {
                })
            }
        },
        deleteData(){
            this.axios.defaults.headers.common = { 'Authorization': `Bearer ${this.token}` }
            this.axios.delete('api/rate/'+this.deleteID).then(() => {
                this.getData()
                this.deleteID = null
                this.deletemodal = false
            }).catch(error => {
               
              console.log(error)

            }).finally(() => {
                
            })
        }
    },
    components:{
        DataTable,
        Confirm,
        LButton,
        quillEditor,
        LargeFormBaseVue,
        taginputVue
    },
    computed:{
        title(){
            if(this.editIndex > -1){
                return 'Edit Item'
            }else{
                return 'New Item'
            }
        }
    },
    setup() {
        const state = reactive({
        dynamicComponent: null,
        content: '',
        _content: '',
        editorOption: {
            placeholder: 'Detail Here',
            modules: {
            toolbar: [
                ['bold', 'italic', 'underline', 'strike'],
                ['blockquote', 'code-block'],
                [{ header: 1 }, { header: 2 }],
                [{ list: 'ordered' }, { list: 'bullet' }],
                [{ script: 'sub' }, { script: 'super' }],
                [{ indent: '-1' }, { indent: '+1' }],
                [{ direction: 'rtl' }],
                [{ size: ['small', false, 'large', 'huge'] }],
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                [{ color: [] }, { background: [] }],
                [{ font: [] }],
                [{ align: [] }],
                ['clean'],
                ['link', 'image', 'video']
            ]
            // other moudle options here
            }
            // more options
        },
        disabled: false
        })

        const onEditorBlur = quill => {
        console.log('editor blur!', quill)
        }
        const onEditorFocus = quill => {
        console.log('editor focus!', quill)
        }
        const onEditorReady = quill => {
        console.log('editor ready!', quill)
        }
        const onEditorChange = ({ quill, html, text }) => {
        console.log('editor change!', quill, html, text)
        state._content = html
        }

        setTimeout(() => {
        state.disabled = true
        }, 2000)

        return { state, onEditorBlur, onEditorFocus, onEditorReady, onEditorChange }
    }
};
</script>

<style lang="scss" scoped>

</style>