<template>
    <div class="border-b-2 border-secondary md:border-b-0">
        <div class="text-sm md:text-base leading-loose md:leading-loose w-full dark:text-gray-200 font-normal dark:font-normal content mb-5 text-gray-700" v-html="items.vision">
        </div>
        <div class="block md:hidden py-4">
            <Share></Share>
        </div>
    </div>
</template>

<script>
import store from '@/store';
import Share from '../../layouts/Share.vue'
export default {
    name: 'ClientContentvisi',

    data() {
        return {
            items: {},
        };
    },

    mounted() {
         this.getData()
    },

    methods: {
       getData(){
            store.commit('ChangeLoader',true)
            this.axios.get('api/getcompany').then((response) => {
                this.items = response.data[0]
              
            }).catch(error => {
                
            console.log(error)

            }).finally(() => {
                 store.commit('ChangeLoader',false)
                
            })
        }
    },
    components:{
        Share
    }
};
</script>

<style lang="scss" scoped>
@import "../../assets/main.scss"

</style>