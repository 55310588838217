<template>
    <div class="container-x">
        <div class="grid gap-2 grid-cols-1 lg:grid-cols-[2fr_1fr] py-2">
            <div class="left">
                <div class="grid gap-2  grid-cols-1 md:grid-cols-2">
                    <router-link :to="{ name: 'DKredit', params: { name: item.permalink }}" class="w-full h-80 lg:h-96 overflow-hidden relative box" v-for="item in items" :key="item.id">
                        <div class="image w-full h-full overflow-hidden"><img :src="item.thumbnailpath" class="w-full h-full object-cover object-centerd" :alt="item.title"></div>
                        <div class="footer duration-300 absolute bottom-0 bg-w-overlay dark:bg-b-overlay w-full h-10 leading-10 text-center text-xs uppercase font-bold text-gray-900 dark:text-gray-200">{{item.title}}</div>
                        <div class="bg-p-overlay dark:bg-b-overlay absolute duration-500 inset-0 opacity-0 h-full w-full py-5 px-16 z-10 flex flex-col justify-center items-center box-detail ">
                            <div class="text-xl uppercase font-bold text-white mb-5">{{item.title}}</div>
                            <p class="text-white text-sm text-center leading-relaxed">{{item.description}}</p>
                        </div>
                    </router-link>
                </div>
            </div>
            <div class="right">
                <Calc></Calc>
            </div>
        </div>
        
    </div>
</template>

<script>
import store from '@/store';
import Calc from './Calc.vue'
export default {
    name: 'ClientContentKredit',

    data() {
        return {
            items:[]
        };
    },

    mounted() {
        this.getData();
    },

    methods: {
       getData(){
         store.commit('ChangeLoader',true)
        this.axios.get('api/getloan').then((response) => {
                this.items = response.data
            }).catch(error => {
                
            console.log(error)

            }).finally(() => {
                 store.commit('ChangeLoader',false)
                
            })
        }
    },
    components:{
        Calc
    }
};
</script>

<style lang="scss" scoped>
.box{
    &:hover{
        .box-detail{
            opacity: 1 !important;
        }
        .footer{
            transform: translateY(50px);
        }
    }
  
}

</style>