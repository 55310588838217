<template>
    <div class="container-y flex flex-row h-full items-center justify-center" v-if="!loading">
        <div class="w-full py-3 md:py-5">
            <nav class="flex" aria-label="Breadcrumb">
                <ol class="flex breadcrumb uppercase leading-none divide-x divide-gray-400 dark:text-gray-100 text-primary dark:hover:text-white">
                    <li class="pr-4"><router-link to="/" class="inline-flex items-center text-xs font-bold"><mdicon name="home" size="20" class="text-gray-700 mr-1 dark:text-gray-100" />Home</router-link></li>
                    <li v-for="crumb in breadcrumbs" :key="crumb" class="px-2 md:px-4"><router-link :to="crumb.link" class="inline-flex items-center text-xs font-bold">{{crumb.key}}</router-link></li>
                </ol>
            </nav>
            <div class="uppercase mt-1 text-sm md:text-xl text-primary  font-bold dark:text-gray-100">
                {{pagename}}
            </div>
        </div>
        <div class="share hidden md:flex md:w-3/4 lg:w-2/4 items-end flex-row justify-end h-full" v-if="shares.length">
            <div class="text-xl font-semibold text-gray-700 h-full mr-3 dark:text-gray-200">Bagikan :</div>
            <div class="flex flex-row justify-center items-center" v-if="shares.length">
                <div class="h-full mr-1 lg:mr-2 hover:-translate-y-1 duration-300" v-for="s in shares" :key="s">
                   <ShareNetwork
                    :network="s.platform"
                    :url="s.sharelink"
                    title=" "
                    description=" "
                    quote=" "
                    hashtags=" "
                    >
                    <mdicon :name="s.icon" size="35" :style="{'color' : s.color}" class="dark:text-gray-200" />
                </ShareNetwork>
                </div>            
            </div>
        </div>
    </div>
</template>

<script>
import store from '@/store';
export default {
    name: 'ClientBreadcrumb',
    props: ['breadcrumbs','page'],
    data() {
        return {
             shares:[]
        };
    },

   mounted() {
        this.getData()
   },

    methods: {
         getData(){
            this.axios.get('api/getshare').then((response) => {
               let obj = {}

               for(let i = 0 ; i < response.data.length ; i ++){
                    obj = {
                        platform : response.data[i].platform,
                        icon : response.data[i].icon,
                        color : response.data[i].color,
                        share : response.data[i].share,
                        display : response.data[i].display,
                        chat : response.data[i].chat,
                        chaturl : response.data[i].chaturl,
                        url : response.data[i].url,
                        sharelink :document.location.href,
                    }

                    this.shares.push(obj)
               }

            }).catch(error => {
                
            console.log(error)

            }).finally(() => {
                
            })
        }
    },
    computed:{
        pagename(){
            return this.page
        },
        loading(){
            return store.state.preloader
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../assets/main.scss";
.breadcrumb{
    li{
        &:nth-last-child(1){
            color: $secondary !important;
            pointer-events: none;
        }
    }
}
</style>