<template>
    <div class="container-y pb-5">
        <div v-if="items.length">
            <div class="grid grid-cols-1 md:grid-cols-[2fr_1fr] gap-5 mb-5" v-for="item in items" :key="item">
                <div class="left order-2 md:order-1">
                    <div class="text-sm md:text-xl font-bold text-primary uppercase dark:text-gray-200 mb-3">
                        {{item.title}}
                    </div>
                    <div class="text-sm md:text-base leading-loose md:leading-loose w-full dark:text-gray-200 font-normal dark:font-normal content mb-5 text-gray-700" v-html="item.detail">

                    </div>
                    <button class="bg-secondary rounded text-xs md:text-sm text-white font-bold p-3 mt-3 duration-300 active:translate-y-1">
                        <router-link to="/karir/formulir-lamaran-kerja">Lamar Sekarang</router-link>
                    </button>                 
                </div>
                <div class="right order-1 md:order-2">
                    <div class="image">
                        <img :src="item.imagepath" :alt="item.title">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import store from '@/store';
export default {
    name: 'ClientContentkarir',

    data() {
        return {
            items: []
        };
    },

    mounted() {
        this.getdata();
    },

    methods: {
        getdata(){
            store.commit('ChangeLoader',true)
            this.axios.get('api/getcareer').then((response) => {
                this.items = response.data
            }).catch(error => {
                
            console.log(error)

            }).finally(() => {
                store.commit('ChangeLoader',false)
            })
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../../assets/main.scss"
</style>