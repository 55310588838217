import { createStore } from 'vuex'

export default createStore({
  state: {
    company:[],
    general:null,
    preloader: false
  },
  getters: {
    getCompany: state => {
			return state.company
		},
    getGeneral: state => {
			return state.general
		},
    getLoader: state => {
			return state.preloader
		}
  },
  mutations: {
    ChangeCompany (state,data) {
			state.company = data
		},
    ChangeGeneral (state,data) {
			state.general = data
		},
    ChangeLoader (state,data) {
			state.preloader = data
		},
  },
  actions: {
  },
  modules: {
  },
  
})
