<template>
    <div>
        <tabNavVue :tabs="tabs" :selected="selected" @selected="setSelected">
            <tabVue :isSelected="selected === '1'">
                <BlogTableVue></BlogTableVue>
            </tabVue>
            <tabVue :isSelected="selected === '2'">
              <BlogCategoryTableVue></BlogCategoryTableVue>
            </tabVue>
        </tabNavVue>
    </div>
</template>

<script>
import tabNavVue from './tabNav.vue';
import tabVue from './tab.vue';
import BlogTableVue from './BlogTable.vue';
import BlogCategoryTableVue from './BlogCategoryTable.vue';
export default {
    name: 'PadmawebBlogComponent',

    data() {
        return {
            selected:'1',

            tabs:[
                {
                    name: 'Blog',
                    initial: '1',
                    icon: 'newspaper-variant-multiple-outline'
                },
                 {
                    name: 'Blog Category',
                    initial: '2',
                    icon: 'shape'
                }

            ],
        };
    },

    mounted() {
        
    },

    methods: {
        setSelected(tab){
            this.selected = tab;
        },
    },
    components:{
        tabNavVue,
        tabVue,
        BlogCategoryTableVue,
        BlogTableVue
    }
};
</script>

<style lang="scss" scoped>

</style>